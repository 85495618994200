<template>
  <!-- 产品分类list页面 -->
  <div class="app-presSale-warper">
    <appHeader :navTitle="language.navTitle"></appHeader>
    <div class="flashSale-tabs-boxers flexbox">
      <div
        class="flashSale-tabs flexbox"
        id="secKillTabsID"
        @scroll="handleSecKillScroll"
      >
        <div
          v-for="(item, index) in fishSaleTabs"
          :key="index"
          :id="`${index}_tabsItemId`"
          :class="[
            'flashSale-tabs-list',
            'flexbox',
            fishSaleIndex == index && 'tabs-active',
          ]"
          @click="onChangeTabs(index)"
        >
          <div class="tabs-list-item flexbox">
            <svg-icon icon-class="app-countDown"> </svg-icon>
            <span class="noText"> {{ item.title }}</span>
            <span class="noText">
              {{ item.startTimeString }} - {{ item.endTimeString }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="app-presSale-body">
      <div class="card-select-boxers">
        <div class="card-select">
          <div
            class="select-active-name flexbox"
            @click="selectIsShow = !selectIsShow"
            v-if="selectName"
          >
            <span>{{ selectName }}</span>
            <van-icon name="arrow-down" />
          </div>
          <div class="select-option" v-show="selectIsShow">
            <div
              v-for="(item, index) in classificationList"
              :key="index"
              :class="[
                'option-list-item',
                'flexbox',
                activeClassIndex == index && 'selectActive',
              ]"
              @click="onChangeSelect(item, index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="card-list-body">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="message.listNullText"
          :loading-text="message.loadingText"
          @load="onLoad"
        >
          <div class="card-list-boxers flexbox" v-if="flashSaleList.length">
            <div
              class="list-item"
              v-for="(item, index) in flashSaleList"
              :key="index"
              @click="
                $router.push({
                  path: '/appGoodsDetail',
                  query: { id: item.id, sourcePage: 'seckill' },
                })
              "
            >
              <div class="list-item-img imgFlex">
                <img v-lazy="getUrl(item.image)" />
              </div>
              <div class="list-item-payment text">
                {{ item.highlights }}
              </div>
              <div class="list-item-name text2">
                {{ item.name }}
              </div>
              <div class="list-item-price flexbox">
                <span>{{ moneyIcon }}{{ item.unitAmount }}</span>
                <span class="flexbox">{{ item.priceReductionRatio }}%</span>
              </div>
            </div>
          </div>
          <default-page v-else></default-page>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import appHeader from "../component/app-header.vue";
import appFlashSaleApi from "@/api/h5Api/appFlashSaleApi";
import imgApi from "@/utils/projectConfig.js";
import moment from "moment-timezone";
import { timeZone } from "@/utils/timeZone";
export default {
  name: "appFlashSalePage",
  data() {
    return {
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      imgApi: imgApi.imgUrl,
      language: this.$language(),
      message: this.$language("messageLanguage"),
      finished: false,
      loading: false,
      fishSaleTabs: [],
      fishSaleIndex: 0,
      timeSetInter: "",
      timeZone: timeZone,
      classificationList: [],
      selectIsShow: false,
      activeClassIndex: 0,
      selectName: "",
      flashSaleList: [],
      searchObj: {
        total: 0,
        page: 1,
        size: 10,
      },
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleSecKillScroll);
  },
  created() {
    this.onInit();
  },
  methods: {
    onInit() {
      window.addEventListener("scroll", this.handleSecKillScroll);
      this.get_classTree();
      // this.fishSaleTabs = dataTime;
    },
    timeSetIn(time) {
      this.timeSetInter = setInterval(() => {
        time += 1000;
        let nowStartTime = this.fishSaleTabs[this.fishSaleIndex].startTime;
        let nowEndTime = this.fishSaleTabs[this.fishSaleIndex].endTime;
        if (nowStartTime < time && time < nowEndTime) {
          this.fishSaleIndex = this.fishSaleIndex + 1;
          this.searchObj.page = 1;
          this.get_seckillList();
          if (document.getElementById("secKillTabsID")) {
            this.scrollCallBack();
          }
        }
      }, 1000);
    },
    // 获取时间线筛选数据
    async getTime() {
      const { code, data } = await appFlashSaleApi.get_flashTime();
      if (code === 200 && data.list.length) {
        data.list.map((item, index) => {
          item.startTime = item.startTime * 1000;
          item.endTime = item.endTime * 1000;
          if (item.startTime < data.time * 1000 && data.time < item.endTime) {
            this.fishSaleIndex = index;
          }
        });
        let time = moment().tz(this.timeZone);

        this.fishSaleTabs = data.list;

        clearInterval(this.timeSetInter);
        const currentTime = moment()
          .tz(this.timeZone)
          .format("YYYY-MM-DD HH:mm:ss", time)
          .valueOf();
        this.timeSetIn(currentTime);
        this.$nextTick(() => {
          this.scrollCallBack();
        });
        this.get_seckillList();
      }
    },
    // 获取seckill列表
    async get_seckillList() {
      this.loading = true;
      this.groupListData = [];
      let categoryId = this.classificationList.length
        ? this.classificationList[this.activeClassIndex].id
        : "";
      let timeId;
      if (categoryId === "All") {
        categoryId = "";
      }
      if (this.fishSaleTabs.length) {
        console.log(this.fishSaleTabs[this.fishSaleIndex]);
        timeId = this.fishSaleTabs[this.fishSaleIndex].id;
      }
      let params = {
        timeId: timeId,
        categoryId: categoryId,
        page: this.searchObj.page,
        size: this.searchObj.size,
      };
      const { code, data } = await appFlashSaleApi.get_product_list(params);
      if (code == 200) {
        this.loading = false;
        data.list.map((item) => {
          item.imageUrl = this.imgApi + item.image;
          item.originalAmount = (item.originalAmount / 100).toFixed(2);
          item.unitAmount = (item.unitAmount / 100).toFixed(2);
        });
        this.searchObj.total = data.total;
        this.flashSaleList = this.flashSaleList.concat(data.list);
        this.searchObj.total = data.total;
        if (this.flashSaleList.length >= this.searchObj.total) {
          this.finished = true;
        } else {
          this.finished = false;
          this.searchObj.page++;
        }
      }
    },
    handleSecKillScroll(e) {
      if (document.getElementById("secKillTabsID")) {
        let scrollTabs = document.getElementById("secKillTabsID").scrollLeft;
        if (scrollTabs) {
          this.activeTabsClass = "secKill-tabs-scroll";
        } else {
          this.activeTabsClass = "";
        }
      }
    },
    scrollCallBack() {
      let indexId = `${this.fishSaleIndex}_tabsItemId`;
      if (document.getElementById(`${indexId}`)) {
        let activeTabsDom = document.getElementById(`${indexId}`).offsetLeft;
        let activeTabsWidth = document.getElementById(`${indexId}`).clientWidth;
        let scrollLeft = activeTabsDom - activeTabsWidth;
        document.getElementById("secKillTabsID").scrollLeft = scrollLeft;
      }
    },
    onLoad() {
      if (!this.fishSaleTabs.length) {
        this.getTime();
      } else {
        this.get_seckillList();
      }
    },

    onChangeTabs(index) {
      this.fishSaleIndex = index;
      this.$nextTick(() => {
        this.scrollCallBack();
      });
      this.finished = false;
      this.loading = false;
      this.flashSaleList = [];
      this.searchObj.page = 1;
      this.get_seckillList();
    },
    onChangeSelect(item, index) {
      this.selectName = item.name;
      this.activeClassIndex = index;
      this.selectIsShow = false;
      this.finished = false;
      this.loading = false;
      this.flashSaleList = [];
      this.searchObj.page = 1;
      this.get_seckillList();
    },

    // 获取分类数据
    async get_classTree() {
      this.recommendIsShow = false;
      let { code, data } = await appFlashSaleApi.getClassIfy_List();
      if (code === 200) {
        this.classificationList = data;
        this.classificationList.unshift({
          id: "All",
          name: this.language.selectAll,
        });
        this.activeClassIndex = 0;
        this.selectName = this.classificationList[0].name;
      }
    },
    getUrl(url) {
      return this.imgApi + url;
    },
  },
  components: { appHeader },
};
</script>

<style lang="scss" scoped>
.app-presSale-warper {
  width: 100%;
  height: 100vh;
  font-family: "GrifaSlabRegular";
  background: #f7f7fb;

  .flashSale-tabs-boxers {
    margin-top: 15px;
    padding: 12px 30px;
    background-color: #fff;

    .flashSale-tabs {
      width: 100%;
      overflow-y: scroll;
      transition: all 0.3s ease;

      .flashSale-tabs-list {
        padding: 20px 30px;
        background-color: #f5f6fa;
        margin-right: 12px;
        border-radius: 12px;

        .tabs-list-item {
          justify-content: space-between;
          color: #8f959e;
          font-size: 28px;

          svg {
            width: 30px;
            height: 30px;
            margin-right: 12px;
          }
        }
      }

      .tabs-active {
        background-color: $appBlur;

        .tabs-list-item {
          color: $appWhite;
        }
      }
    }
  }

  .app-presSale-body {
    width: 100%;
    height: calc(100% - 98px - 127px);
    background: #f7f7fb;
  }

  .card-select-boxers {
    background: #fff;

    .card-select {
      position: relative;

      .select-active-name {
        padding: 0 36px;
        height: 100px;
        color: #774388;
        font-size: 24px;

        span {
          font-size: 26px;
          font-family: 600;
          margin-right: 8px;
        }
      }

      .select-option {
        transform: all 0.3s ease;
        position: absolute;
        top: 100px;
        left: 36px;
        border-radius: 18px;
        overflow: hidden;
        width: 42%;
        background-color: #fafafa;
        z-index: 2;

        .option-list-item {
          height: 90px;
          padding: 0 30px;
          color: #90969f;
          font-size: 24px;
          font-family: 600;
        }

        .selectActive {
          background-color: #6d3580;
          color: #fff;
        }
      }
    }
  }

  .card-list-body {
    width: 100%;
    height: calc(100% - 125px);
    background: #f7f7fb;
    overflow-y: scroll;

    .card-list-boxers {
      width: 100%;
      padding: 30px 36px;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: #fff;

      .van-list {
        background-color: #fff;
      }

      .list-item {
        width: 48%;
        margin-bottom: 30px;
        background-color: #fff;
        padding-bottom: 30px;

        .list-item-img {
          width: 100%;
          height: 412px;
          overflow: hidden;
          border-radius: 18px;
          position: relative;
        }

        .list-item-name {
          min-height: 76px;
          margin-top: 12px;
          color: #1d1e20;
          font-size: 26px;
          line-height: 1.5;
        }

        .list-item-payment {
          margin-top: 22px;
          color: #959ba3;
          font-size: 20px;
          line-height: 1.5;
        }

        .list-item-price {
          margin-top: 22px;
          font-size: 32px;
          color: #6d3580;

          span:nth-child(2) {
            font-size: 24px;
            margin-left: 12px;
            padding: 2px 8px;
            border-radius: 6px;
            border: 1px solid $blur;
          }
        }
      }
    }
  }
}
</style>
