<template>
  <!-- 产品分类list页面 -->
  <div class="appClassList-page-warper">
    <appHeader :navTitle="language.navTitle"></appHeader>
    <searchCard @searchChange="searchChange"></searchCard>
    <div :class="['card-tabs-body', routerPage === 'hot' && 'cardHotBody']">
      <van-tabs
        v-model="tabsActive"
        class="card-tabs-boxers"
        swipe-threshold="3"
        @change="onChangeTabs"
        swipeable
      >
        <van-tab
          v-for="(item, index) in tabsDataList"
          :key="index"
          :title="item.name"
        >
          <div class="card-select-boxers">
            <div class="card-select">
              <div
                class="select-active-name flexbox"
                @click="onSelectIsShow(item, index)"
              >
                <span>{{ item.selectLabel }}</span>
                <van-icon name="arrow-down" />
              </div>
              <div class="select-option" v-show="item.selectIsShow">
                <div
                  v-for="(selectItem, selectIndex) in selectDataList"
                  :key="selectIndex"
                  :class="[
                    'option-list-item',
                    'flexbox',
                    formObj.sort == selectItem.value && 'selectActive',
                  ]"
                  @click="onChangeSelect(selectItem)"
                >
                  {{ selectItem.label }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-list-body">
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="message.listNullText"
              :loading-text="message.loadingText"
              @load="onLoad"
            >
              <div class="card-list-boxers flexbox" v-if="productList.length">
                <div
                  v-for="(item, listIndex) in productList"
                  :key="listIndex"
                  class="card-list-item"
                  @click="
                    $router.push({
                      path: '/appGoodsDetail',
                      query: { id: item.id },
                    })
                  "
                >
                  <div class="app-product-card">
                    <div class="app-product-img imgFlex">
                      <img v-lazy="getUrlImg(item.image)" />
                    </div>
                    <div class="app-product-name text">
                      {{ item.name }}
                    </div>
                    <div class="app-product-payment text2">
                      {{ item.salesTotal }} {{ language.paymentTitle }}
                    </div>
                    <div class="app-product-price text">
                      {{ moneyIcon }}{{ item.unitAmount }}
                    </div>
                  </div>
                </div>
              </div>
              <default-page v-else></default-page>
            </van-list>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import appHeader from "../component/app-header.vue";
import searchCard from "../component/app-search.vue";
import productList from "../component/app-product-list.vue";
import appClassProductApi from "@/api/h5Api/appClassProductApi";
import imgApi from "@/utils/projectConfig.js";
export default {
  name: "appClassProductIndex",
  data() {
    return {
      language: this.$language(),
      message: this.$language("messageLanguage"),
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      imgApi: imgApi.imgUrl,
      thumbnail: this.$thumbnail,
      tabsDataList: [],
      tabsActive: null,
      selectDataList: [],
      classificationData: [],
      formObj: {
        page: 1,
        size: 20,
        name: "",
        sort: "default",
        categoryId: "",
        total: 0,
      },
      productList: [],
      loading: false,
      finished: false,
      refreshing: false,
      routerPage: "",
      navTitle: "",
    };
  },
  mounted() {
    this.getClassificationList();
  },
  methods: {
    onInit() {
      this.selectDataList = [
        { value: "default", label: this.language.select1 },
        { value: "sale", label: this.language.select2 },
        { value: "news", label: this.language.select3 },
        { value: "prizeDesc", label: this.language.select4 },
        { value: "prizeAsc", label: this.language.select5 },
        { value: "star", label: this.language.select6 },
      ];
      this.tabsDataList.map((item) => {
        item.selectValue = 0;
        item.selectIsShow = false;
        item.selectLabel = this.selectDataList[item.selectValue].label;
        item.selectValue = this.selectDataList[item.selectValue].value;
      });
    },
    getClassificationList() {
      appClassProductApi.getClassIfy_List().then((res) => {
        if (res.code == 200) {
          this.classificationData = res.data;
          let enumType = ["classification", "homeClass"];
          if (
            Object.keys(this.$route.query).length > 0 &&
            enumType.includes(this.$route.query.type)
          ) {
            let result = [];
            const id = Number(this.$route.query.id);
            this.formObj.categoryId = id;
            this.treeNode(res.data, id, result);
            this.$nextTick(() => {
              result.map((item, index) => {
                if (item.id == id) {
                  this.tabsActive = index;
                }
              });
            });
            this.tabsDataList = result;
          } else {
            this.tabsDataList = res.data;
          }
          const allText=this.$language('appFlashSale').selectAll
          this.tabsDataList.unshift({id:'',name:allText})
          this.onInit();
        }
      });
    },
    treeNode(node, targetId, result) {
      for (let key in node) {
        if (node[key].id === targetId) {
          node.map((item) => {
            result.push(item);
          });
        }
        if (node[key].child) {
          this.treeNode(node[key].child, targetId, result);
        }
      }
    },
    onLoad() {
      this.getProductList();
    },
    async getProductList () {
      console.log(this.formObj.name,'this.formObj.name')
      this.loading = true;
      const params = {
        categoryId: this.formObj.categoryId,
        name: this.formObj.name,
        sort: this.formObj.sort,
        page: this.formObj.page,
        size: this.formObj.size,
      };
      const { code, data } = await appClassProductApi.getProductPort(params);
      if (code == 200) {
        this.loading = false;
        data.list.map((item) => {
          item.originalAmount = Number((item.originalAmount / 100)).toFixed(2);
          item.unitAmount = Number((item.unitAmount / 100)).toFixed(2);
          if (item.sales > 1000) {
            item.salesTotal = item.sales.toString() + "+";
          } else {
            item.salesTotal = item.sales;
          }
        });
        this.productList = this.productList.concat(data.list);
        this.formObj.total = data.total;
        if (this.productList.length >= this.formObj.total) {
          this.finished = true;
        } else {
          this.finished = false;
          this.formObj.page++;
        }
      }
    },
    getUrlImg(url) {
      return this.imgApi + url;
    },

    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onChangeTabs() {
      this.finished = false;
      this.loading = true;
      this.productList = [];
      this.formObj.total = 0;
      this.formObj.page = 1;
      this.formObj.sort = "default";
      this.formObj.categoryId = this.tabsDataList[this.tabsActive].id;
      this.onInit();
      this.onLoad();
    },
    onClickBlank() {
      this.tabsDataList[this.tabsActive].selectIsShow = false;
      this.$forceUpdate();
    },
    onSelectIsShow(item, index) {
      this.tabsDataList[index].selectIsShow =
        !this.tabsDataList[index].selectIsShow;
      this.$forceUpdate();
    },

    onChangeSelect(selectItem) {
      this.formObj.sort = selectItem.value;
      this.tabsDataList[this.tabsActive].selectValue = selectItem.value;
      this.tabsDataList[this.tabsActive].selectLabel = selectItem.label;
      this.tabsDataList[this.tabsActive].selectIsShow = false;
      this.$forceUpdate();
      this.finished = false;
      this.loading = true;
      this.productList = [];
      this.formObj.total = 0;
      this.formObj.page = 1;
      this.formObj.categoryId = this.tabsDataList[this.tabsActive].id;
      this.onLoad();
    },

    // 搜索
    searchChange(val) {
      this.finished = false;
      this.loading = true;
      this.productList = [];
      this.formObj.total = 0;
      this.formObj.page = 1;
      this.formObj.name = val;
      this.onLoad();
    },
  },
  components: { appHeader, searchCard, productList },
  watch: {
    "$store.state.app_ClassData": {
      handler(newVal, oldVal) {
        this.tabsDataList = [...newVal];
        this.onInit();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.appClassList-page-warper {
  width: 100%;
  height: 100vh;
  font-family: "GrifaSlabRegular";

  ::v-deep .search-warper {
    background-color: #fff;
    width: 100%;
  }

  .card-tabs-body {
    width: 100%;
    height: calc(100% - 249.5px);
  }

  .cardHotBody {
    height: calc(100% - 100.5px);
  }

  .card-tabs-boxers {
    padding: 30px 0;
    height: 100%;

    ::v-deep .van-tabs__wrap {
      .van-tabs__nav {
        .van-tab {
          .van-tab__text {
            font-weight: 400;
            font-size: 28px;
            color: #9ba0a8;
          }
        }

        .van-tab--active {
          .van-tab__text {
            font-weight: 600;
            color: #6d3580;
          }
        }
      }

      .van-tabs__line {
        height: 4px;
        background-color: #6d3580;
      }
    }

    ::v-deep .van-tabs__content {
      height: calc(100% - 60px);

      .van-tab__pane {
        height: 100%;

        .card-select-boxers {
          background: #fff;

          .card-select {
            position: relative;

            .select-active-name {
              padding: 0 36px;
              height: 100px;
              color: #774388;
              font-size: 24px;

              span {
                font-size: 26px;
                font-family: 600;
                margin-right: 8px;
              }
            }

            .select-option {
              transform: all 0.3s ease;
              position: absolute;
              top: 100px;
              left: 36px;
              border-radius: 18px;
              overflow: hidden;
              width: 42%;
              background-color: #fafafa;
              z-index: 2;

              .option-list-item {
                height: 90px;
                padding: 0 30px;
                color: #90969f;
                font-size: 24px;
                font-family: 600;
              }

              .selectActive {
                background-color: #6d3580;
                color: #fff;
              }
            }
          }
        }

        .card-list-body {
          width: 100%;
          height: calc(100% - 110px);
          overflow-y: scroll !important;
          background: #fff;
        }

        .van-list {
        }

        .card-list-boxers {
          width: 100%;
          padding: 30px 36px;
          flex-wrap: wrap;
          justify-content: space-between;

          .card-list-item {
            width: 48%;
            margin-bottom: 35px;

            .app-product-card {
              font-family: "GrifaSlabRegular";

              .app-product-img {
                width: 100%;
                height: 420px;
                border-radius: 18px;
                overflow: hidden;
              }

              .app-product-name {
                margin-bottom: 12px;
                font-size: 28px;
                color: #38393b;
              }

              .app-product-payment {
                margin-bottom: 16px;
                font-size: 20px;
                color: #8f959e;
              }

              .app-product-price {
                margin-bottom: 24px;
                color: #6d3580;
                font-size: 28px;
              }

              .app-product-tags {
                display: flex;
                font-size: 20px;
                color: #6d3580;

                span {
                  padding: 6px 9px;
                  border: 1px solid #6d3580;
                  border-radius: 4px;
                }
              }
            }
          }
        }

        .shop-list-boxers {
          flex-direction: column;
          background-color: #fbfbfb;

          .shop-item {
            width: 100%;
            background-color: #fff;
            box-shadow: 0px 0 15px 0px #eff0f1;
            border-radius: 18px;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>
