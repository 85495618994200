<template>
  <div class="warper">12</div>
</template>

<script>
export default {
  name: "Index",
  props: {
    rowClick: {
      type: Function,
      default: function () {},
    },
  },
  data() {
    return {};
  },
  components: {},
  created() {
    console.log(33);
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
