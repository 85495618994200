var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"appHome-flashSale-warper"},[_c('div',{staticClass:"appHome-flashSale-header flexbox"},[_c('div',{staticClass:"header-left flexbox"},[_c('svg-icon',{staticClass:"appHomeSvg",attrs:{"icon-class":"app-alarm-clock"}}),_c('span',{staticClass:"title-left"},[_vm._v(_vm._s(_vm.language.flashSaleTitle))])],1),_c('div',{staticClass:"header-right"},[_c('span',{staticClass:"title-right",on:{"click":function($event){return _vm.$router.push({ path: '/appFlashSale' })}}},[_vm._v(_vm._s(_vm.language.viewAll))])])]),_c('div',{staticClass:"flashSale-tabs-boxers flexbox"},[_c('div',{staticClass:"flashSale-tabs flexbox",attrs:{"id":"secKillTabsID"},on:{"scroll":_vm.handleSecKillScroll}},_vm._l((_vm.fishSaleTabs),function(item,index){return _c('div',{key:index,class:[
        'flashSale-tabs-list',
        'flexbox',
        _vm.activeTabs == index && 'tabs-active',
      ],attrs:{"id":`${index}_tabsItemId`},on:{"click":function($event){return _vm.onChangeTabs(item, index)}}},[_c('div',{staticClass:"tabs-list-item flexbox"},[_c('svg-icon',{attrs:{"icon-class":"app-countDown"}}),_c('span',{staticClass:"noText"},[_vm._v(" "+_vm._s(item.startTimeString)+" - "+_vm._s(item.endTimeString))])],1)])}),0)]),_c('div',{staticClass:"appHome-flashSale-body"},[_c('div',{staticClass:"flashSale-list-boxers flexbox"},[(_vm.flashSaleListData.length)?_c('van-swipe',{staticClass:"list-item-swipe",attrs:{"indicator-color":"#6d3580"},on:{"change":_vm.onChangeSwipe}},_vm._l((_vm.countLen),function(item,lenIndex){return _c('van-swipe-item',{key:lenIndex},_vm._l((_vm.setList(lenIndex)),function(item,index){return _c('div',{key:index,staticClass:"flashSale-list-item",on:{"click":function($event){return _vm.$router.push({
              path: '/appGoodsDetail',
              query: { id: item.id, sourcePage: 'seckill' },
            })}}},[_c('div',{staticClass:"list-item-img-boxers imgFlex"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrl(item.image)),expression:"getUrl(item.image)"}]})]),_c('div',{staticClass:"list-item-name text2"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"list-item-price flexbox"},[_c('span',[_vm._v(" "+_vm._s(_vm.moneyIcon)+_vm._s(item.unitAmount))]),_c('span',{staticClass:"flexbox"},[_vm._v(_vm._s(item.priceReductionRatio)+"%")])])])}),0)}),1):_c('defaultPage')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }