var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-presSale-warper"},[_c('appHeader',{attrs:{"navTitle":_vm.language.navTitle}}),_c('div',{staticClass:"flashSale-tabs-boxers flexbox"},[_c('div',{staticClass:"flashSale-tabs flexbox",attrs:{"id":"secKillTabsID"},on:{"scroll":_vm.handleSecKillScroll}},_vm._l((_vm.fishSaleTabs),function(item,index){return _c('div',{key:index,class:[
          'flashSale-tabs-list',
          'flexbox',
          _vm.fishSaleIndex == index && 'tabs-active',
        ],attrs:{"id":`${index}_tabsItemId`},on:{"click":function($event){return _vm.onChangeTabs(index)}}},[_c('div',{staticClass:"tabs-list-item flexbox"},[_c('svg-icon',{attrs:{"icon-class":"app-countDown"}}),_c('span',{staticClass:"noText"},[_vm._v(" "+_vm._s(item.title))]),_c('span',{staticClass:"noText"},[_vm._v(" "+_vm._s(item.startTimeString)+" - "+_vm._s(item.endTimeString)+" ")])],1)])}),0)]),_c('div',{staticClass:"app-presSale-body"},[_c('div',{staticClass:"card-select-boxers"},[_c('div',{staticClass:"card-select"},[(_vm.selectName)?_c('div',{staticClass:"select-active-name flexbox",on:{"click":function($event){_vm.selectIsShow = !_vm.selectIsShow}}},[_c('span',[_vm._v(_vm._s(_vm.selectName))]),_c('van-icon',{attrs:{"name":"arrow-down"}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectIsShow),expression:"selectIsShow"}],staticClass:"select-option"},_vm._l((_vm.classificationList),function(item,index){return _c('div',{key:index,class:[
              'option-list-item',
              'flexbox',
              _vm.activeClassIndex == index && 'selectActive',
            ],on:{"click":function($event){return _vm.onChangeSelect(item, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)])]),_c('div',{staticClass:"card-list-body"},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":_vm.message.listNullText,"loading-text":_vm.message.loadingText},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[(_vm.flashSaleList.length)?_c('div',{staticClass:"card-list-boxers flexbox"},_vm._l((_vm.flashSaleList),function(item,index){return _c('div',{key:index,staticClass:"list-item",on:{"click":function($event){return _vm.$router.push({
                path: '/appGoodsDetail',
                query: { id: item.id, sourcePage: 'seckill' },
              })}}},[_c('div',{staticClass:"list-item-img imgFlex"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrl(item.image)),expression:"getUrl(item.image)"}]})]),_c('div',{staticClass:"list-item-payment text"},[_vm._v(" "+_vm._s(item.highlights)+" ")]),_c('div',{staticClass:"list-item-name text2"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"list-item-price flexbox"},[_c('span',[_vm._v(_vm._s(_vm.moneyIcon)+_vm._s(item.unitAmount))]),_c('span',{staticClass:"flexbox"},[_vm._v(_vm._s(item.priceReductionRatio)+"%")])])])}),0):_c('default-page')],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }