import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import '@/fonts/fonts.css'
import { environment } from '@/utils/constant'
Vue.prototype.$constant = environment;

import store from '@/store'
import 'lib-flexible/flexible'
// 引入
import VueLazyload from 'vue-lazyload'
import '@/icons'
// Vue.use(VueLazyload) 
Vue.use(VueLazyload, {
  // 加载失败后加载的图片
  error: require('./assets/logo.png'),
  loading: require('./assets/logo.png'),
})
import '@/utils/vant'
import appNotify from './views/h5/component/app-notify.vue'
Vue.component('appNotify', appNotify)
import defaultPage from '@/layout/defaultNull.vue'
Vue.component('defaultPage', defaultPage)
import './utils/appback.js'
import './utils/version'
import '@/utils/ui/pc/pc'
import { web_languageList, web_languageType, web_settingLanguage } from '@/utils/language/pc-language/language'
import { app_languageList, app_languageType, app_settingLanguage } from '@/utils/language/h5-language/language'
const result = /Android |webos| iPhone |iPod| BlackBerry | iPad/i.test(navigator.userAgent)
let languageList = web_languageList
let languageType = web_languageType
let settingLanguage = web_settingLanguage
if (result) {
  languageList = app_languageList
  languageType = app_languageType
  settingLanguage = app_settingLanguage
}
Vue.prototype.$language = languageList
Vue.prototype.$languageType = languageType
Vue.prototype.$settingLanguage = settingLanguage

import globalCommon from '../src/utils/global'
Vue.prototype.$globalCommon = globalCommon;
Vue.prototype.$permissionObj = globalCommon.$permissionObj; 

Vue.prototype.$thumbnail = '?x-oss-process=image/resize,w_100' // 图片缩略

Vue.prototype.$resetSetItem = function (type, key, data) {
  // 创建一个StorageEvent事件
  var newStorageEvent = document.createEvent('StorageEvent');
  const storage = {
    setItem: function (k, val) {
      if (type === "localStorage") {
        localStorage.setItem(k, val);
      } else {
        sessionStorage.setItem(k, val);
      }
      // 初始化创建的事件
      newStorageEvent.initStorageEvent(key, false, false, k, null, val, null, null);
      // 派发对象
      window.dispatchEvent(newStorageEvent);
    }
  }
  return storage.setItem(key, data);
}
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')