<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import { getParams } from "@/utils/publicMethods";
export default {
  name: "App",
  mounted() {
    let redirect;
    let result = {};
    let isShow = false;
    this.$router.options.routes.map((item) => {
      if (item.path == this.$route.path) {
        result = { ...item };
        isShow = true;
      }
      if (!isShow && item.children && item.children.length) {
        item.children.map((record) => {
          if (record.path == this.$route.path) {
            result = { ...record };
          }
        });
      }
    });
    let language = getParams("language");
    language = decodeURI(language);
    if (language && language !== "null" && language !== "undefined") {
      localStorage.setItem("languageType", language);
    }
    let code = getParams("code"); 
    if (code == 0 || code) {
      sessionStorage.setItem("CODE", code);
    }
    if (!this.isMobile() && this.$route.path === "/appMerchantSettlement") {
      this.$router.push({ path: "/application", query: this.$route.query });
      return false;
    } else if (!Object.keys(result).length) {
      redirect = this.$router.options.routes[0].redirect;
      this.$router.push({ path: redirect });
    }
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        // match方法可在字符串内检索指定的值，
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none;
}
.el-loading-mask.is-fullscreen .el-loading-spinner .circular {
  font-size: 32px;
  font-weight: 900;
  width: 38px !important;
  height: 38px !important;
}
.el-loading-spinner .path {
  stroke: $blur !important;
  stroke-width: 5px !important;
}
</style>
