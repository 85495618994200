import service from '@/required/appAxios'
export default {
  // 获取店铺列表数据
  get_shopList (data) {
    return service({
      url: 'shop.get',
      method: "get",
      params: data
    })
  },
}