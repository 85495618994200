import service from '@/required/appAxios'
export default {
  // 获取全部分类数据 
  getClassIfy_List (form) {
    return service({
      url: '/category.get_tree',
      method: "get",
    })
  },
  // 获取group数据
  get_groupList (data) {
    return service({
      url: 'group.get_product_list',
      method: "get",
      params: data
    })
  },
}