<template>
  <!-- 产品分类list页面 -->
  <div class="app-presSale-warper">
    <appHeader :navTitle="language.navTitle"></appHeader>
    <div class="app-presSale-body">
      <van-tabs
        v-model="tabsActive"
        class="card-tabs-boxers"
        swipe-threshold="3"
        @change="onChangeTabs"
        :ellipsis="false"
      >
        <van-tab
          v-for="(item, index) in tabsDataList"
          :key="index"
          :title="item.name"
        >
          <div class="card-select-boxers">
            <div class="card-select">
              <div class="select-active-name flexbox" @click="onSelectIsShow">
                <span>{{ formObj.categoryLabel }}</span>
                <van-icon name="arrow-down" />
              </div>
              <div class="select-option" v-show="selectIsShow">
                <div
                  v-for="(item, index) in classificationList"
                  :key="index"
                  :class="[
                    'option-list-item',
                    'flexbox',
                    formObj.categoryId == item.id && 'selectActive',
                  ]"
                  @click="onChangeSelect(item, index)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-list-body">
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="message.listNullText"
              :loading-text="message.loadingText"
              @load="onLoad"
            >
              <div class="card-list-boxers flexbox">
                <div
                  class="list-item"
                  v-for="(item, index) in preSaleList"
                  :key="index"
                  @click="
                    $router.push({
                      path: '/appGoodsDetail',
                      query: { id: item.id, sourcePage: 'presale' },
                    })
                  "
                >
                  <div class="list-item-img imgFlex">
                    <img v-lazy="getImgUrl(item.image)" />
                    <div class="img-count-down flexbox text-warper">
                      {{ language.listLabel }}
                      {{ item.shipDay }}
                      {{ language.listLabe2 }}
                    </div>
                  </div>

                  <div class="list-item-payment text">
                    {{ item.highlights }}
                  </div>
                  <div class="list-item-name text2">
                    {{ item.name }}
                  </div>
                  <div class="list-item-price">
                    {{ moneyIcon }}{{ item.unitAmount }}
                  </div>
                </div>
              </div>
            </van-list>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import appHeader from "../component/app-header.vue";
import appPreSaleApi from "@/api/h5Api/appPreSaleApi";
import imgApi from "@/utils/projectConfig.js";
export default {
  name: "appClassListPage",
  data() {
    return {
      imgApi: imgApi.imgUrl,
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      loading: false,
      finished: false,
      language: this.$language(),
      message: this.$language("messageLanguage"),
      tabsDataList: [],
      tabsActive: 0,
      preSaleList: [],
      formObj: {
        page: 1,
        size: 20,
        total: 0,
        stateLabel: "",
        state: "start",
        categoryId: "",
        categoryLabel: "",
      },
      selectIsShow: false,
      classificationList: [],
    };
  },
  created() {
    this.get_classTree();
  },
  mounted() {
    this.onInit();
  },
  methods: {
    // 获取分类数据
    async get_classTree() {
      this.classificationList = [];
      let { code, data } = await appPreSaleApi.getClassIfy_List();
      if (code === 200) {
        this.classificationList = data;
        this.classificationList.unshift({
          id: "All",
          name: this.language.selectAll,
        });
        this.formObj.categoryId = "";
      }
    },
    getPreSaleList() {
      this.selectIsShow = false;
      this.loading = true;
      let categoryId = this.formObj.categoryId;
      if (this.formObj.categoryId == "All") {
        categoryId = "";
      }
      const params = {
        page: this.formObj.page,
        size: this.formObj.size,
        state: this.tabsDataList[this.tabsActive].value,
        categoryId: categoryId,
      };
      appPreSaleApi.get_productList(params).then(({ code, data }) => {
        this.loading = false;
        if (code == 200) {
          data.list.map((item) => {
            item.unitAmount = (item.unitAmount / 100).toFixed(2);
          });
          this.preSaleList = this.preSaleList.concat(data.list);
          this.formObj.total = data.total;
          if (this.preSaleList.length >= this.formObj.total) {
            this.finished = true;
          } else {
            this.finished = false;
            this.formObj.page++;
          }
        }
      });
    },
    onLoad() {
      this.getPreSaleList();
    },

    onChangeTabs(e) {
      this.tabsActive = e;
      this.onInit();
      this.finished = false;
      this.preSaleList = [];
      this.finished = false;
      this.formObj.page = 1;
      this.formObj.total = 0;
      this.getPreSaleList();
    },
    onClickBlank() {
      this.selectIsShow = false;
      this.$forceUpdate();
    },
    onSelectIsShow(item, index) {
      this.selectIsShow = !this.selectIsShow;
      this.$forceUpdate();
    },

    onChangeSelect(selectItem) {
      this.formObj.categoryLabel = selectItem.name;
      this.formObj.categoryId = selectItem.id;
      this.selectIsShow = false;
      this.preSaleList = [];
      this.finished = false;
      this.formObj.page = 1;
      this.formObj.total = 0;
      this.getPreSaleList();
    },
    onInit() {
      this.formObj.categoryId = "All";
      this.formObj.categoryLabel = this.language.selectAll;
      this.tabsDataList = [
        { value: "start", name: this.language.tabs1 },
        { value: "wait", name: this.language.tabs2 },
        { value: "end", name: this.language.tabs3 },
      ];
    },
    getImgUrl(url) {
      return this.imgApi + url;
    },
  },
  components: { appHeader },
};
</script>

<style lang="scss" scoped>
.app-presSale-warper {
  width: 100%;
  height: 100vh;
  font-family: "GrifaSlabRegular";
  background: #f7f7fb;

  ::v-deep .search-warper {
    background-color: #fff;
    width: 100%;
  }

  .app-presSale-body {
    width: 100%;
    height: calc(100% - 150px);
  }

  .card-tabs-boxers {
    padding: 20px 0;
    height: 100%;

    ::v-deep .van-tabs__wrap {
      .van-tabs__nav {
        padding-left: 20px;
        padding-right: 20px;
        .van-tab {
          margin: 0 12px;
          flex: none !important;
          text-align: center;
          line-height: 2.5;
          padding: 0 15px;
          .van-tab__text {
            font-weight: 500;
            font-size: 32px;
            color: #9ba0a8;
          }
        }

        .van-tab--active {
          .van-tab__text {
            font-weight: 600;
            color: #6d3580;
          }
        }
      }

      .van-tabs__line {
        height: 4px;
        background-color: #6d3580;
      }
    }

    ::v-deep .van-tabs__content {
      height: calc(100% - 60px);

      .van-tab__pane {
        height: 100%;
      }
    }
  }
  .card-select-boxers {
    background: #fff;

    .card-select {
      position: relative;

      .select-active-name {
        padding: 0 36px;
        height: 100px;
        color: #774388;
        font-size: 24px;

        span {
          font-size: 26px;
          font-family: 600;
          margin-right: 8px;
        }
      }

      .select-option {
        transform: all 0.3s ease;
        position: absolute;
        top: 100px;
        left: 36px;
        border-radius: 18px;
        overflow: hidden;
        width: 42%;
        background-color: #fafafa;
        z-index: 2;

        .option-list-item {
          height: 90px;
          padding: 0 30px;
          color: #90969f;
          font-size: 24px;
          font-family: 600;
        }

        .selectActive {
          background-color: #6d3580;
          color: #fff;
        }
      }
    }
  }

  .card-list-body {
    width: 100%;
    height: calc(100% - 90px);
    overflow-y: scroll;
  }

  .van-list {
    padding: 30px 36px;
    background-color: #fff;
  }

  .card-list-boxers {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #fff;

    .list-item {
      width: 48%;
      margin-bottom: 30px;
      background-color: #fff;

      .list-item-img {
        width: 100%;
        height: 412px;
        overflow: hidden;
        border-radius: 18px;
        position: relative;

        .img-count-down {
          text-align: center;
          padding: 20px 15px;
          background-color: #6d3580;
          justify-content: center;
          font-size: 20px;
          color: #fff;
          width: 100%;
          min-height: 60px;
          position: absolute;
          bottom: 0;
          line-height: 1.5;
        }
      }

      .list-item-name {
        margin-top: 12px;
        color: #1d1e20;
        font-size: 26px;
      }

      .list-item-payment {
        margin-top: 15px;
        color: #959ba3;
        font-size: 20px;
      }

      .list-item-price {
        margin-top: 22px;
        font-size: 34px;
        color: #6d3580;
      }
    }
  }
}
</style>
