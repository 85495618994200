<template>
  <div class="groupPurchase-card-warper">
    <div class="groupPurchase-card-header flexbox">
      <div class="header-left flexbox">
        <svg-icon icon-class="app-home-user" class="appHomeSvg"></svg-icon>
        <span class="title-left">{{ language.groupTitle }}</span>
      </div>
      <div class="header-right">
        <span
          class="title-right"
          @click="$router.push({ path: '/appGroupBuying' })"
        >
          {{ language.viewAll }}
        </span>
      </div>
    </div>
    <div class="appHome-groupPurchase-body">
      <van-tabs
        v-model="activeTabs"
        class="groupPurchase-tabs"
        @change="onChangeTabs"
        :ellipsis="false"
      >
        <van-tab
          v-for="(item, index) in tabsListData"
          :key="index"
          :title="item.name"
        >
        </van-tab>
      </van-tabs>
      <div class="groupPurchase-data">
        <div class="tabs-list-boxers" v-if="groupPurchaseList.length">
          <div
            class="tabs-list-item"
            v-for="(item, index) in groupPurchaseList"
            :key="index"
            @click="
              $router.push({
                path: '/appGoodsDetail',
                query: { id: item.id, sourcePage: 'group' },
              })
            "
          >
            <div class="list-img imgFlex">
              <img v-lazy="getUrl(item.image)" />
              <!-- <div class="list-item-time flexbox">
                <span class="noText">{{ item.highlights }}</span>
              </div> -->
            </div>
            <div class="list-info">
              <div class="list-name text2">
                {{ item.name }}
              </div>
              <div class="list-peopleGroupBuying text">
                {{ item.peopleNumber }}{{ language.peopleGroupBuying }}
              </div>
              <div class="list-price">{{ moneyIcon }}{{ item.unitAmount }}</div>
            </div>
          </div>
        </div>
        <defaultPage v-else></defaultPage>
      </div>
    </div>
  </div>
</template>

<script>
import appHomeApi from "@/api/h5Api/appHomeApi";
import imgApi from "@/utils/projectConfig.js";
export default {
  name: "Index",
  props: {
    tabsList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      imgApi: imgApi.imgUrl,
      thumbnail: this.$thumbnail,
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      language: this.$language(),
      tabsListData: [],
      activeTabs: 0,
      groupPurchaseList: [],
    };
  },
  mounted() {
    this.tabsListData = this.tabsList;
    if (this.tabsListData.length) {
      this.onLoad();
    }
  },
  methods: {
    onChangeTabs(e) {
      this.activeTabs = e;
      this.onLoad();
    },
    async onLoad() {
      const params = {
        categoryId: this.tabsListData[this.activeTabs].id,
        page: 1,
        size: 6,
      };
      const { code, data } = await appHomeApi.get_groupList(params);
      if (code == 200) {
        data.list.map((item) => {
          item.unitAmount = (Number(item.unitAmount) / 100).toFixed(2);
        });
        this.groupPurchaseList = data.list;
      }
    },
    getUrl(url) {
      return this.imgApi + url;
    },
  },
  watch: {
    tabsList: {
      handler(newVal, oldVal) {
        this.tabsListData = [...newVal];
        this.onLoad();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.groupPurchase-card-warper {
  margin-top: 15px;

  .groupPurchase-card-header {
    justify-content: space-between;
    padding: 0px 36px;
    height: 110px;
    z-index: 2;
    background-color: #fff;
  }

  .appHome-groupPurchase-body {
    .groupPurchase-tabs {
      ::v-deep .van-tabs__wrap {
        .van-tabs__nav {
          .van-tab {
            

            .van-tab__text {
              font-size: 28px;
              color: #9ba0a8;
            }
          }

          .van-tab--active {
            .van-tab__text {
              color: #6d3580;
            }
          }
        }

        .van-tabs__line {
          background-color: #6d3580;
        }
      }

      .van-tabs__content {
        .van-tab__pane {
          overflow-x: hidden;
        }
      }
    }

    .groupPurchase-data {
      padding: 15px 30px;
      width: 100%;
      overflow-x: scroll;
      background-color: #fff;

      .tabs-list-boxers {
        width: 100%;
        display: flex;
        justify-content: space-between;
        overflow-x: scroll;

        .tabs-list-item {
          min-width: 336px;
          width: 336px;
          background-color: #fff;
          border-radius: 18px;
          overflow: hidden;
          padding-bottom: 16px;
          margin-right: 30px;

          .list-img {
            width: 100%;
            height: 310px;
            position: relative;
            border-radius: 18px;
            overflow: hidden;

            .list-item-time {
              padding: 0 20px;
              justify-content: center;
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 60px;
              background-color: rgba(94, 48, 108, 0.9);
              color: $appWhite;
              font-size: 22px;
            }
          }

          .list-info {
            padding: 12px;

            .list-name {
              height: 100%;
              text-align: left;
              line-height: 30px;
              font-size: 24px;
              color: #1d1e20;
            }

            .list-peopleGroupBuying {
              color: #999fa7;
              font-size: 22px;
              margin-top: 12px;
            }

            .list-price {
              margin-top: 12px;
              color: #1d1e20;
              font-weight: 600;
              font-size: 36px;
              text-align: left;
            }
          }
        }

        .tabs-list-item:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
