import service from '@/required/appAxios'
export default {
  // 获取全部分类数据 
  getClassIfy_List (form) {
    return service({
      url: '/category.get_tree',
      method: "get",
    })
  },
  // 获取预售数据
  get_productList (data) {
    return service({
      url: 'presale.get_product_list',
      method: "get",
      params: data
    })
  },
}