<template>
  <div class="goodsDetail-guess-like">
    <div class="guess-like-header flexbox">
      <div class="header-left flexbox">
        <svg-icon icon-class="app-heart"></svg-icon>
        <span>{{ language.guessLike }}</span>
      </div>
      <div class="header-right">
        <!-- <svg-icon icon-class="app-refresh" @click="onload" :class="[loadingClass]"></svg-icon> -->
      </div>
    </div>
    <div class="guessLike-body-boxers">
      <div class="body-list-boxers flexbox">
        <div class="body-list-item" v-for="(item, index) in guessLikeData" :key="index" @click="onChangeDetail(item)">
          <div class="guess-like-img imgFlex">
            <img v-lazy="getImgUrl(item.image)" />
          </div>
          <div class="guess-like-hint noText">
            {{ item.highlights }}
          </div>
          <div class="guess-like-name text2">
            {{ item.name }}
          </div>
          <div class="guess-like-price">{{ moneyIcon }}{{ item.unitAmount }}</div>
          <div class="guess-like-option flexbox">
            <span>{{ language.buyNow }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>import imgApi from "@/utils/projectConfig.js";
export default {
  name: "Index",
  props: {
    listData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data () {
    return {
      imgApi: imgApi.imgUrl,
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      language: this.$language(),
      guessLikeData: [],
      loadingClass: "",
    };
  },
  mounted () {
    this.guessLikeData = this.listData;
  },
  methods: {
    onload () {
      this.loadingClass = "loading-active";
    },
    onChangeDetail (record) {
      this.$emit("hotDetailCheck", record);
    },
    getImgUrl (url) {
      return this.imgApi + url
    },
  },
  watch: {
    listData: {
      handler (newVal, oldVal) {
        this.guessLikeData = newVal;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.goodsDetail-guess-like {
  .guess-like-header {
    padding: 0 36px;
    height: 110px;
    justify-content: space-between;
    color: #1d1f21;
    font-size: 36px;
    font-weight: 600;

    svg {
      color: #1d1f21;
    }

    .header-left {
      svg {
        width: 42px !important;
        height: 42px !important;
        margin-right: 12px;
      }
    }

    .header-right {
      svg {
        width: 52px !important;
        height: 52px !important;
      }
    }
  }

  .guessLike-body-boxers {
    width: 100%;
    padding: 0 36px;

    .body-list-boxers {
      width: 100%;
      max-width: 100%;
      overflow-x: scroll;

      .body-list-item {
        width: 430px;
        min-width: 430px;
        border-radius: 18px;
        overflow: hidden;
        background-color: #fff;
        color: #1d1f21;
        margin-right: 30px;

        .guess-like-img {
          width: 100%;
          height: 413px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .guess-like-hint {
          margin-top: 15px;
          font-size: 24px;
          line-height: 1.5;
          color: #8f8f8f;
        }

        .guess-like-name {
          margin-top: 15px;
          line-height: 1.5;
          font-size: 28px;
          text-align: left;
        }

        .guess-like-price {
          text-align: left;
          margin-top: 15px;
          font-size: 32px;
          font-weight: 600;
          color: $blur;
        }

        .guess-like-option {
          padding: 30px 0;
          justify-content: center;

          span {
            font-size: 28px;
            padding: 12px 32px;
            border-radius: 50px;
            color: #fff;
            background-color: #6d3580;
          }
        }
      }
    }
  }

  .loading-active {
    transform-origin: 50% 50%;
    transition: all 0.3s ease;
    animation: loadingActive 0.8s linear infinite;
  }

  @keyframes loadingActive {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
