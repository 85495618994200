var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-presSale-warper"},[_c('appHeader',{attrs:{"navTitle":_vm.language.navTitle}}),_c('div',{staticClass:"app-presSale-body"},[_c('van-tabs',{staticClass:"card-tabs-boxers",attrs:{"swipe-threshold":"3","ellipsis":false},on:{"change":_vm.onChangeTabs},model:{value:(_vm.tabsActive),callback:function ($$v) {_vm.tabsActive=$$v},expression:"tabsActive"}},_vm._l((_vm.tabsDataList),function(item,index){return _c('van-tab',{key:index,attrs:{"title":item.name}},[_c('div',{staticClass:"card-select-boxers"},[_c('div',{staticClass:"card-select"},[_c('div',{staticClass:"select-active-name flexbox",on:{"click":_vm.onSelectIsShow}},[_c('span',[_vm._v(_vm._s(_vm.formObj.categoryLabel))]),_c('van-icon',{attrs:{"name":"arrow-down"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectIsShow),expression:"selectIsShow"}],staticClass:"select-option"},_vm._l((_vm.classificationList),function(item,index){return _c('div',{key:index,class:[
                  'option-list-item',
                  'flexbox',
                  _vm.formObj.categoryId == item.id && 'selectActive',
                ],on:{"click":function($event){return _vm.onChangeSelect(item, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)])]),_c('div',{staticClass:"card-list-body"},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":_vm.message.listNullText,"loading-text":_vm.message.loadingText},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"card-list-boxers flexbox"},_vm._l((_vm.preSaleList),function(item,index){return _c('div',{key:index,staticClass:"list-item",on:{"click":function($event){return _vm.$router.push({
                    path: '/appGoodsDetail',
                    query: { id: item.id, sourcePage: 'presale' },
                  })}}},[_c('div',{staticClass:"list-item-img imgFlex"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getImgUrl(item.image)),expression:"getImgUrl(item.image)"}]}),_c('div',{staticClass:"img-count-down flexbox text-warper"},[_vm._v(" "+_vm._s(_vm.language.listLabel)+" "+_vm._s(item.shipDay)+" "+_vm._s(_vm.language.listLabe2)+" ")])]),_c('div',{staticClass:"list-item-payment text"},[_vm._v(" "+_vm._s(item.highlights)+" ")]),_c('div',{staticClass:"list-item-name text2"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"list-item-price"},[_vm._v(" "+_vm._s(_vm.moneyIcon)+_vm._s(item.unitAmount)+" ")])])}),0)])],1)])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }