// 俄语

let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "Дом",
    },
    "searchLanguage": {
        searchBtn: 'Поиск',
        searchPlace: 'Поиск....'
    },
    'setDrawer': {
        label1: 'Многоязычные настройки',
        label2: 'Продавец обосновался',
        label3: 'Логин продавца',
        label4: 'Служба поддержки клиентов',
        label5: 'Загрузить приложение для Android',
        label6: 'Загрузить приложение для iOS',
        optionsBtn: 'Отмена'
    },
    "appService": {
        navTitle: 'Служба поддержки клиентов',
        iconInformation: 'Пожалуйста, выберите коммуникационное программное обеспечение для консультации',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
    },
    "appMerchantSettlement": {
        navTitle: 'Купец обосновался',
        label1: 'Имя продавца',
        label1Hint: 'Название продавца или торговая марка, если они установлены, не могут быть изменены',
        visible1: 'Пожалуйста, введите название продавца',

        label2: 'Почтовый ящик',
        label2Hint: 'Пароль, используемый продавцом для входа на портал продавца. ',
        visible2: 'Пожалуйста, введите свой адрес электронной почты',
        label3: 'Код подтверждения электронной почты',
        label3Hint: 'Код необходим для подтверждения личности продавца и предотвращения вредоносной деятельности. Пожалуйста, предоставьте правильную информацию о коде подтверждения',
        visible3: 'Пожалуйста, введите код подтверждения электронной почты',

        label4: 'Пароль продавца',
        label4Hint: 'Пароль, используемый продавцом для входа на портал продавца. ',
        visible4: 'Пожалуйста, введите пароль продавца',
        label5: 'пригласительный код',
        visible5: 'Пожалуйста, введите код приглашения',
        label5Hint: 'Введите пригласительный код продавца, который вас пригласил. ',

        label6: 'Сохранить изображение',
        label6Hint: 'Изображение магазина продавца',
        visible6: 'Пожалуйста, введите код подтверждения',
        label7: 'Сертификат продавца',
        label7Hint: 'Сертификационные документы продавца, такие как торговая лицензия, свидетельство о налоговой регистрации и т. д. Эти файлы должны представлять собой файлы изображений высокого качества. ',
        visible7: 'Пожалуйста, загрузите изображения вашей торговой лицензии и соответствующих отраслевых квалификационных сертификатов (вы можете загрузить до 2 изображений, максимальный размер каждого изображения — 5 МБ, формат изображений поддерживает Jpg и PNG)',

        placeholderEnter: 'Пожалуйста, введите',
        placeholderSelect: 'Пожалуйста, выберите',
        sendEmail: 'Отправить',
        optionBtn1: 'Условия обслуживания',
        optionBtn2: 'Защита продавца',
        optionBtn3: 'Отправить на рассмотрение',
        uploadMessageError: 'Не удалось загрузить изображение',
        UploadLayoutError: 'Ошибка формата изображения',
        fileFail: 'Пожалуйста, загрузите и отформатируйте изображения',
        popupTitle1: 'Условия обслуживания',
        popupTitle2: 'Соглашение о защите продавца'
    },
    'appSetLanguage': {
        navTitle: 'Многоязычные настройки',
        affirm: 'подтвердить',
    },
    'classifyDrawer': {
        navTitle: 'Категория'
    },
    'appHome': {
        recommendTitle: 'Рекомендую',
        viewAll: 'просмотреть все',
        usedTitle: 'Б/у',
        buyNow: 'Купить сейчас',
        shopTitle: 'Торговец',
        shopOption: 'Шкаф',
        hotTitle: 'Горячий',
        ranking: 'Рейтинг продаж',
        preSaleTitle: 'Предпродажа',
        preSaleTabs1: 'В процессе',
        preSaleTabs2: 'Не запущено',
        preSaleTabs3: 'Конец',
        groupTitle: 'Группа',
        peopleGroupBuying: 'участвовали люди',
        flashSaleTitle: 'Flash',
        preSaleStateTimeS: 'старт',
        preSaleStateTimeE: 'конец',
        showNow: 'Иди за покупками сейчас'
    },
    "appShopsListIndex": {
        navTitle: 'Список торговцев',
        searchPlace: 'Искать...',
        favoriteTitle: 'Любимое количество &',
        shopBtn: 'Просмотр гардероба',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'Категория',
        viewAll: 'просмотреть все',
    },

    'appClassProductIndex': {
        navTitle: 'Продукт',
        select1: 'Комплексный',
        select2: 'Объем продаж',
        select3: 'Новый продукт',
        select4: 'Цена от высокой к низкой',
        select5: 'Цена от низкой к высокой',
        select6: 'Рейтинг',
        paymentTitle: 'Платеж',

    },
    'appPreSale': {
        navTitle: 'Предпродажа',
        tabs1: 'В процессе',
        tabs2: 'Не запущено',
        tabs3: 'Закончено',
        selectAll: 'Все',
        listLabel: 'Доставка',
        listLabe2: 'день',
    },
    "appGroupBuying": {
        navTitle: 'Группа',
        selectAll: 'Все',
        listLabel: 'Нужно',
        listLabe2: 'люди',
    },
    "appFlashSale": {
        navTitle: 'Вспышка',
        selectAll: 'Все',
    },
    'appSalesRanking': {
        navTitle: 'Горячий',
        payment: 'платеж',
        optionBtn1: 'Собрать',
        optionBtn2: 'Купить сейчас',
    },
    'appLogin': {
        navTitle: 'Войти',
        navTitleHint: 'Войдите в систему сейчас и начните торговать с друзьями со всего мира!',
        singUp: 'Зарегистрироваться',

        emailLabel: 'Номер мобильного телефона',
        emailPlaceholder: 'Пожалуйста, введите номер вашего мобильного телефона',
        emailErrorText: 'Сообщение об ошибке! ',
        emailNullVerify: 'Пожалуйста, введите номер своего мобильного телефона!',//Пожалуйста, введите свой адрес электронной почты
        emailErrorVerify: 'Пожалуйста, введите номер своего мобильного телефона! ',//Ошибка формата электронной почты

        passwordLabel: 'ваш пароль',
        passPlaceholder: 'Пожалуйста, введите пароль',
        passNullVerify: 'Пожалуйста, введите свой пароль', //Пожалуйста, введите свой пароль
        passErrorVerify: 'Пароль меньше 6 символов! ',//Пароль меньше 6 символов

        placeholder: 'Пожалуйста, введите',

        emailRegistration: 'Зарегистрируйтесь сейчас',
        forgetPassTitle: 'Забыли пароль? ',

        submitBtnTitle: 'Войти',
        loginMessage: 'Учетная запись или пароль неверны! '
    },
    'appForgotPass': {
        navTitle: 'Забыли пароль',
        navTitleHint: 'Пожалуйста, введите номер своего мобильного телефона и получите код подтверждения, чтобы установить новый пароль. ',
        emailLabel: 'Номер мобильного телефона',
        emailPlaceholder: 'Пожалуйста, введите номер вашего мобильного телефона',
        emailErrorText: 'Сообщение об ошибке!',//Пожалуйста, введите свой адрес электронной почты.
        emailNullVerify: 'Пожалуйста, введите номер своего мобильного телефона!',//Пожалуйста, введите свой адрес электронной почты
        emailErrorVerify: 'Пожалуйста, введите номер своего мобильного телефона!', // Ошибка формата электронной почты.
        verificationCode: 'код подтверждения',
        verificationPlace: 'код подтверждения',
        verificationCodeError: 'Пожалуйста, введите код подтверждения! ',
        sendTitle: 'Отправить код подтверждения',
        sendText: 'Повторить',
        passwordLabel: 'Новый пароль',
        passPlaceholder: 'Пожалуйста, введите новый пароль',
        confirmPassLabel: 'Подтвердите новый пароль',
        confirmPlace: 'Подтвердите новый пароль',
        passNullVerify: 'Введите новый пароль', //Пожалуйста, введите свой пароль
        passErrorVerify: 'Пароль меньше 6 символов! ',//Пароль меньше 6 символов
        passCompareErrorVerify: 'Пароли, введенные дважды, несовместимы!',
        passCompareErrorNull: 'Пожалуйста, введите новый пароль еще раз',
        submitBtnTitle: 'Сбросить пароль',
        loginMessage: 'Код подтверждения неверен! '
        // emailRegistration: 'Регистрация по электронной почте',
        //forgetPassTitle: 'Забыли пароль?',

    },
    'appRegister': {
        navTitle: 'Регистрация',
        navTitleHint: 'Создайте учетную запись, чтобы получить доступ ко всем функциям! ',
        namePlace: 'Пожалуйста, введите',
        firstNameLabel: 'имя',
        firstNamePlace: 'Пожалуйста, введите',
        firstNameErrorVerify: 'Пожалуйста, введите свое имя',
        lastNameLabel: 'фамилия',
        lastNameErrorVerify: 'Пожалуйста, введите свою фамилию',
        nickNameLabel: 'псевдоним',
        nickNameErrorVerify: 'Пожалуйста, введите свой никнейм',
        emailLabel: 'Номер мобильного телефона',
        emailPlaceholder: 'Пожалуйста, введите номер вашего мобильного телефона',
        emailErrorText: 'Сообщение об ошибке!',//Пожалуйста, введите свой адрес электронной почты.
        emailNullVerify: 'Пожалуйста, введите номер своего мобильного телефона!',//Пожалуйста, введите свой адрес электронной почты
        emailErrorVerify: 'Пожалуйста, введите номер своего мобильного телефона! ',//Ошибка формата электронной почты
        verificationCode: 'код подтверждения',
        verificationPlace: 'Пожалуйста, введите код подтверждения',
        sendTitle: 'Отправить код подтверждения',
        sendText: 'Обратный отсчет',
        verificationCodeError: 'Пожалуйста, введите код подтверждения! ',
        passwordLabel: 'пароль',
        passPlaceholder: 'Пожалуйста, введите пароль',
        confirmPassLabel: 'Подтвердить пароль',
        confirmPlace: 'Подтвердить пароль',
        passNullVerify: 'Введите свой пароль', //Пожалуйста, введите свой пароль
        passErrorVerify: 'Пароль меньше 6 символов! ',//Пароль меньше 6 символов
        passCompareErrorVerify: 'Пароли, введенные дважды, несовместимы!',
        passCompareErrorNull: 'Пожалуйста, введите пароль еще раз',
        registerHint: 'У вас уже есть учетная запись? ',
        registerHintLogin: 'Войти',
        submitBtnTitle: 'Зарегистрироваться',
        loginMessage: 'Код подтверждения неверен! '

        // gender: 'Gender',
        // gender1: 'Boy',
        // gender2: 'Girl',
        // gender3: 'Secrecy',
        // genderError: 'Please enter your gender',
    },
    'appGoodsDetail': {
        salesTitle: 'Продажи',
        payHintTitle1: 'Баланс',
        payHintTitle2: 'PayPal',
        payHintTitle3: 'Виза',
        conditionLabel: 'Условие:',
        conditionContent: 'Способы оплаты, поддерживаемые категориями брендов',
        frightLabel: 'Логистика',
        frightContent: 'Доставка в среднем в течение 1-3 дней',
        deliverTime: 'Соглашение о защите покупателя',
        specSelect: 'Пожалуйста, выберите спецификацию',
        specLabel: 'Спецификация',
        specOptions: 'подтвердить',
        countLabel: 'Количество',
        commentTitle: 'Комментарий',
        agreement: 'Оценить время и дни доставки',
        salesLabel: 'Объем продаж:',
        productCount: "Количество товара:",
        collectLabel: 'Количество коллекции:',
        averageLabel: 'Среднее количество дней:',
        since: 'День участника',
        shipper: 'быстрый ответчик',
        responder: 'Быстрый отправитель',
        reliable: 'надежный',
        btnContact: 'Контакт',
        enter: 'шкаф',
        followBtn: 'Коллекция',
        guessLike: 'думаю, тебе понравится',
        buyNow: 'Купить сейчас',
        dialogOverviewTitle: 'Обзор',
        overConditionLabel: 'Условие',
        overConditionContent: 'новый',
        overBrandLabel: 'Бренд',
        overCategoryLabel: 'Категория',
        overPayMethodsLabel1: 'Поддерживаемые способы оплаты',
        overPayMethodsLabel2: 'Важная информация',
        overCategoryEnter: 'Подтвердить',
        dialogFreightTitle: 'Логистическое соглашение',
        dialogAgreementTitle: 'Соглашение о защите покупателя',
        footerLeft: 'Добавить в корзину',
        footerRight: 'Купить сейчас',
        activeLabel1: 'Групповая покупка',
        activeLabel2: 'Вторая распродажа',
        activeLabel3: 'Предпродажа',
        flashStateNameE: 'Конец',
        flashStateNameI: 'В процессе',
        flashStateNameS: 'Не запущено',
        flashHintSName: 'Пуск',
        flashHintEName: 'Завершено',
        flashHintIName: 'Оставшееся время',
        preSaleStateTimeNO: 'Не запущено',
        preSaleStateTimeE: 'Конец',
        preSaleStateTimeS: 'Текущий',
        preSaleHintNoName: 'Начать',
        preSaleHintIEName: 'Завершено',
        preSaleHintEName: 'Конец',
        groupHint: 'человек',
        reviewTitle: 'Обзор системы по умолчанию'
    },
    "appShops": {
        select1: 'Комплексный',
        select2: 'Объем продаж',
        select3: 'Новый продукт',
        select4: 'Цена от высокой к низкой',
        select5: 'Цена от низкой к высокой',
        select6: 'Рейтинг',
        infoLabel1: 'Объем продаж',
        infoLabel2: 'Количество товара',
        infoLabel3: 'Количество коллекции',
        infoLabel4: 'Оценить время доставки',
        memberLabel1: 'День участника',
        memberLabel2: 'Быстрый ответчик',
        memberLabel3: 'Быстрая доставка',
        memberLabel4: 'надежный',
        commodity: 'товар',
        classAll: 'все',

        // timeDay: 'Evaluate Delivery time & days',
        // productQuantity: 'Product Quantity',
        // shippedQuantity: 'Shipped Quantity',
        // collections: "Number of Collections",
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 商品评论页
    'appProductReviews': {
        navTitle: 'Комментарии',
        reviewTitle: 'Системные комментарии по умолчанию',
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'Обновление версии, пожалуйста, подтвердите? ',
        loadingTitle: 'Загрузка...',
        systemUpgrade: 'Обновление системы',
        upgradeImmediately: 'Подтвердить',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "Загрузка", // Загрузка
        finishedText: "Нет", //Нет
        loadingText: "Загрузка", // Загрузка
        nullText: 'Данных пока нет',
        loadingFinished: "Загрузка завершена",
        listNullText: 'Больше нет',
        pullIngText: 'Потяните вниз, чтобы обновить',
        looseText: 'Отпустите для обновления',
        pullingSuccess: 'Последующая загрузка прошла успешно',
        source: 'подтверждаю'
    },

};
export default obj;
