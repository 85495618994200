<template>
  <div class="appShops-page-warper">
    <div class="app-shops-header">
      <svg-icon
        class="shops-back"
        icon-class="app-header-back"
        @click="$router.go('-1')"
      />
      <div class="appShops-bg">
        <img v-lazy="getImgUrl()" />
      </div>
      <div class="app-shops-search">
        <searchCard @searchChange="searchChange"></searchCard>
      </div>
    </div>
    <div class="app-shops-body">
      <div class="appShops-logo flexbox">
        <div class="logo-img imgFlex">
          <img v-lazy="shopInfoObj.image" />
        </div>
        <div class="shop-name">
          <div class="logo-name">{{ shopInfoObj.name }}</div>
          <div class="info-nav-stareLeave">
            <div class="info-nav-stareLeave flexbox">
              <div
                v-for="(svgItem, svgIndex) in 5"
                :key="svgIndex"
                :class="[
                  'stare-leave',
                  shopInfoObj.star >= svgIndex + 1 && 'star-active',
                ]"
              >
                <svg-icon icon-class="app-heard"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shops-icon-boxers">
        <div class="icon-list" v-for="(item, index) in iconList" :key="index">
          <div class="icon-list-img imgFlex">
            <img v-lazy="getIconUrl(index + 1)" alt="" />
          </div>
        </div>
      </div>

      <div class="shops-sales-status">
        <div class="sales-status-list">
          <p>{{ shopInfoObj.productSale }}</p>
          <p class="noText">{{ language.infoLabel1 }}</p>
        </div>
        <div class="sales-status-list">
          <p>{{ shopInfoObj.productCount }}</p>
          <p class="noText">{{ language.infoLabel2 }}</p>
        </div>
        <div class="sales-status-list">
          <p>{{ shopInfoObj.collect }}</p>
          <p class="noText">{{ language.infoLabel3 }}</p>
        </div>
        <div class="sales-status-list">
          <p>{{ shopInfoObj.shipDay }}</p>
          <p class="noText">{{ language.infoLabel4 }}</p>
        </div>
      </div>
      <div class="shops-commodity-body">
        <p class="commodity-title">
          <svg-icon icon-class="app-commodity"></svg-icon>
          <span> {{ language.commodity }}</span>
        </p>
        <div class="commodity-select-boxers flexbox">
          <div class="select-boxers">
            <p class="select-active" @click="onChangeTabs('select')">
              <span>{{ selectActiveTitle }}</span>
              <van-icon name="arrow-down" />
            </p>
            <transition>
              <div class="select-options" v-show="selectActiveIsShow">
                <div
                  v-for="(item, index) in selectList"
                  :key="index"
                  :class="[
                    'select-options-list',
                    selectActive === index && 'select-options-active',
                  ]"
                  @click="onChangeSelect(item, index)"
                >
                  {{ item.label }}
                </div>
              </div>
            </transition>
          </div>
          <div class="class-boxers">
            <p class="class-active" @click="onChangeTabs('class')">
              <svg-icon icon-class="app-shops-class"></svg-icon>
              <span>{{ classActiveTitle }}</span>
            </p>
            <transition>
              <div class="class-options" v-if="classActiveIsShow">
                <div
                  v-for="(item, index) in classListData"
                  :key="index"
                  :class="[
                    'class-options-list',
                    classActive === index && 'class-options-active',
                  ]"
                  @click="onChangeClass(item, index)"
                >
                  <svg-icon
                    :icon-class="item.svgIcon"
                    v-if="item.id == 'all'"
                  ></svg-icon>
                  <div class="class-img-item imgFlex" v-else>
                    <img v-lazy="getUrlImg(item.icon)" />
                  </div>
                  <span> {{ item.name }}</span>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="class-product-list">
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="message.listNullText"
            :loading-text="message.loadingText"
            @load="onLoad"
          >
            <div class="product-list-boxers" v-if="shopsListData.length">
              <div
                class="list-item-card"
                v-for="(item, index) in shopsListData"
                :key="index"
                @click="pageTumps(item)"
              >
                <div class="list-item-img imgFlex">
                  <img v-lazy="getUrlImg(item.image)" />
                </div>
                <div class="list-item-name text2">
                  {{ item.name }}
                </div>
                <div class="list-item-price">
                  {{ moneyIcon }}{{ item.unitAmount }}
                </div>
              </div>
            </div>
            <default-page v-else></default-page>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searchCard from "../component/app-search.vue";
import appShopsDetailApi from "@/api/h5Api/appShopsDetailApi";
import imgApi from "@/utils/projectConfig";
export default {
  name: "appShopsPage",
  data() {
    return {
      language: this.$language(),
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      imgApi: imgApi.imgUrl,
      selectList: [],
      selectActive: 0,
      selectActiveIsShow: false,
      selectActiveTitle: "",

      classListData: [],
      classActive: 0,
      classActiveIsShow: false,
      classActiveTitle: "",

      message: this.$language("messageLanguage"),
      loading: false,
      finished: false,
      shopInfoObj: {
        productSale: 0,
        productCount: 0,
        collect: 0,
        shipDay: 0,
      },
      formObj: {
        page: 1,
        size: 20,
        total: 0,
        name: "",
        sort: "default",
        categoryId: "",
      },
      shopsListData: [],
      iconList: [],
      configImgList: "",
    };
  },
  created() {
    this.onInit();
  },
  methods: {
    onInit() {
      this.getShopInfoData();
      this.selectList = [
        { value: "default", label: this.language.select1 },
        { value: "sale", label: this.language.select2 },
        { value: "news", label: this.language.select3 },
        { value: "prizeDesc", label: this.language.select4 },
        { value: "prizeAsc", label: this.language.select5 },
        { value: "star", label: this.language.select6 },
      ];
      this.selectActiveTitle = this.selectList[this.selectActive].label;
      this.formObj.sort = this.selectList[this.selectActive].value;
      this.get_classTree();
      this.iconList = [
        this.language.memberLabel1,
        this.language.memberLabel2,
        this.language.memberLabel3,
        this.language.memberLabel4,
      ];
      this.configImgList = "";
      if (this.$route.query.sourcePage === "shopsList") {
        this.getImgList();
      }
    },
    async getImgList() {
      const { code, data } = await appShopsDetailApi.get_imageList();
      if (code == 200) {
        if (data.shopListTopImage) {
          this.configImgList = this.imgApi + data.shopListTopImage;
        }
      }
    },
    getImgUrl() {
      return this.shopInfoObj.backgroundImage;
    },
    onLoad() {
      this.getShopsDetailList();
    },
    pageTumps(item) {
      console.log(this.shopInfoObj);
      this.$router.push({
        path: "/appGoodsDetail",
        query: {
          shopId: this.shopInfoObj.id,
          id: item.id,
          sourcePage: "shopDetail",
        },
      });
    },
    onChangeTabs(key) {
      if (key === "select") {
        this.classActiveIsShow = false;
        this.selectActiveIsShow = !this.selectActiveIsShow;
      }
      if (key === "class") {
        this.selectActiveIsShow = false;
        this.classActiveIsShow = !this.classActiveIsShow;
      }
    },
    onChangeSelect(record, index) {
      this.selectActive = index;
      this.formObj.sort = record.value;
      this.selectActiveTitle = record.label;
      this.selectActiveIsShow = false;
      this.finished = false;
      this.loading = false;
      this.shopsListData = [];
      this.formObj.page = 1;
      this.getShopsDetailList();
    },
    onChangeClass(record, index) {
      this.classActive = index;
      this.formObj.categoryId = record.id;
      this.classActiveTitle = record.name;
      this.classActiveIsShow = false;
      this.finished = false;
      this.loading = false;
      this.shopsListData = [];
      this.formObj.page = 1;
      this.getShopsDetailList();
    },
    searchChange(val) {
      this.formObj.name = val;
      this.finished = false;
      this.loading = false;
      this.shopsListData = [];
      this.formObj.page = 1;
      this.getShopsDetailList();
    },
    // 店铺基本信息获取
    getShopInfoData() {
      const params = {
        shopId: this.$route.query.id,
      };
      appShopsDetailApi.get_shopInfoData(params).then(({ code, data }) => {
        if (code == 200) {
          data.backgroundImage = this.imgApi + data.backgroundImage;
          data.image = this.imgApi + data.image;
          this.shopInfoObj = { ...data };
        }
      });
    },
    // 获取分类数据
    async get_classTree() {
      this.recommendIsShow = false;
      let { code, data } = await appShopsDetailApi.getClassIfy_List();
      if (code === 200) {
        this.classListData = data;
        this.classListData.unshift({
          svgIcon: "app-shopsClass-icon",
          id: "all",
          name: this.language.classAll,
        });
        this.classActive = 0;
        this.classActiveTitle = this.classListData[this.classActive].name;
        this.formObj.categoryId = this.classListData[this.classActive].id;
      }
    },
    async getShopsDetailList() {
      this.loading = true;
      let categoryId;
      if (this.formObj.categoryId === "all") {
        categoryId = "";
      }
      const params = {
        shopId: this.$route.query.id,
        categoryId: categoryId,
        name: this.formObj.name,
        sort: this.formObj.sort,
        page: this.formObj.page,
        size: this.formObj.size,
      };
      const { code, data } = await appShopsDetailApi.get_shopProductData(
        params
      );
      if (code == 200) {
        this.loading = false;
        data.list.map((item) => {
          item.originalAmount = Number(item.originalAmount / 100).toFixed(2);
          item.unitAmount = Number(item.unitAmount / 100).toFixed(2);
        });
        this.shopsListData = this.shopsListData.concat(data.list);
        this.formObj.total = data.total;
        if (this.shopsListData.length >= this.formObj.total) {
          this.finished = true;
        } else {
          this.finished = false;
          this.formObj.page++;
        }
      }
    },
    getUrlImg(url) {
      return this.imgApi + url;
    },
    getIconUrl(index) {
      return require(`../../../assets/h5/member_icon${index}.png`);
    },
  },
  components: { searchCard },
};
</script>

<style lang="scss" scoped>
.appShops-page-warper {
  width: 100%;
  height: 100%;
  font-family: "GrifaSlabRegular";
  background: #f7f7fb;

  .app-shops-header {
    width: 100%;
    height: 30%;
    position: relative;
    background: #fff;

    .shops-back {
      position: absolute;
      top: 110px;
      left: 50px;
      width: 42px !important;
      height: 42px !important;
      z-index: 2;
      color: #6e3681;
    }

    .app-shops-search {
      width: 100%;
      position: absolute;
      top: 160px;

      ::v-deep .van-cell {
        height: 80px;
        background: rgb(255 255 255 / 40%);
      }
    }

    .appShops-bg {
      width: 100%;
      height: 100%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      img {
        max-width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
  }

  .app-shops-body {
    width: 100%;
    height: 100%;
    padding: 0 20px;

    .appShops-logo {
      margin-top: -50px;
      z-index: 2;

      .logo-img {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        width: 156px;
        height: 156px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        z-index: 2;

        img {
          z-index: 2;
        }
      }

      .shop-name {
        padding-left: 15px;
        height: auto;
        padding-top: 45px;

        .logo-name {
          color: #1d1e20;
          font-size: 36px;
          font-weight: 600;
        }

        .info-nav-stareLeave {
          margin-top: 2px;
          display: flex;
          align-items: center;

          .stare-leave {
            cursor: pointer;
            margin-right: 2px;

            .svg-icon {
              color: #dbdbdf;
              cursor: pointer;
              width: 24px !important;
              height: 24px !important;
            }
          }

          .star-active {
            .svg-icon {
              color: #ff2f2f !important;
            }
          }
        }
      }
    }

    .shops-icon-boxers {
      padding: 20px;
      padding-top: 35px;
      border-radius: 18px;
      margin-top: 30px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-around;
      background: #fff;

      .icon-list {
        margin-bottom: 15px;

        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;

        .icon-list-img {
          width: 68px;
          height: 68px;
        }

        p {
          margin-top: 15px;
          line-height: 1.5;
          text-align: center;
          font-size: 24px;
          color: #959ba3;
        }
      }
    }

    .shops-sales-status {
      padding: 20px;
      border-radius: 18px;
      background-color: #fff;
      margin-top: 15px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      .sales-status-list {
        width: 49%;
        margin-bottom: 15px;
      }

      p {
        width: 100%;
        text-align: center;
      }

      p:nth-child(2) {
        color: #959ba3;
        font-size: 24px;
        line-height: 1.5;
        margin-top: 12px;
      }

      p:nth-child(1) {
        margin-top: 15px;
        color: #1d1e20;
        font-size: 32px;
        font-weight: 600;
        line-height: 1.5;
      }
    }

    .shops-commodity-body {
      margin-top: 15px;
      background: #fff;
      padding: 20px;
      border-radius: 18px;
      .commodity-title {
        display: flex;
        align-items: center;

        svg {
          width: 42px !important;
          height: 42px !important;
          color: #1d1e20;
          margin-right: 12px;
        }

        span {
          font-size: 36px;
          color: #1d1e20;
          font-weight: 600;
        }
      }

      .commodity-select-boxers {
        justify-content: space-between;
        align-items: flex-start;
        background: #fff;
        padding: 30px 0;
        .select-boxers {
          width: 50%;
          position: relative;

          p {
            color: #6d3580;
            font-size: 28px;

            svg {
              width: 36px !important;
              height: 36px !important;
            }

            span {
              margin-right: 12px;
            }
          }

          .select-options {
            width: 95%;
            position: absolute;
            top: 70px;
            left: 0;
            background-color: #fff;
            box-shadow: 0 0 15px 0 #d9d9d9;
            border-radius: 18px;
            overflow: hidden;

            .select-options-list {
              padding: 30px 36px;
              display: flex;
              align-items: center;
              font-size: 26px;
              font-weight: 600;
              color: #8f959e;
            }

            .select-options-active {
              background-color: #6d3580;
              color: #fff;

              svg {
                color: #fff;
              }
            }
          }
        }

        .class-boxers {
          width: 50%;
          position: relative;

          svg {
            width: 36px !important;
            height: 36px !important;
            color: #6d3580;
          }

          p {
            text-align: right;
            color: #6d3580;
            font-size: 28px;

            span {
              margin-left: 12px;
            }
          }

          .class-options {
            width: 95%;
            max-height: calc(110px * 6);
            position: absolute;
            top: 70px;
            right: 0;
            background-color: #fff;
            box-shadow: 0 0 15px 0 #d9d9d9;
            border-radius: 18px;
            overflow: hidden;
            overflow-y: scroll;
            .class-options-list {
              display: flex;
              align-items: center;
              padding: 30px 36px;
              color: #8f959e;
              font-size: 26px;
              font-weight: 600;

              span {
                margin-left: 12px;
              }

              .class-img-item {
                width: 36px;
                height: 36px;
              }
            }

            .class-options-active {
              background-color: #6d3580;
              color: #fff;

              svg {
                color: #fff;
              }
            }
          }
        }
      }

      .class-product-list {
        background: #f7f7fb;

        ::v-deep .van-list {
          background: #f7f7fb;

          .product-list-boxers {
            padding: 20px;
            border-radius: 18px;
            background: #fff;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .list-item-card {
              width: 49%;
              padding: 20px;
              background-color: #fff;
              border-radius: 18px;
              overflow: hidden;
              margin-bottom: 20px;

              .list-item-img {
                width: 100%;
                height: 400px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .list-item-name {
                font-size: 28px;
                color: #1d1e20;
                margin-top: 12px;
              }

              .list-item-price {
                margin-top: 20px;
                font-size: 34px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  /* 设置持续时间和动画函数 */
  .v-enter-active,
  .v-leave-active {
    transition: all 0.3s ease;
  }

  .v-enter,
  .v-leave-to {
    transform: translateY(-10px);
    opacity: 0;
  }
}
</style>
