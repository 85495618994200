<template>
  <div class="appHome-preSalCard-warper">
    <div class="appHome-preSalCard-header flexbox">
      <div class="header-left flexbox">
        <svg-icon icon-class="app-countDown" class="appHomeSvg"></svg-icon>
        <span class="title-left">{{ language.preSaleTitle }}</span>
      </div>
      <div class="header-right">
        <span
          class="title-right"
          @click="$router.push({ path: '/appPreSale' })"
        >
          {{ language.viewAll }}
        </span>
      </div>
    </div>
    <div class="appHome-preSalCard-body">
      <van-tabs
        v-model="activeTabs"
        class="preSalCard-tabs"
        @change="onChangeTabs"
        :ellipsis="false"
        animated
      >
        <van-tab
          v-for="(item, index) in tabsList"
          :key="index"
          :title="item.label"
        >
        </van-tab>
      </van-tabs>
      <div class="tabs-list-boxers">
        <van-swipe
          v-if="preSaleDataList.length"
          class="list-item-swipe"
          indicator-color="#6d3580"
          @change="onChangeSwipe"
        >
          <van-swipe-item v-for="(item, lenIndex) in countLen" :key="lenIndex">
            <div
              class="tabs-list-item"
              v-for="(item, index) in setList(lenIndex)"
              :key="index"
              @click="
                $router.push({
                  path: '/appGoodsDetail',
                  query: { id: item.id, sourcePage: 'presale' },
                })
              "
            >
              <div class="list-img imgFlex">
                <img v-lazy="getUrl(item.image)" />
                <div class="list-item-time flexbox">
                  <span v-if="item.endTimeName"
                    >{{ item.endTimeName }} {{ tagName }}</span
                  >
                </div>
              </div>
              <div class="list-info">
                <div class="list-name text2">
                  {{ item.name }}
                </div>
              </div>
              <div class="list-price-btn">
                <div class="list-price">
                  {{ moneyIcon }} {{ item.unitAmount }}
                </div>
                <div class="list-options flexbox">
                  <span>{{ language.buyNow }}</span>
                </div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
        <defaultPage v-else></defaultPage>
      </div>
    </div>
  </div>
</template>

<script>
import appHomeApi from "@/api/h5Api/appHomeApi";
import imgApi from "@/utils/projectConfig.js";
export default {
  name: "appHomePreSaleCard",
  props: {
    classId: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {
      language: this.$language(),
      thumbnail: this.$thumbnail,
      imgApi: imgApi.imgUrl,
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      tabsList: [],
      activeTabs: 0,
      preSaleDataList: [],
      countLen: 4,
      current: 0,
      tagName: "",
    };
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onChangeTabs(e) {
      this.current = 0;
      this.activeTabs = e;
      this.getPreSaleData();
    },
    onChangeSwipe() {
      this.current = e;
    },
    onLoad() {
      this.tabsList = [
        { value: "start", label: this.language.preSaleTabs1 },
        { value: "wait", label: this.language.preSaleTabs2 },
        { value: "end", label: this.language.preSaleTabs3 },
      ];
      this.getPreSaleData();
    },
    async getPreSaleData() {
      const params = {
        state: this.tabsList[this.activeTabs].value,
        categoryId: this.classId,
        page: 1,
        size: 8,
      };
      const { code, data } = await appHomeApi.get_preSaleList(params);
      if (code == 200) {
        data.list.map((item) => {
          item.endTimeName = item.endTimeName?.split(" ")[0];
          item.unitAmount = (item.unitAmount / 100).toFixed(2);
        });
        this.preSaleDataList = data.list;
        this.countLen = 1;
        setTimeout(() => {
          this.countLen = Math.ceil(this.preSaleDataList.length / 4);
        });
        let state = this.tabsList[this.activeTabs].value;
        if (state === "start") {
          this.tagName = this.language.preSaleStateTimeE;
        }
        if (state === "wait") {
          this.tagName = this.language.preSaleStateTimeS;
        }
        if (state === "end") {
          this.tagName = this.language.preSaleStateTimeE;
        }
      }
    },
    getUrl(url) {
      return this.imgApi + url;
    },
    setList(index) {
      if (index === 0) {
        return this.preSaleDataList.slice(0, 4);
      } else {
        let count = index * 4;
        return this.preSaleDataList.slice(count, count + 4);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.appHome-preSalCard-warper {
  margin-top: 15px;

  .appHome-preSalCard-header {
    justify-content: space-between;
    padding: 0px 36px;
    height: 110px;
    z-index: 2;
    background-color: #fff;
  }

  .appHome-preSalCard-body {
    .preSalCard-tabs {
      ::v-deep .van-tabs__wrap {
        .van-tabs__nav {
          .van-tab {
            flex: none !important;

            .van-tab__text {
              font-size: 28px;
              color: #9ba0a8;
            }
          }

          .van-tab--active {
            .van-tab__text {
              color: #6d3580;
            }
          }
        }

        .van-tabs__line {
          background-color: #6d3580;
        }
      }

      .van-tabs__content {
        .van-tab__pane {
          overflow-x: hidden;
        }
      }
    }

    .tabs-list-boxers {
      width: 100%;

      .list-item-swipe {
        ::v-deep .van-swipe__track {
          background-color: #fff;
          padding: 0px;
          width: 100% !important;
        }

        ::v-deep .van-swipe__indicators {
          bottom: 15px;

          .van-swipe__indicator {
            width: 40px;
            height: 6px;
            border-radius: 68px;
            background: #bcbcbc;
          }

          .swipe__indicator--active {
            color: $blur;
          }
        }

        .van-swipe-item {
          width: 100% !important;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          background-color: #fff;
          padding: 20px;
          border-radius: 18px;
        }

        .tabs-list-item {
          width: calc((100% / 2) - 30px);
          background-color: #fff;
          border-radius: 18px;
          overflow: hidden;
          margin-right: 30px;
          margin-bottom: 30px;

          .list-img {
            width: 100%;
            height: 280px;
            position: relative;
            border-radius: 18px;
            overflow: hidden;

            .list-item-time {
              justify-content: center;
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 60px;
              background-color: rgba(94, 48, 108, 0.9);
              color: $appWhite;
              font-size: 22px;
            }
          }

          .list-info {
            margin-top: 12px;
            padding: 0 10px;
            //max-height: 90px;

            .list-name {
              //height: 90px;
              text-align: center;
              line-height: 30px;
              font-size: 24px;
              color: #1d1e20;
            }

            .list-name.text3 {
              -webkit-line-clamp: 3;
            }
          }

          .list-price-btn {
            background-color: #fff;

            .list-price {
              margin-top: 12px;
              color: #1d1e20;
              font-weight: 600;
              font-size: 36px;
              text-align: center;
            }

            .list-options {
              margin-top: 12px;
              justify-content: center;

              span {
                align-items: center;
                justify-content: center;
                padding: 12px 32px;
                background-color: $appBlur;
                color: $appWhite;
                border-radius: 46px;
              }
            }
          }
        }

        .tabs-list-item:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
