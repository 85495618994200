<template>
  <div class="app-notify-warper">
    <van-popup
      v-model="popupIsShow"
      position="top"
      :overlay="false"
      :close-on-click-overlay="false"
      duration="0.3"
      :transition-appear="false"
    >
      <div class="notify-body">
        {{ notifyTitle }}
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "appNotifyIndex",
  props: {
    timer: Number,
    notifyTitle: String,
    notifyIsShow: Boolean,
  },
  data() {
    return {
      popupIsShow: false,
    };
  },
  mounted() {
    this.popupIsShow = this.notifyIsShow;
    setTimeout(() => {
      this.popupIsShow = false;
      this.$emit("notifyIsShow");
    }, this.timer || 2500);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.app-notify-warper {
  .van-popup {
    top: 20px;
    left: 55%;
    .notify-body {
      padding: 20px 36px;
      // width: 40%;
      min-width: 20%;
      max-width: 40%;
      border-radius: 12px;
      background-image: linear-gradient(to right, #01b09a 50%, #8dc843);
      color: #fff;
      font-size: 32px;
      text-align: center;
    }
  }
}
</style>
