// 意大利语

let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "Casa",
    },
    "searchLanguage": {
        searchBtn: 'Ricerca',
        searchPlace: 'Ricerca....'
    },
    'setDrawer': {
        label1: 'Impostazioni multilingue',
        label2: 'Il commerciante si è sistemato',
        label3: 'Accesso commerciante',
        label4: "Servizio clienti",
        label5: 'Scarica l\'applicazione Android',
        label6: 'Scarica l\'applicazione ios',
        optionsBtn: 'Annulla'
    },
    "appService": {
        navTitle: "Servizio clienti",
        iconInformation: 'Seleziona il software di comunicazione per la consultazione',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
    },
    "appMerchantSettlement": {
        navTitle: 'Il commerciante si è sistemato',
        label1: 'Nome del commerciante',
        label1Hint: 'Il nome del commerciante o il nome del marchio, una volta impostato, non può essere modificato',
        visible1: 'Inserisci il nome del commerciante',

        label2: 'Cassetta postale',
        label2Hint: 'La password utilizzata dal commerciante per accedere al portale del commerciante. ',
        visible2: 'Inserisci la tua email',
        label3: 'Codice di verifica email',
        label3Hint: 'Codice richiesto per confermare l\'identità del commerciante e prevenire attività dannose. Fornisci le informazioni corrette sul codice di verifica',
        visible3: 'Inserisci il codice di verifica email',

        label4: 'Password commerciante',
        label4Hint: 'La password utilizzata dal commerciante per accedere al portale del commerciante. ',
        visible4: 'Inserisci la password del commerciante',
        label5: 'codice invito',
        visible5: 'Inserisci il codice di invito',
        label5Hint: 'Inserisci il codice di invito del commerciante che ti ha invitato. ',

        label6: 'Memorizza immagine',
        label6Hint: 'Immagine del negozio del commerciante',
        visible6: 'Inserisci il codice di verifica',
        label7: "Certificato commerciante",
        label7Hint: 'Documenti di certificazione del commerciante, come licenza commerciale, certificato di registrazione fiscale, ecc. Questi file dovrebbero essere file di immagine di alta qualità. ',
        visible7: 'Carica le immagini della tua licenza commerciale e i relativi certificati di qualificazione del settore (puoi caricare fino a 2 immagini, ogni immagine ha una dimensione massima di 5 MB e il formato dell\'immagine supporta Jpg e Png)',

        placeholderEnter: 'Inserisci',
        placeholderSelect: 'Seleziona',
        sendEmail: 'Invia',
        optionBtn1: 'Termini di servizio',
        optionBtn2: 'Protezione venditore',
        optionBtn3: 'Invia per la revisione',
        uploadMessageError: 'Caricamento immagine non riuscito',
        UploadLayoutError: "Errore formato immagine",
        fileFail: 'Carica e formatta le immagini',
        popupTitle1: 'Termini di servizio',
        popupTitle2: 'Contratto di protezione del venditore'
    },
    'appSetLanguage': {
        navTitle: 'Impostazioni multilingue',
        affirm: 'confermare',
    },
    'classifyDrawer': {
        navTitle: 'Categoria'
    },
    'appHome': {
        recommendTitle: 'Consiglia',
        viewAll: 'visualizza tutto',
        usedTitle: 'Usato',
        buyNow: 'Acquista ora',
        shopTitle: 'Commerciante',
        shopOption: 'Armadio',
        hotTitle: 'Caldo',
        ranking: 'Classifica delle vendite',
        preSaleTitle: 'Prevendita',
        preSaleTabs1: 'In corso',
        preSaleTabs2: 'Non avviato',
        preSaleTabs3: 'Finito',
        groupTitle: 'Gruppo',
        peopleGroupBuying: 'le persone hanno partecipato',
        flashSaleTitle: 'Flash',
        preSaleStateTimeS: 'inizio',
        preSaleStateTimeE: 'fine',
        showNow: "Vai a fare shopping adesso"
    },
    "appShopsListIndex": {
        navTitle: 'Elenco commercianti',
        searchPlace: 'Cerca...',
        favoriteTitle: 'Quantità preferita &',
        shopBtn: 'Visualizza armadio',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'Categoria',
        viewAll: 'visualizza tutto',
    },

    'appClassProductIndex': {
        navTitle: 'Prodotto',
        select1: 'Completo',
        select2: 'Quantità di vendita',
        select3: 'Nuovo prodotto',
        select4: 'Prezzo dal più alto al più basso',
        select5: 'Prezzo dal più basso al più alto',
        select6: 'Valutazione',
        paymentTitle: 'Pagamento',

    },
    'appPreSale': {
        navTitle: 'Prevendita',
        tabs1: 'In corso',
        tabs2: 'Non avviato',
        tabs3: 'Finito',
        selectAll: 'Tutti',
        listLabel: 'Spedizione',
        listLabe2: 'giorno',
    },
    "appGroupBuying": {
        navTitle: 'Gruppo',
        selectAll: 'Tutti',
        listLabel: 'Bisogno',
        listLabe2: 'persone',
    },
    "appFlashSale": {
        navTitle: 'Flash',
        selectAll: 'Tutti',
    },
    'appSalesRanking': {
        navTitle: 'Caldo',
        payment: 'pagamento',
        optionBtn1: 'Raccogli',
        optionBtn2: 'Acquista ora',
    },
    'appLogin': {
        navTitle: 'Accedi',
        navTitleHint: 'Accedi adesso e inizia a fare trading con amici da tutto il mondo! ',
        singUp: 'Registrati',

        emailLabel: "Numero di cellulare",
        emailPlaceholder: 'Inserisci il tuo numero di cellulare',
        emailErrorText: 'Messaggio di errore! ',
        emailNullVerify: 'Inserisci il tuo numero di cellulare!',//Inserisci il tuo indirizzo email
        emailErrorVerify: 'Inserisci il tuo numero di cellulare! ',//Errore nel formato dell'e-mail

        passwordLabel: 'la tua password',
        passPlaceholder: 'Inserisci la password',
        passNullVerify: 'Inserisci la tua password', //Inserisci la tua password
        passErrorVerify: 'La password è inferiore a 6 caratteri! ',//La password contiene meno di 6 caratteri

        placeholder: 'Inserisci',

        emailRegistration: 'Registrati ora',
        forgetPassTitle: 'Hai dimenticato la password? ',

        submitBtnTitle: 'Accedi',
        loginMessage: 'Account o password errati! '
    },
    'appForgotPass': {
        navTitle: 'Password dimenticata',
        navTitleHint: 'Inserisci il tuo numero di cellulare e ricevi il codice di verifica per impostare una nuova password. ',
        emailLabel: "Numero di cellulare",
        emailPlaceholder: 'Inserisci il tuo numero di cellulare',
        emailErrorText: 'Messaggio di errore!',// Inserisci il tuo indirizzo email
        emailNullVerify: 'Inserisci il tuo numero di cellulare!',//Inserisci il tuo indirizzo email
        emailErrorVerify: 'Inserisci il tuo numero di cellulare!',//Errore nel formato dell'email
        verificationCode: 'codice di verifica',
        verificationPlace: 'codice di verifica',
        verificationCodeError: 'Inserisci il codice di verifica! ',
        sendTitle: 'Invia codice di verifica',
        sendText: 'Riverifica',
        passwordLabel: 'Nuova password',
        passPlaceholder: 'Inserisci una nuova password',
        confirmPassLabel: 'Conferma la nuova password',
        confirmPlace: 'Conferma la nuova password',
        passNullVerify: 'Inserisci la tua nuova password', //Inserisci la tua password
        passErrorVerify: 'La password è inferiore a 6 caratteri! ',//La password contiene meno di 6 caratteri
        passCompareErrorVerify: 'Le password inserite due volte non sono coerenti! ',
        passCompareErrorNull: 'Inserisci nuovamente la nuova password',
        submitBtnTitle: 'Reimposta password',
        loginMessage: 'Il codice di verifica non è corretto! '
        // emailRegistration: 'Registrazione email',
        // dimenticaPassTitolo: 'Password dimenticata?',

    },
    'appRegister': {
        navTitle: 'Registrati',
        navTitleHint: 'Crea un account per accedere a tutte le funzionalità! ',
        namePlace: 'Inserisci',
        firstNameLabel: 'nome',
        firstNamePlace: 'Inserisci',
        firstNameErrorVerify: 'Inserisci il tuo nome',
        lastNameLabel: 'cognome',
        lastNameErrorVerify: 'Inserisci il tuo cognome',
        nickNameLabel: 'soprannome',
        nickNameErrorVerify: 'Inserisci il tuo nickname',
        emailLabel: "Numero di cellulare",
        emailPlaceholder: 'Inserisci il tuo numero di cellulare',
        emailErrorText: 'Messaggio di errore!',// Inserisci il tuo indirizzo email
        emailNullVerify: 'Inserisci il tuo numero di cellulare!',//Inserisci il tuo indirizzo email
        emailErrorVerify: 'Inserisci il tuo numero di cellulare! ',//Errore nel formato dell'e-mail
        verificationCode: 'codice di verifica',
        verificationPlace: 'Inserisci il codice di verifica',
        sendTitle: 'Invia codice di verifica',
        sendText: 'Conto alla rovescia',
        verificationCodeError: 'Inserisci il codice di verifica! ',
        passwordLabel: 'password',
        passPlaceholder: 'Inserisci la password',
        confirmPassLabel: 'Conferma password',
        confirmPlace: 'Conferma password',
        passNullVerify: 'Inserisci la tua password',//Inserisci la tua password
        passErrorVerify: 'La password è inferiore a 6 caratteri! ',//La password contiene meno di 6 caratteri
        passCompareErrorVerify: 'Le password inserite due volte non sono coerenti! ',
        passCompareErrorNull: 'Inserisci nuovamente la password',
        registerHint: 'Hai già un account? ',
        registerHintLogin: 'Accedi',
        submitBtnTitle: 'Registrati',
        loginMessage: 'Il codice di verifica non è corretto! '

        // gender: 'Gender',
        // gender1: 'Boy',
        // gender2: 'Girl',
        // gender3: 'Secrecy',
        // genderError: 'Please enter your gender',
    },
    'appGoodsDetail': {
        salesTitle: 'Vendite',
        payHintTitle1: 'Saldo',
        payHintTitle2: 'PayPal',
        payHintTitle3: 'Visto',
        conditionLabel: 'Condizione:',
        conditionContent: "Metodi di pagamento supportati dalla categoria del marchio",
        frightLabel: 'Logistica',
        frightContent: 'Consegna in media entro 1~3 giorni',
        agreement: "Accordo sulla protezione dell'acquirente",
        specSelect: 'Seleziona una specifica',
        specLabel: 'Specifica',
        specOptions: 'conferma',
        countLabel: 'Quantità',
        commentTitle: 'Commento',
        deliverTime: 'Valuta i tempi e i giorni di consegna',
        salesLabel: 'Quantità di vendita:',
        productCount: "Quantità prodotto:",
        collectLabel: 'Quantità della raccolta:',
        averageLabel: 'Numero medio di giorni:',
        since: "Giornata dei membri",
        shipper: "risponditore rapido",
        responder: "Mittente rapido",
        reliable: 'affidabile',
        btnContact: 'Contatto',
        enter: 'armadio',
        followBtn: 'Raccolta',
        guessLike: 'immagino che ti piaccia',
        buyNow: 'Acquista ora',
        dialogOverviewTitle: 'Panoramica',
        overConditionLabel: 'Condizione',
        overConditionContent: 'nuovo',
        overBrandLabel: 'Marca',
        overCategoryLabel: 'Categoria',
        overPayMethodsLabel1: "Metodi di pagamento supportati",
        overPayMethodsLabel2: 'Informazioni importanti',
        overCategoryEnter: 'Conferma',
        dialogFreightTitle: 'Accordo logistico',
        dialogAgreementTitle: 'Contratto sulla tutela dell\'acquirente',
        footerLeft: 'Aggiungi al carrello',
        footerRight: "Acquista ora",
        activeLabel1: 'Acquisto di gruppo',
        activeLabel2: 'Seconda vendita',
        activeLabel3: 'Prevendita',
        flashStateNameE: 'Fine',
        flashStateNameI: 'In corso',
        flashStateNameS: 'Non avviato',
        flashHintSName: 'Inizia',
        flashHintEName: 'Terminato',
        flashHintIName: 'Tempo rimanente',
        preSaleStateTimeNO: 'Non avviato',
        preSaleStateTimeE: 'Fine',
        preSaleStateTimeS: 'In corso',
        preSaleHintNoName: 'Inizia',
        preSaleHintIEName: 'Terminato',
        preSaleHintEName: 'Fine',
        groupHint: 'persona',
        reviewTitle: "Revisione predefinita del sistema"
    },
    "appShops": {
        select1: 'Completo',
        select2: 'Quantità di vendita',
        select3: 'Nuovo prodotto',
        select4: 'Prezzo dal più alto al più basso',
        select5: 'Prezzo dal più basso al più alto',
        select6: 'Valutazione',
        infoLabel1: 'Quantità di vendita',
        infoLabel2: 'Quantità prodotto',
        infoLabel3: 'Quantità della raccolta',
        infoLabel4: 'Valuta i tempi di consegna',
        memberLabel1: 'Giornata dei membri',
        memberLabel2: 'Risponditore rapido',
        memberLabel3: 'Speditore rapido',
        memberLabel4: 'affidabile',
        commodity: 'merce',
        classAll: 'tutti',

        // timeDay: 'Evaluate Delivery time & days',
        // productQuantity: 'Product Quantity',
        // shippedQuantity: 'Shipped Quantity',
        // collections: "Number of Collections",
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 商品评论页
    'appProductReviews': {
        navTitle: 'Commenti',
        reviewTitle: "Commenti predefiniti del sistema",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'Aggiornamento versione, confermare? ',
        loadingTitle: 'Scaricamento in corso...',
        systemUpgrade: 'Aggiornamento del sistema',
        upgradeImmediately: 'Conferma',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "Loading", // Loading
        finishedText: "None", //None
        loadingText: "Loading", // Loading
        nullText: 'No data yet',
        loadingFinished: "Loading completed",
        listNullText: 'No more',
        pullIngText: 'Pull down to refresh',
        looseText: 'Release to refresh',
        pullingSuccess: 'Pull-down loading successful',
        source: 'confirm'
    },

};
export default obj;
