
const pcRoutes = [
  {
    path: "/",
    name: "indexPage",
    redirect: "/indexPage",
  },
  {
    path: "/indexPage",
    name: "indexPage",
    component: () => import(/* webpackChunkName: "pcIndex" */ "@/views/pc/pc-index.vue"),
    meta: { title: '首页', requiresDesktop: true },
    redirect: '/home',
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import(/* webpackChunkName: "pcHome" */ "@/views/pc/pc-home/home.vue"),
        meta: { title: '首页', requiresDesktop: true }
      },
      {
        path: "/doc",
        name: "doc",
        component: () => import(/* webpackChunkName: "pcHome" */ "@/views/pc/pc-doc/pc-doc-index.vue"),
        meta: { title: '文本', requiresDesktop: true }
      },
      {
        path: "/shopList",
        name: "pcShopListIndex",
        component: () => import(/* webpackChunkName: "pcHome" */ "@/views/pc/pc-shop-list/shop-list-index.vue"),
        meta: { title: '店铺列表', requiresDesktop: true }
      },
      {
        path: "/shoppingCar",
        name: "shoppingCar",
        component: () => import(/* webpackChunkName: "pcShoppingCar" */ "@/views/pc/pc-shopCar/pc-shoppingCar-index.vue"),
        meta: { title: '购物车', requiresDesktop: true }
      },
      {
        path: "/application",
        name: "application",
        component: () => import(/* webpackChunkName: "pcShoppingCar" */ "@/views/pc/pc-store-application/pc-store-application-index.vue"),
        meta: { title: '店铺申请', requiresDesktop: true }
      },
      {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "pcLogin" */ "@/views/pc/pc-login/pc-login-index.vue"),
        meta: { title: '登陆', requiresDesktop: true }
      },
      {
        path: "/singUpIndex",
        name: "signUpIndex",
        component: () => import(/* webpackChunkName: "pcLogin" */ "@/views/pc/pc-signUp/pc-signUp-index.vue"),
        meta: { title: '注册', requiresDesktop: true }
      },
      {
        path: "/hot",
        name: "pcHotPageIndex",
        component: () => import(/* webpackChunkName: "pcHotIndex" */  "@/views/pc/pc-hot/pc-hot-index.vue"),
        meta: { title: '热门', requiresDesktop: true }
      },
      {
        path: "/used",
        name: "pcUsedPageIndex",
        component: () => import(/* webpackChunkName: "pcUsedIndex */  "@/views/pc/pc-used/pc-used-index.vue"),
        meta: { title: '二手', requiresDesktop: true }
      },
      {
        path: "/seckill",
        name: "pcSeckillPageIndex",
        component: () => import(/* webpackChunkName: "pcSeckill */  "@/views/pc/pc-seckill/pc-seckill-index.vue"),
        meta: { title: '秒杀', requiresDesktop: true }
      },
      {
        path: "/presale",
        name: "pcPresalePageIndex",
        component: () => import(/* webpackChunkName: "pcPresalePage */  "@/views/pc/pc-presale/pc-presale-index.vue"),
        meta: { title: '预售', requiresDesktop: true }
      },
      {
        path: "/group",
        name: "pcGroupPageIndex",
        component: () => import(/* webpackChunkName: "pcGroupPage */  "@/views/pc/pc-group/pc-group-index.vue"),
        meta: { title: '团体', requiresDesktop: true }
      },

      {
        path: "/classProduct",
        name: "pcClassificationProductIndex",
        component: () => import(/* webpackChunkName: "pcClassificationProduct */  "@/views/pc/pc-class-product/class-product-index.vue"),
        meta: { title: '分类产品页', requiresDesktop: true }
      },
      {
        path: "/productDetail",
        name: "pcProductDetailIndex",
        component: () => import(/* webpackChunkName: "pcProductDetail */  "@/views/pc/pc-product-detail/product-detail.vue"),
        meta: { title: '分类产品详情页', requiresDesktop: true }
      },
      {
        path: "/merchant",
        name: "pcMerchantIndex",
        component: () => import(/* webpackChunkName: "pcMerchant */  "@/views/pc/pc-merchant/pc-merchant-index.vue"),
        meta: { title: '商家店铺页', requiresDesktop: true }
      },
    ]
  },


];
export default pcRoutes