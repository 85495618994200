<template>
  <div class="set-drawer-warper">
    <van-popup
      class="drawer-popup-warper"
      v-model="drawerVisible"
      position="bottom"
      round
      @close="handlerDrawerClose"
    >
      <div class="popup-body">
        <div class="popup-list">
          <div
            class="list-item"
            v-for="(item, index) in drawerList"
            :key="index"
            @click="onClickItem(item, index)"
          >
            <p :class="['title', activeIndex == index && 'active']">
              {{ item.label }}
            </p>
          </div>
        </div>
        <div class="footer-option" @click="handlerDrawerClose">
          {{ language.optionsBtn }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import imageUrlApi from "@/utils/projectConfig.js";
import downloadAppApi from "@/api/h5Api/downloadApp";
export default {
  name: "homeDrawerIndex",
  props: {
    visibleIsShow: Boolean,
  },
  data() {
    return {
      imageUrlApi: imageUrlApi,
      activeIndex: null,
      drawerVisible: false,
      language: this.$language("setDrawer"),
      drawerList: [],
    };
  },

  mounted() {
    this.drawerVisible = this.visibleIsShow;
    this.onLoad();
  },
  methods: {
    onClickItem(record, index) {
      this.activeIndex = index;
      if (record.value == "register") {
        this.$router.push({ path: "/appMerchantSettlement" });
      }
      if (record.value == "service") {
        // this.$router.push({ path: '/appService' })
        this.$emit("drawerItem", "service");
      }
      if (record.value == "setLanguage") {
        this.$router.push({ path: "/appSetLanguage" });
      }
      if (record.value == "business") { 
        this.$globalCommon.$openParamUrl(this.$globalCommon.$merchantsHost);
      }
      if (record.value == "Android") {
        this.getChangeRouter(record.value);
      }

      if (record.value == "ios") {
        this.getChangeRouter(record.value);
      }
    },
    async getChangeRouter(key) {
      const { data, code } = await downloadAppApi.downloadApi();
      if (code == 200) {
        if (key == "Android") {
          console.log(data.android);
          window.location.href = data.android;
        }
        if (key == "ios") {
          window.location.href = data.ios;
        }
      }
    },
    onLoad() {
      this.drawerList = [
        { value: "setLanguage", label: this.language.label1 },
      ];

      if (this.$permissionObj.goToMerchants) {
        this.drawerList.push({value: "business", label: this.language.label3});
      }

      if (this.$permissionObj.merchantsSettledIn) {
        this.drawerList.push({ value: "register", label: this.language.label2 });
      }

      this.drawerList.push({ value: "service", label: this.language.label4 });

      if (this.$permissionObj.downloadApp) {
        this.drawerList.push(
          { value: "Android", label: this.language.label5 },
          { value: "ios", label: this.language.label6 }
        );
      }
    },
    handlerDrawerClose() {
      this.drawerVisible = false;
      this.$emit("handlerDrawerClose", this.drawerVisible);
    },
  },
};
</script>

<style lang="scss" scoped>
.set-drawer-warper {
  width: 100%;

  .drawer-popup-warper {
    width: 100%;
    padding: 40px;
    max-height: 54%;
    background-color: rgba(255, 255, 255, 0.7);

    .popup-body {
      height: auto;

      .popup-list {
        margin-bottom: 60px;

        .list-item {
          line-height: 2.5;
          text-align: center;
          font-size: 32px;
          color: #1d1e20;
          font-weight: 600;
          border-bottom: 1px dashed $size8f;

          .active {
            font-size: 33px;
            font-weight: 700;
            color: $blur;
          }
        }
      }

      .footer-option {
        margin: 0 auto;
        width: 80%;
        height: 82px;
        font-size: 32px;
        color: #fff;
        background: $blur;
        padding: 0px 42px;
        border-radius: 16px;
        text-align: center;
        line-height: 82px;
      }
    }
  }
}
</style>
