<template>
  <div class="app-classification-warper">
    <div class="app-classification-header flexbox">
      {{ language.navTitle }}
    </div>
    <div class="appClassification-body">
      <template v-if="classNavList.length">
        <div class="class-list-warper">
          <!-- <div class="class-list-header flexbox">
            <div v-for="(item, index) in classNavList" :key="index" :class="[
              'class-list-item',
              activeClassIndex === index && 'class-active-index',
            ]" @click="classTabsActive(item, index)">
              <div class="class-img imgFlex">
                <img v-lazy="getImgUrl(item, index)" />
                <div class="class-info">
                  <p class="class-info-name">{{ item.name }}</p>
                </div>
              </div>
            </div>
          </div> -->
          <div class="class-product-boxers">
            <div class="product-list" v-for="(item, index) in classProductData" :key="index">
              <div class="product-list-title flexbox">
                <div class="left flexbox">
                  <span class="upDown" @click="onClickUpDown(item, index)">
                    <van-icon name="arrow-down" v-if="item.childIsShow" />
                    <van-icon name="arrow-up" v-else />
                  </span>
                  <span class="noText name">{{ item.name }}</span>
                </div>
                <div class="right flexbox">
                  <span @click="onClickViewLink(item, 'all')">
                    {{ language.viewAll }}
                  </span>
                </div>

              </div>
              <div class="product-list-child flexbox" v-if="item.childIsShow">
                <div v-for="(child, childIndex) in item.child" :key="childIndex"
                  @click="onClickChildItem(item, child, childIndex)" :class="[
                    'child-list',
                    item.childIndex == childIndex && 'child-active',
                  ]">
                  <div class="child-img imgFlex">
                    <img v-lazy="getChildUrl(child)" />
                  </div>
                  <div class="child-text text">{{ child.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <default-page v-else></default-page>
    </div>
  </div>
</template>

<script>
import appClassificationApi from "@/api/h5Api/appClassificationApi.js";
import imgApi from "@/utils/projectConfig.js";
import defaultNull from "@/layout/defaultNull.vue";
export default {
  components: { defaultNull },
  name: "appClassificationIndex",
  data () {
    return {
      language: this.$language(),
      thumbnail: this.$thumbnail,
      imgUrl: imgApi.imgUrl,
      searchValue: "",
      activeClassIndex: 0,
      activeChildIndex: 0,
      classNavList: "", //头部分类
      classProductData: [],
      countNumber: 0,
    };
  },
  activated () {
    if (this.$route.path === "/appClassification" && !this.countNumber) {
      this.init();
    }
  },
  created () {
    if (this.$route.path === "/appClassification" && !this.countNumber) {
      this.init();
      this.countNumber = 1;
    }
  },
  methods: {
    init () {
      this.getClassification();
    },
    // 分类数据获取
    getClassification () {
      appClassificationApi.getClassIfy_List().then((res) => {
        if (res.code == 200) {
          this.classNavList = res.data;
          this.classProductData = res.data;
          this.classProductData.map((item, index) => {
            item.childIndex = 0;
            item.childIsShow = true;
          });
        }
      });
    },
    onClickViewLink (record) {
      this.$router.push({
        path: "/appClassProductIndex",
        query: { type: "classification", id: record.id },
      });
    },
    onClickUpDown (record, index) {
      record.childIsShow = !record.childIsShow;
      this.$forceUpdate();
    },
    onClickChildItem (record, child, index) {
      record.childIndex = index;
      this.$forceUpdate();
      setTimeout(() => {
        this.$router.push({
          path: "appClassProductIndex",
          query: {
            type: "classification",
            id: child.id,
          },
        });
      }, 1200);
    },
    classTabsActive (item, index) {
      this.activeClassIndex = index;
      this.classProductData = this.classNavList[this.activeClassIndex].child;
      this.classProductData.map((item, index) => {
        item.childIndex = 0;
        item.childIsShow = true;
      });
    },
    getChildUrl (record) {
      return this.imgUrl + record.icon;
    },
    getImgUrl (record, index) {
      if (this.activeClassIndex === index) {
        return this.imgUrl + record.iconTrue;
      } else {
        return this.imgUrl + record.iconFalse;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.app-classification-warper {
  width: 100%;
  height: 100%;
  background-color: #fafafa;

  .app-classification-header {
    justify-content: center;
    width: 100%;
    height: 110px;
    background-color: #fff;
    position: sticky;
    top: 0;
    font-size: 32px;
    font-weight: 600;
    color: $appSizeColor;
    z-index: 2;
  }

  .appClassification-body {
    width: 100%;
    height: calc(100% - 110px);
    background: #f4f4f4;
    overflow-y: scroll;
    padding: 20px;

    .body-search-boxers {
      padding: 30px;
      background: #fff;

      ::v-deep .van-cell {
        background: #f5f6fa;
        border-radius: 16px;

        .van-field__body {
          .van-field__control {
            color: #8f959e;
            font-size: 30px;
            font-weight: 600;
          }
        }

        .svg-icon {
          color: #1d1e21 !important;
          width: 42px !important;
          height: 42px !important;
        }
      }
    }

    .class-list-warper {
      .class-list-header {
        padding: 20px;
        overflow-x: scroll;
        background-color: #fff;
        margin-bottom: 15px;
        border-radius: 18px;

        .class-list-item {
          padding: 15px;
          background-color: #fff;
          margin-right: 30px;
          border-radius: 16px;

          .class-img {
            min-width: 180px;
            height: 200px;
            border-radius: 16px;
            overflow: hidden;
            position: relative;

            .class-info {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              padding: 12px 30px;

              .class-info-name {
                color: #1d1e21;
                font-size: 28px;
                font-weight: 600;
              }

              .class-info-title {
                color: #9fa4ac;
                font-size: 22px;
              }
            }
          }
        }

        .class-active-index {
          border-radius: 16px;
          background-color: #fff;

          .class-img {
            .class-info {
              .class-info-name {
                color: #fff !important;
              }

              .class-info-title {
                color: #fff !important;
              }
            }
          }
        }

        .class-list-item:hover {
          background-color: #fff;
          border-radius: 16px;
        }

        .class-list-item:last-child {
          margin-right: 0;
        }
      }

      .class-product-boxers {
        background-color: #fff;
        border-radius: 18px;

        .product-list {
          width: 100%;
          padding: 30px 0;

          .product-list-title {
            width: 100%;
            padding: 0px 36px;

            .left {
              width: 75%; 
              .upDown {
                margin-right: 8px;
                font-size: 36px;
                font-weight: 600;
                color: #1d1e20;
              }

              .name {
                display: inline-block;
                max-width: 95%;
                font-size: 38px;
                font-weight: 600;
                color: #1d1e20;
              }
            }

            .right {
              justify-content: flex-end;
              width: 35%;

              span {
                cursor: pointer;
                text-align: right;
                color: #8f959e;
                font-size: 26px;
                font-weight: 600;
              }

              .van-icon {
                cursor: pointer;
                font-size: 24px;
                color: #8f959e;
                margin-left: 12px;
              }
            }


          }

          .product-list-child {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 30px;

            .child-list {
              padding: 12px;
              width: calc(((100% - (24px * 3)) / 4));
              border-radius: 16px;
              overflow: hidden;
              margin-right: 24px;
              margin-bottom: 12px;

              .child-img {
                width: 100%;
                min-height: 158px;
                height: 158px;
              }

              .child-text {
                text-align: center;
                margin-top: 8px;
                font-size: 19px;
                color: #1d1e20;
              }
            }

            .child-list:nth-child(4n) {
              margin-right: 0;
            }

            .child-active {
              border: 1px solid #6d3580;
            }
          }
        }
      }
    }
  }

  .class-list-header::-webkit-scrollbar {
    display: block !important;
    width: 5px;
    height: 4px;
  }

  /* 滚动条滑块样式 */
  .class-list-header::-webkit-scrollbar-thumb {
    background-color: #888;
    /* 设置滑块背景颜色 */
    border-radius: 4px;
    /* 设置滑块圆角 */
  }

  /* 滚动条轨道hover状态样式 */
  .class-list-header::-webkit-scrollbar-track:hover {
    background-color: #f1f1f1;
    /* 设置轨道hover状态时的背景颜色 */
  }

  /* 滚动条滑块hover状态样式 */
  .class-list-header::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* 设置滑块hover状态时的背景颜色 */
  }
}
</style>
