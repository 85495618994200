<template>
  <!-- 发货信息模块 -->
  <div class="goodsDeliver-info-boxers">
    <div class="goodsDeliver-header flexbox">
      <div class="header-left imgFlex">
        <img v-if="deliverObj.imageIcon" :src="deliverObj.imageIcon" />
        <el-avatar
          v-else
          class="about-sellers-logo"
          :style="`background:${extractColorByName(deliverObj.name)}`"
        >
          {{ elAvatarSlice(deliverObj.name) }}
        </el-avatar>
      </div>
      <div class="header-right">
        <div class="goodsDeliver-name flexbox">
          <span>{{ deliverObj.name }}</span>
          <span class="btn-follow" @click="$router.push({ path: '/appLogin' })">
            {{ language.followBtn }}
          </span>
        </div>
        <p>
          <svg-icon icon-class="app-countDown"></svg-icon>
          <span>{{ deliverObj.createAt }}</span>
        </p>
        <!-- <p>
            <svg-icon icon-class="app-countDown"></svg-icon>
            <span>{{ dayTitle }}</span>
          </p> -->
      </div>
    </div>
    <div class="goodsDeliver-quantity-list">
      <div class="quantity-list-item">
        <span class="label">{{ language.salesLabel }}</span>
        <span>{{ deliverObj.productSale }}</span>
      </div>
      <div class="quantity-list-item soldQuantity-item">
        <span class="label">{{ language.productCount }}</span>
        <span>{{ deliverObj.productCount }}</span>
      </div>
      <div class="quantity-list-item">
        <span class="label">{{ language.collectLabel }}</span>
        <span>{{ deliverObj.collect }}</span>
      </div>
      <div class="quantity-list-item">
        <span class="label">{{ language.averageLabel }}</span>
        <span>{{ deliverObj.shipDay }}</span>
      </div>
    </div>
    <div class="goodsDeliver-introduce-list flexbox">
      <div class="deliver-introduce-item flexbox">
        <span class="svg-span">
          <svg-icon icon-class="app-calendar"></svg-icon>
        </span>
        <span class="title text3">{{ language.since }}</span>
      </div>
      <div class="deliver-introduce-item flexbox">
        <span class="svg-span">
          <svg-icon icon-class="app-trucks"></svg-icon>
        </span>
        <span class="title text3">{{ language.shipper }}</span>
      </div>
      <div class="deliver-introduce-item flexbox">
        <span class="svg-span">
          <svg-icon icon-class="app-serve"></svg-icon>
        </span>
        <span class="title text3">{{ language.responder }}</span>
      </div>
      <div class="deliver-introduce-item flexbox">
        <span class="svg-span">
          <svg-icon icon-class="app-defend"></svg-icon>
        </span>
        <span class="title text3">{{ language.reliable }}</span>
      </div>
    </div>
    <div class="goodsDeliver-options flexbox">
      <div class="option-btn left" @click="$router.push({ path: '/appLogin' })">
        {{ language.btnContact }}
      </div>
      <div
        class="option-btn right"
        @click="
          $router.push({ path: '/appShops', query: { id: deliverObj.id } })
        "
      >
        {{ language.enter }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "goodsDetailDeliverCard",
  props: {
    objList: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      language: this.$language(),
      dayTitle: "",
      deliverObj: {},
    };
  },
  mounted() {
    this.deliverObj = this.objList;
    this.dayTitle = this.language.deliverTime.replace(
      /&/,
      this.deliverObj.average_days
    );
  },
  methods: {
    // 用户名称截取作为头像
    elAvatarSlice(name) {
      if (name) {
        return name.slice(0, 1);
      }
    },
    /**
     * 根据名字提取颜色
     * @param name 名字
     */
    extractColorByName(name) {
      if (name) {
        var temp = [];
        temp.push("#");
        for (let index = 0; index < name.length; index++) {
          temp.push(parseInt(name[index].charCodeAt(0), 10).toString(16));
        }
        return temp.slice(0, 5).join("").slice(0, 4);
      }
    },
  },
  watch: {
    objList: {
      handler(newVal, oldVal) {
        this.dayTitle = this.language.deliverTime.replace(
          /&/,
          newVal.average_days
        );
        this.deliverObj = newVal;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.goodsDeliver-info-boxers {
  margin-top: 40px;
  padding: 30px 36px;
  border-radius: 18px;
  background-color: #f5f6fa;

  .goodsDeliver-header {
    width: 100%;

    .header-left {
      width: 145px;
      height: 145px;
      overflow: hidden;
      background: #fff;
      border-radius: 18px;

      .about-sellers-logo {
        width: 100%;
        height: 100%;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 36px;
      }
    }

    .header-right {
      width: calc(100% - 145px);
      height: auto;
      padding-left: 12px;

      .goodsDeliver-name {
        justify-content: space-between;
        color: #1d1e20;
        font-size: 32px;
        font-weight: 600;

        .btn-follow {
          color: #fff;
          font-size: 24px;
          font-weight: 600;
          padding: 12px;
          border-radius: 12px;
          background-color: #6d3580;
        }
      }

      p {
        margin-top: 22px;
        color: #959ba3;
        font-weight: 500;

        svg {
          width: 24px !important;
          height: 24px !important;
          margin-right: 6px;
        }

        span {
          font-size: 24px;
        }
      }
    }
  }

  .goodsDeliver-quantity-list {
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .quantity-list-item {
      width: 50%;
      line-height: 1.5;
      margin-bottom: 15px;

      .label {
        display: inline-block;
        color: #9ba1a9;
        font-size: 24px;
      }

      span:nth-child(2) {
        margin-left: 12px;
        color: #1d1e20;
        font-size: 25px;
      }
    }
  }

  .goodsDeliver-introduce-list {
    flex-wrap: wrap;

    .deliver-introduce-item {
      width: 50%;
      margin-bottom: 30px;

      .svg-span {
        border-radius: 50%;
        width: 62px;
        height: 62px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(109, 53, 128, 0.2);

        svg {
          width: 38px !important;
          height: 38px !important;
          color: #6d3580;
        }
      }

      .title {
        margin-left: 12px;
        color: #8f959e;
        font-size: 26px;
        max-width: calc(100% - 40px);
      }
    }
  }

  .goodsDeliver-options {
    margin-top: 20px;
    justify-content: space-between;

    .option-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #6d3580;
      font-size: 32px;
      border-radius: 18px;
      height: 90px;
      width: 47%;
      border: 4px solid #6d3580;
    }

    .right {
      color: #fff;
      background-color: #6d3580;
    }
  }
}
</style>
