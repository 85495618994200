import service from '@/required/appAxios'
export default {
  // 获取全部分类数据 
  getClassIfy_List (form) {
    return service({
      url: '/category.get_tree',
      method: "get",
    })
  },
  // 获取推荐数据
  get_HotTop15 (data) {
    return service({
      url: 'product.top15',
      method: "get",
    })
  },
}