// 繁体中文

import { Upload } from "element-ui";

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '한국인', // 韩语
    '繁體中文', // 繁体中文
    // 'English',  // 英语
    // // '日本語', // 日语
    // // 'Português', // 葡萄牙语
    // '繁體中文', // 繁体中文
    // // 'Français', // 法语
    // // 'Deutsch', // 德语
    // // 'Türk', // 土耳其语
    // // 'Español', // 西班牙语
    // // 'عربي', // 阿拉伯语
    // '한국인', // 韩语
    // 'Suomi', // 芬兰语
    // 'Русский', // 俄语
    // 'italiano', // 意大利语
    // 'แบบไทย', // 泰语
    // 'Nederlands', // 荷兰语
  ],
  'navigation': {
    home: "首頁",
    categories: "類別",
    hot: "熱賣",
    uset: "使用過",
    seckill: "秒殺",
    presale: "預售",
    group: "團購",
    login: '登入',
    singUp: '註冊',
  },
  'classifyDrawer': {
    navTitle: '類別'
  },
  'home': {
    showNow: '現在去購物',
    viewMore: '查看更多',
    recommendTitle: '推薦',
    merchantTitle: '商家',
    preSaleTitle: '預售',
    preSaleStarted: '啟動',
    preSaleNotStarted: '未啟動',
    preSaleEnded: '結束',
    secKillTitle: '秒殺',
    groupBuyingTitle: '團購',
    groupBuyingViewAll: '查看更多', 
    usedTitle: '使用過',

    sales: '銷售量',
    product: '產品',
    ranking: '排行',
    viewBtn: '立即查看',
  },
  "shopList": {
    searchPlaceholder: '搜尋更多...',
    salesLabel1: '產品數量',
    salesLabel2: '最喜歡的數量',
    salesLabel3: '銷售數量',

  },
  'downUploadLanguage': {
    downUpLoadTitle: '下載應用程式',
    downUpLoadText: '在 iphone、ipad 和 Android 上免費下載應用程式 ',
    phoneText: 'APP STORE',
    chromeText: 'GOOGLE PLAY',
  },
  'footerLanguage': {
    // 分类
    cateGoriesTitle: '類別',
    
    // 政策中心
    policyTitle: '政策中心',
    policyTerms: '服務條款',
    policyPrivacy: '隱私權政策',
    policyItems: '違禁物品',
    policyConduct: '禁止行為',
    policyElectronics: '電子通訊政策',
    policyMinors: '未成年人',
    policyDMCA: 'DMCA/版權通知',
    policySMS: '簡訊政策',
    policyRequests: '執法部門的請求',
    // 公司社区
    companyTitle: "公司",
    companyAboutUs: "關於我們",
    companyHiring: "我們正在招聘",
    companyCommunity: "我們的社群",
    // 支持
    supportTitle: '支援',
    supportGuidelines: '市場指南。',
    supportSafety: '安全指南',
    supportLogistics: '物流服務',
    supportSeller: '賣家保護',
    supportBuyer: '買家保護',
    supportAccessibility: '輔助功能',

    footerRightTitle: '在應用程式上買賣',
    contactUs: '聯絡我們',
    setLanguage: '設定語言',
    footerLogoCopyright: '©',
    ingTitle:',inc'
  },
  'login': {
    navTitle: '登入',
    navTitleHint: '沒有帳戶？',
    singUp: '註冊',
    mobileLabel: '您的手機',
    mobileNullVerify: '請輸入電子郵件',//請輸入邮箱
    passwordLabel: '你的密碼',
    passwordVerify: '輸入您的密碼',//請輸入密码
    placeholder: '請輸入',
    forgetPass: '忘記密碼',//忘记密码
    loginBtnTitle: '登入',
    forgetPassTitle: '忘記密碼',
    loginMessage: '手機號碼或密碼錯誤！',
  },
  'shoppingCar': {
    navTitle: '購物車',
    checkBoxTitle: '全選',
    removeTitle: '刪除',
    selectPriceTitle1: '已選產品',
    selectPriceTitle2: '件，相當於',
    optionsBtn1: '支付'
  },
  "application": {
    navTitle: '商家入駐',
    label1: '商家名稱',
    label1Hint: '商家名稱或品牌名稱，一旦設定不可修改',
    visible1: '請輸入店家名稱',

    label2: '電子郵件',
    label2Hint: '商家用於登入商家入口網站的密碼。 它應該是至少 8 個字元的安全密碼。',
    visible2: '請輸入電子郵件',
    label3: '驗證碼',
    label3Hint: '确认商家身份并防止恶意活动所需的代码。请提供正确的验证码信息',
    visible3: '請輸入驗證碼',

    label4: '商家密碼',
    label4Hint: '商家用於登入商家入口網站的密碼。 它應該是至少 8 個字元的安全密碼。',
    visible4: '請輸入商家密碼',
    label5: '邀請碼',
    label5Hint: '輸入邀請您的商家邀請碼',

    label6: '圖像',
    label6Hint: '商家的店鋪圖像',
    visible6: '請輸入驗證碼',
    label7: '身份證明',
    label7Hint: '身份證明正反面圖片',
    visible7: '請上傳營業執照圖片及相關產業資質證明（最多可上傳2張圖片，每張圖片最大尺寸2m，圖片格式支援Jpg、Png、Jpeg）',

    placeholderEnter: '請輸入',
    placeholderSelect: '請選擇',
    sendEmail: '傳送',
    optionBtn1: '服務條款',
    optionBtn2: '賣家保護',
    optionBtn3: '提交審核',
    uploadMessageError: '圖片上傳失敗',
    UploadLayoutError:'圖片格式錯誤'
  },
  'forgetPass': {
    navTitle: '忘記密碼',
    navTitleHint: '沒有帳戶？',
    loginIn: '登入',

    emailLabel: '手機號',
    mobileNullVerify: '請輸入手機號',//請輸入邮箱

    verificationLabel: '驗證碼',
    verificationVerify: '輸入驗證碼',//請輸入验证码
    confirmEmail: '確認郵件',

    password1Label: '你的密碼',
    password1Verify: '輸入您的密碼',//請輸入密码

    password2Label: '確認密碼',
    password2Verify: '請再次輸入你的密碼',//请再次输入密码
    passwordRepetitionVerify: '兩次輸入的密碼不一致',//请再次输入密码

    placeholder: '請輸入',

    submitBtnTitle: '提交',

    forgetPassTitle: '忘記密碼',
    codeMessage: '驗證碼不正確!'
  },
  'singUpIndex': {
    navTitle: '註冊',
    navTitleHint: '已經有帳戶？',
    loginIn: '登入',

    firstNameLabel: '名', // 昵称
    firstNameVerify: '請輸入名字', //請輸入姓名

    lastNameLabel: '姓', // 姓
    lastNameVerify: '請輸入姓氏',

    nicknameLabel: '暱稱',//名
    nicknameVerify: '請輸入暱稱',



    mobileLabel: '手機號',
    mobileNullVerify: '請輸入手機號',//請輸入手機號

    verificationLabel: '驗證碼',
    verificationVerify: '輸入驗證碼',//請輸入验证码
    confirmEmail: '確認郵件',

    password1Label: '你的密碼',
    password1Verify: '輸入您的密碼',//請輸入密码

    password2Label: '確認密碼',
    password2Verify: '請再次輸入你的密碼',//请再次输入密码
    passwordRepetitionVerify: '兩次輸入的密碼不一致',//请再次输入密码

    placeholder: '請輸入',

    submitBtnTitle: '登記',
    codeMessage: '驗證碼不正確!'
  },
  'hot': {
    hotTitle: '銷量前15名排名',
    product: '產品',
    sales: '銷售量',
    ranking: '排行',
    viewBtn: '立即查看',
    selectPlaceholder: '請選擇',
    viewPayment: '支付'
  },
  'used': {
    classTitle: "類別",
    brandCardTitle: '品牌',
    brandPlace: '搜尋品牌',

    searchPlaceholder: '搜尋更多...',

    recommendTitle: '推薦',
    crumbsTitle: '預設',
    select1: '綜合的',
    select2: '銷售數量',
    select3: '新產品',
    select4: '價格從高到低',
    select5: '價格從低到高',
    select6: '評分',

 
    SearchCriteria: '搜尋條件：',
    clearAll: '全部清除',
    loading: '載入中...',
    noMore: '沒有更多了'
  },
  'classProduct': {

    classTitle: "類別",
    brandCardTitle: '品牌',
    brandPlace: '搜尋品牌',

    searchPlaceholder: '搜尋更多...',

    recommendTitle: '推薦',
    crumbsTitle: '預設',
    select1: '綜合的',
    select2: '銷售數量',
    select3: '新產品',
    select4: '價格從高到低',
    select5: '價格從低到高',
    select6: '評分',



    SearchCriteria: '搜尋條件：',
    clearAll: '全部清除',
    loading: '載入中...',
    noMore: '沒有更多了'

  },

  'group': {
    defaultTitle: '預設',
    selectTitle: '請選擇',
    requires: '需要',
    people: '人',
    classAll: '全部',

  },
  'presale': {
    defaultTitle: '預設',
    selectTitle: '請選擇',
    requires: '出貨需要',
    people: '天',

    classAll: '全部',
    started: '開始',
    notStarted: '沒有開始',
    ended: '結束',

  },
  // 秒杀页面
  'seckill': {
    classAll: '全部',
    selectTitle:'請選擇'
  },
  'productDetail': {
    likeMessage: '您還沒有登錄，請先登錄',
    collectLabel: '讚',
    shareLabel: '分享',
    commentLabel: '評論',

    defaultLabel: '預設',
    numberLabel: '數位',
    numberTotal: '全部的：',
    buyNow: '立即購買',
    addChart: '購物車添加',

    brand: '品牌:',
    paymentMethod: '付款方式：',
    deliveryInformation: '所有訂單均加急（1-3 天）出貨',
    payLabel1: '餘額',
    payLabel2: 'PayPal',
    payLabel3: 'visa',

    policyLabel: '安心',
    policyTitle: '買家保護政策',
    policyInfo: '按照指示取得訂單或接收退款',
    policyMore: '了解更多',

    aboutSellersTitle: '關於賣家',
    contactMerchants: '聯絡商家',
    viewClosets: '查看壁櫥',
    sales: '銷售量',
    product: '產品數量',
    care: '收藏數量',
    average: '平均天數',

    memberSince: '會員日',
    fastResponder: '快速響應者',
    fastShipper: '快速出貨人',
    reliable: "可靠的",

    guessTitle: '猜你喜歡',

    activeLabel1: '團購',
    activeLabel2: '秒殺',
    activeLabel3: '預售',

    presaleHint: '付款成功後%內出貨',
    timeDays: '天',

    activeState1Time1: '結束',
    activeState1Time2: '前往',
    activeState1Time3: '開始',
    activeTimeHint1: '已經結束了',
    activeTimeHint2: '剩餘時間',
    activeTimeHint3: '開始時間是',

    activityPresellState: '結束',
    activityPresellHint: '人',
    activeStart: '開始',
    activeEnd: '結束',
  },
  "merchant": {
    follow: '跟隨',
    loginMessage: '您還沒有登錄，請先登入！',
    searchPlaceholder: '搜尋產品', 
    salesProductTitle: '實際銷售產品數量',
    shippedQuantity: "產品總數",
    collections: "收藏數量",
    evaluate: "評估交貨時間",


    category: '類別',
    brandCardTitle: '品牌',
    brandPlace: '請輸入',

    memberSince: '會員日',
    responder: '快速響應者',
    shipper: '快速出貨人',
    reliable: '可靠的',

    brandPlace: '搜尋品牌',
    productTitle: '商品',
    select1: '綜合',
    select2: '銷售數量',
    select3: '新產品',
    select4: '價格從高到低',
    select5: '價格從低到高',
    select6: '評分',
    clearAll: '全部清除',
    searchCriteria: '搜尋條件：'
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: '版本升級,請確認？ ',
    loadingTitle: '正在下載...',
    systemUpgrade: '系統升級',
    upgradeImmediately: '確認',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "加載中", // 正在加載
    finishedText: "無", //無
    loadingText: "加載中", // 正在加載
    nullText: '暫無數據',
  },

};
export default obj;
