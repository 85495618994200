<template>
  <div class="appDialog-warper">
    <van-popup
      class="popup-dialog-warper"
      v-model="dialogIsShow"
      position="bottom"
      round
      @close="
        {
          (dialogIsShow = !dialogIsShow), $emit('dialogClose');
        }
      "
    >
      <div class="popupDialog-body">
        <div class="popupDialog-header">
          <p>
            <span> {{ navTitle }}</span>
            <svg-icon
              icon-class="app-close"
              @click="
                {
                  (dialogIsShow = !dialogIsShow), $emit('dialogClose');
                }
              "
            ></svg-icon>
          </p>
        </div>
        <div class="appPopup-content" v-html="htmlData"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "appDetailDialogIndex",
  props: {
    isShow: Boolean,
    navTitle: String,
    htmlData: String,
  },
  data() {
    return {
      dialogIsShow: false,
    };
  },
  created() {
    this.dialogIsShow = this.isShow;
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.appDialog-warper {
  width: 100%;
  .popup-dialog-warper {
    width: 100%;
    height: 70%;
    .popupDialog-body {
      ::v-deep .popupDialog-header {
        font-size: 32px;
        font-weight: 600;
        color: #1d1e20;
        width: 100%;
        background-color: #fff;
        height: 140px;
        position: sticky;
        top: 0;
        p {
          height: 100%;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          position: relative;
          svg {
            color: #1d1e20;
            position: absolute;
            right: 36px;
            top: 50px;
            width: 43px !important;
            height: 43px !important;
          }
        }
      }
      .appPopup-content {
        padding: 30px;
      }
    }
  }
}
</style>
<style>
.van-icon {
  color: #222222;
}
</style>
