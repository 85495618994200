/**
 * @description 获取url参数
 * @param {String} key 参数键值
 * @returns {String}
 */
function getParams (key) {
  let url = window.location.href + ""
  let regstr = "/(\\?|\\&)" + key + "=([^\\&]+)/"
  let reg = eval(regstr)
  //eval可以将 regstr字符串转换为 正则表达式
  let result = url.match(reg)

  if (result && result[2]) {
    return result[2].split("#")[0]
  }
}
export { getParams }