<template>
  <div class="app-footer-card-warper">
    <div class="footer-list">
      <div class="list-item" @click="routerView('home')">
        <span v-if="routerPath == '/appHome'">{{ language.home }}</span>
        <svg-icon v-else icon-class="app-footer-home" class="app-home"></svg-icon>
      </div>
      <div class="list-item" @click="routerView('car')">
        <svg-icon icon-class="app-shopping-car" class="shopping-car"></svg-icon>
      </div>
      <div class="list-item" @click="routerView('class')">
        <span class="classSvg">
          <svg-icon icon-class="app-footer-class"></svg-icon>
        </span>
      </div>
      <div class="list-item" @click="routerView('info')">
        <svg-icon icon-class="app-info" class="infoSvg"></svg-icon>
      </div>
      <div class="list-item" @click="routerView('user')">
        <svg-icon icon-class="app-footer-user" class="userSvg"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "appFooterCard",
  data () {
    return {
      language: this.$language("footer"),
      routerPath: "",
    };
  },
  mounted () {
    this.routerPath = this.$route.path;
  },
  methods: {
    routerView (key) {
      // let countNum = localStorage.getItem("countNum")
      // countNum += 1
      // this.$resetSetItem("_countNum", "_countNum", countNum);
      if (key == "home") {
        this.$router.push({ path: "/appHome" });
      }
      if (key == "class") {
        this.$router.push({ path: "/appClassification" });
      }
      if (key == "car") {
        this.$router.push({ path: "/appLogin" });
      }
      if (key == "info") {
        this.$router.push({ path: "/appLogin" });
      }
      if (key == "user") {
        this.$router.push({ path: "/appLogin" });
      }
    },
  },
  watch: {
    $route: {
      handler (newVal, oldVal) {
        this.routerPath = newVal.path;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-footer-card-warper {
  position: fixed;
  bottom: 0px;
  border-top: 1px solid #f0f0f0;
  width: 100%;
  height: 160px;
  background-color: #ffffff;
  max-width: 768px;
  margin: auto !important;
  position: fixed;
  bottom: 0;
  z-index: 999;

  .footer-list {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .list-item {
      .classSvg {
        padding: 30px;
        border-radius: 50%;
        background-color: #7b488c;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          color: #f1ebf3 !important;
        }
      }

      span {
        color: #7b488c;
        font-size: 28px;
        font-weight: 600;
      }

      svg {
        color: #8f959e !important;
        width: 58px !important;
        height: 48px !important;
      }
    }
  }
}
</style>
