<template>
  <div class="appHome-flashSale-warper">
    <div class="appHome-flashSale-header flexbox">
      <div class="header-left flexbox">
        <svg-icon icon-class="app-alarm-clock" class="appHomeSvg"></svg-icon>
        <span class="title-left">{{ language.flashSaleTitle }}</span>
      </div>
      <div class="header-right">
        <span class="title-right" @click="$router.push({ path: '/appFlashSale' })">{{ language.viewAll }}</span>
      </div>
    </div>
    <div class="flashSale-tabs-boxers flexbox">
      <div class="flashSale-tabs flexbox" id="secKillTabsID" @scroll="handleSecKillScroll">
        <div v-for="(item, index) in fishSaleTabs" :key="index" :id="`${index}_tabsItemId`" :class="[
          'flashSale-tabs-list',
          'flexbox',
          activeTabs == index && 'tabs-active',
        ]" @click="onChangeTabs(item, index)">
          <div class="tabs-list-item flexbox">
            <svg-icon icon-class="app-countDown"> </svg-icon>
            <span class="noText">
              {{ item.startTimeString }} - {{ item.endTimeString }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="appHome-flashSale-body">
      <div class="flashSale-list-boxers flexbox">
        <van-swipe class="list-item-swipe" indicator-color="#6d3580" @change="onChangeSwipe"
          v-if="flashSaleListData.length">
          <van-swipe-item v-for="(item, lenIndex) in countLen" :key="lenIndex">
            <div class="flashSale-list-item" v-for="(item, index) in setList(lenIndex)" :key="index" @click="
              $router.push({
                path: '/appGoodsDetail',
                query: { id: item.id, sourcePage: 'seckill' },
              })
              ">
              <div class="list-item-img-boxers imgFlex">
                <img v-lazy="getUrl(item.image)" />
                <!-- <div class="item-count-zero flexbox noText">
                  {{ item.highlights }}
                </div> -->
              </div>
              <div class="list-item-name text2">
                {{ item.name }}
              </div>
              <div class="list-item-price flexbox">
                <span> {{ moneyIcon }}{{ item.unitAmount }}</span>
                <span class="flexbox">{{ item.priceReductionRatio }}%</span>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
        <defaultPage v-else></defaultPage>
      </div>
    </div>
  </div>
</template>

<script>
import appHomeApi from "@/api/h5Api/appHomeApi";
import imgApi from "@/utils/projectConfig.js";
import moment from "moment-timezone";
import { timeZone } from "@/utils/timeZone";
export default {
  name: "appHomeFlashSaleCard",
  data () {
    return {
      imgApi: imgApi.imgUrl,
      language: this.$language(),
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      searchObj: {
        pageSize: 8,
        pageCount: 1,
        total: 0,
      },
      timeSetInter: "",
      timeZone: timeZone,
      fishSaleTabs: [],
      activeTabs: 0,
      flashSaleListData: [],
      countLen: 1,
      current: 0,
      countNumber: 0,
    };
  },
  beforeDestroy () {
    window.removeEventListener("scroll", this.handleSecKillScroll);
  },
  activated () {
    if (this.$route.path === "/appHome") {
      window.addEventListener("scroll", this.handleSecKillScroll);
      this.onLoad();
    }
  },
  mounted () {
    if (this.$route.path === "/appHome" && !this.countNumber) {
      window.addEventListener("scroll", this.handleSecKillScroll);
      this.onLoad();
      this.countNumber = 1;
    }
  },
  methods: {
    onChangeSwipe (e) {
      this.current = e;
    },
    onLoad () {
      this.getTime();
    },
    timeSetIn (time) {
      this.timeSetInter = setInterval(() => {
        time += 1000;
        let nowStartTime = this.fishSaleTabs[this.activeTabs].startTime;
        let nowEndTime = this.fishSaleTabs[this.activeTabs].endTime;
        if (nowStartTime < time && time < nowEndTime) {
          this.activeTabs = this.activeTabs + 1;
          this.searchObj.page = 1;
          this.get_seckillList();
          this.scrollCallBack();
        }
      }, 1000);
    },
    // 获取时间线筛选数据
    async getTime () {
      console.log(this.fishSaleTabs, '1212')
      if (this.fishSaleTabs.length) {
        let time = moment().tz(this.timeZone);
        clearInterval(this.timeSetInter);
        const currentTime = moment()
          .tz(this.timeZone)
          .format("YYYY-MM-DD HH:mm:ss", time)
          .valueOf();
        this.timeSetIn(currentTime);
        this.$nextTick(() => {
          this.scrollCallBack();
        });
        return false;
      }
      const { code, data } = await appHomeApi.get_flashTime();
      if (code === 200 && data.list.length) {
        data.list.map((item, index) => {
          item.startTime = item.startTime * 1000;
          item.endTime = item.endTime * 1000;
          if (item.startTime < data.time * 1000 && data.time < item.endTime) {
            this.activeTabs = index;
          }
        });
        this.fishSaleTabs = data.list;
        let time = moment().tz(this.timeZone);
        clearInterval(this.timeSetInter);
        const currentTime = moment()
          .tz(this.timeZone)
          .format("YYYY-MM-DD HH:mm:ss", time)
          .valueOf();
        this.timeSetIn(currentTime);
        this.$nextTick(() => {
          this.scrollCallBack();
        });
        this.get_seckillList();
      }
    },
    // 获取seckill列表
    async get_seckillList () {
      this.groupListData = [];
      let params = {
        timeId: this.fishSaleTabs[this.activeTabs].id,
        categoryId: this.searchObj.selectValue,
        page: this.searchObj.pageCount,
        size: this.searchObj.pageSize,
      };
      const { code, data } = await appHomeApi.get_product_list(params);
      if (code == 200) {
        data.list.map((item) => {
          item.imageUrl = this.imgApi + item.image;
          item.originalAmount = (item.originalAmount / 100).toFixed(2);
          item.unitAmount = (item.unitAmount / 100).toFixed(2);
        });
        this.searchObj.total = data.total;
        this.flashSaleListData = data.list;
        this.current = 0;
        this.countLen = 1;
        setTimeout(() => {
          this.countLen = Math.ceil(this.flashSaleListData.length / 4);
        });
      }
    },
    handleSecKillScroll (e) {
      if (document.getElementById("secKillTabsID")) {
        let scrollTabs = document.getElementById("secKillTabsID").scrollLeft;
        if (scrollTabs) {
          this.activeTabsClass = "secKill-tabs-scroll";
        } else {
          this.activeTabsClass = "";
        }
      }
    },
    scrollCallBack () {
      console.log(this.activeTabs, "this.activeTabs");
      let indexId = `${this.activeTabs}_tabsItemId`;
      if (document.getElementById(`${indexId}`)) {
        let activeTabsDom = document.getElementById(`${indexId}`).offsetLeft;
        let activeTabsWidth = document.getElementById(`${indexId}`).clientWidth;
        let scrollLeft = activeTabsDom - activeTabsWidth;
        console.log(scrollLeft);
        document.getElementById("secKillTabsID").scrollLeft = scrollLeft;
      }
    },
    onChangeTabs (item, index) {
      this.activeTabs = index;
      this.$nextTick(() => {
        this.scrollCallBack();
      });
      this.searchObj.page = 1;
      this.get_seckillList();
    },
    getUrl (url) {
      return this.imgApi + url;
    },
    setList (index) {
      if (index === 0) {
        return this.flashSaleListData.slice(0, 4);
      } else {
        let count = index * 4;
        return this.flashSaleListData.slice(count, count + 4);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.appHome-flashSale-warper {
  margin-top: 15px;

  .appHome-flashSale-header {
    justify-content: space-between;
    padding: 0px 36px;
    height: 110px;
    z-index: 2;
    background-color: #fff;
  }

  .flashSale-tabs-boxers {
    padding: 12px 30px;
    background-color: #fff;

    .flashSale-tabs {
      width: 100%;
      overflow-y: scroll;
      transition: all 0.3s ease;

      .flashSale-tabs-list {
        padding: 20px 30px;
        background-color: #f5f6fa;
        margin-right: 12px;
        border-radius: 12px;

        .tabs-list-item {
          justify-content: space-between;
          color: #8f959e;
          font-size: 28px;

          svg {
            width: 30px;
            height: 30px;
            margin-right: 12px;
          }
        }
      }

      .tabs-active {
        background-color: $appBlur;

        .tabs-list-item {
          color: $appWhite;
        }
      }
    }
  }

  .appHome-flashSale-body {
    padding: 15px;
    background-color: #fff;

    .flashSale-list-boxers {
      .list-item-swipe {
        ::v-deep .van-swipe__track {
          background-color: #fff;
          width: 100% !important;
        }

        ::v-deep .van-swipe__indicators {
          bottom: 15px;

          .van-swipe__indicator {
            width: 40px;
            height: 6px;
            border-radius: 68px;
            background: #bcbcbc;
          }

          .swipe__indicator--active {
            color: $blur;
          }
        }

        .van-swipe-item {
          width: 100% !important;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          background-color: #fff;
          padding: 20px;
          border-radius: 18px;

          .flashSale-list-item {
            width: 48%;
            border-radius: 18px;
            margin-bottom: 26px;

            .list-item-img-boxers {
              position: relative;
              width: 100%;
              height: 454px;
              border-radius: 18px;
              overflow: hidden;

              img {
                height: 100%;
              }

              .item-count-zero {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 60px;
                background-color: rgba(94, 48, 108, 0.9);
                color: $appWhite;
                font-size: 22px;
                line-height: 60px;
                padding: 0 20px;
              }
            }

            .list-item-name {
              margin-top: 14px;
              font-size: 24px;
              color: $appSizeColor;
            }

            .list-item-price {
              margin-top: 22px;
              font-size: 32px;
              font-weight: 600;
              color: $blur;

              span:nth-child(2) {
                font-size: 24px;
                margin-left: 12px;
                padding: 2px 8px;
                border-radius: 6px;
                border: 1px solid $blur;
              }
            }
          }
        }
      }
    }
  }
}
</style>
