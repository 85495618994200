// 土耳其语

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ], 'navigation': {
    home: "HOME",
    categories: "CATEGORIES",
    hot: "HOT",
    uset: "USET",
    seckill: "SECKILL",
    presale: "PRESALE",
    group: "GROUP",
    login: 'login in',
    singUp: 'sign up',
  },
  'classifyDrawer': {
    navTitle: 'Category'
  },
  'home': {
    // 首页页面分类
    womenTitle: '女装',
    menTitle: '男装',
    kids: '儿童',
    viewMore: '查看更多',
    bags: '包',
    clothing: '裙子',
    shoes: '鞋子',
    trendingStyles: '潮流样式',
    accessorie: '附件',
    toys: '玩具',
    // 首页推荐
    recommendTitle: '推荐',
    recommendHome: '主页',
    recommendWomen: '女装',
    recommendMen: '男装',
    recommendKids: '童装',
    recommendElectronics: '电子产品',
    recommendPets: '宠物',
    // 商户
    merchantTitle: '商户',
    viewAll: '查看更多',
    // 预售
    preSaleTitle: '预售',
    preSaleStarted: '启动',
    preSaleNotStarted: '未启动',
    preSaleEnded: '结束',
    // 秒杀
    secKillTitle: '秒杀',
    // 团购
    groupBuyingTitle: '团购',
    groupBuyingViewAll: '查看更多',
    // used使用
    usedTitle: 'used',
    usedHome: '主页',
    usedWomen: '女装',
    usedMen: '男装',
    usedKids: '童装',
    usedElectronics: '电子产品',
    usedPets: '宠物',
    // 排名
    /* 女士 */
    rankingWomenTitle: 'Women',
    rankingWomenProduct: 'Product',
    rankingWomenSales: 'Sales',
    rankingWomenRang: 'Ranking',
    rankingWomenBtn: 'View Now',
    /* 男士 */
    rankingMenTitle: 'Men',
    rankingMenProduct: 'Product',
    rankingMenSales: 'Sales',
    rankingMenRank: 'Ranking',
    rankingMenBtn: 'View Now',
    /* Kids 儿童 */
    rankingKidsTitle: 'Men',
    rankingKidsProduct: 'Product',
    rankingKidsSales: 'Sales',
    rankingKidsRank: 'Ranking',
    rankingKidsBtn: 'View Now',
    /* home 主页 */
    rankingHomeTitle: 'Home',
    rankingHomeProduct: 'Product',
    rankingHomeSales: 'Sales',
    rankingHomeRank: 'Ranking',
    rankingHomeBtn: 'View Now',
    /* Electronics 电子产品 */
    rankingElectronicsTitle: 'Electronics',
    rankingElectronicsProduct: 'Product',
    rankingElectronicsSales: 'Sales',
    rankingElectronicsRank: 'Ranking',
    rankingElectronicsBtn: 'View Now',
    /* Pets 宠物 */
    rankingPetsTitle: 'Pets',
    rankingPetsProduct: 'Product',
    rankingPetsSales: 'Sales',
    rankingPetsRank: 'Ranking',
    rankingPetsBtn: 'View Now',
    selectPlaceholder: '请选择'

  },
  'downUploadLanguage': {
    downUpLoadTitle: 'Download App',
    downUpLoadText: ' Download the app for free on iphone, ipad and Android ',
    phoneText: 'APP STORE',
    chromeText: 'GOOGLE PLAY',
  },
  'footerLanguage': {
    // 分类
    cateGoriesTitle: 'CATEGORIES',
    cateGoriesHome: 'Home',
    cateGoriesWomen: 'Women',
    cateGoriesMen: 'Men',
    cateGoriesKids: 'Kids',
    cateGoriesElectronics: 'Electronics',
    cateGoriesPets: 'Pets',
    // 政策中心
    policyTitle: 'POLICY CENTER',
    policyTerms: 'Terms of Service',
    policyPrivacy: 'Privacy Policy',
    policyItems: 'Prohibited Items',
    policyConduct: 'Prohibited Conduct',
    policyElectronics: 'Electronic Communication Policy',
    policyMinors: 'Minors',
    policyDMCA: 'DMCA/Copyright Notification',
    policySMS: 'SMS Policy',
    policyRequests: 'Requests from law enforcement',
    // 公司社区
    companyTitle: "COMPANY",
    companyAboutUs: "About Us",
    companyHiring: "We're Hiring",
    companyCommunity: "Our Community",
    // 支持
    supportTitle: 'SUPPORT',
    supportGuidelines: 'Marketplace Guidelines.',
    supportSafety: 'Safety Guidelines',
    supportLogistics: 'Logistics Services',
    supportSeller: 'Seller Protection',
    supportBuyer: 'Buyer Protection',
    supportAccessibility: 'Accessibility',

    footerRightTitle: 'BUY AND SELL ON APPS',
    contactUs: 'CONTACT US',
    setLanguage: 'Setting Language',
    footerLogoCopyright: '© 2023 Gaudente, Inc'
  },
  'login': {
    navTitle: 'Login in',
    navTitleHint: 'Don`t have an ccount?',
    singUp: 'Sign up',

    emailLabel: 'Your email',
    emailNullVerify: 'Please enter email',//请输入邮箱
    emailErrorVerify: 'Mailbox format error',//邮箱格式错误

    passwordLabel: 'Your password',
    passwordVerify: 'enter your password',//请输入密码
    placeholder: 'Please Input',
    forgetPass: 'Forget your password',//忘记密码
    loginBtnTitle: 'Login in',

    forgetPassTitle: 'forgot Password'
  },
  'forgetPass': {
    navTitle: 'forgot Password',
    navTitleHint: 'Don`t have an ccount?',
    loginIn: 'Log in',

    emailLabel: 'Email',
    emailNullVerify: 'Please enter email',//请输入邮箱
    emailErrorVerify: 'Mailbox format error',//邮箱格式错误

    verificationLabel: 'Verification Code',
    verificationVerify: 'Enter verification code',//请输入验证码
    confirmEmail: '确认邮件',

    password1Label: 'Your password',
    password1Verify: 'enter your password',//请输入密码

    password2Label: 'Confirm Password',
    password2Verify: 'Please enter your password again',//请再次输入密码
    passwordRepetitionVerify: 'The two password entries are inconsistent',//请再次输入密码

    placeholder: 'Please Input',

    submitBtnTitle: 'Submit',

    forgetPassTitle: 'forgot Password'
  },
  'signUp': {
    navTitle: 'Sign up',
    navTitleHint: 'Already have an account?',
    loginIn: 'Login',

    firstNameLabel: 'First Name', // 昵称
    firstNameVerify: 'Please enter first_name', //请输入姓名

    lastNameLabel: 'Last Name', // 姓
    lastNameVerify: 'Please enter last_name',

    nicknameLabel: 'Nickname',//名
    nicknameVerify: 'Please enter nickname',

    sexLabel: 'Gender',
    sexBoy: 'Boy',
    sexGirl: 'Girl',
    sexSecrecy: 'Secrecy',

    emailLabel: 'Email',
    emailNullVerify: 'Please enter email',//请输入邮箱
    emailErrorVerify: 'Mailbox format error',//邮箱格式错误

    verificationLabel: 'Verification Code',
    verificationVerify: 'Enter verification code',//请输入验证码
    confirmEmail: '确认邮件',

    password1Label: 'Your password',
    password1Verify: 'enter your password',//请输入密码

    password2Label: 'Confirm Password',
    password2Verify: 'Please enter your password again',//请再次输入密码
    passwordRepetitionVerify: 'The two password entries are inconsistent',//请再次输入密码

    placeholder: 'Please Input',

    submitBtnTitle: 'Register',
  },
  'hot': {
    hotTitle: 'Top 15 Sales Rankings',
    // 排名
    /* 女士 */
    rankingWomenTitle: 'Women',
    rankingWomenProduct: 'Product',
    rankingWomenSales: 'Sales',
    rankingWomenRang: 'Ranking',
    rankingWomenBtn: 'View Now',
    /* 男士 */
    rankingMenTitle: 'Men',
    rankingMenProduct: 'Product',
    rankingMenSales: 'Sales',
    rankingMenRank: 'Ranking',
    rankingMenBtn: 'View Now',
    /* Kids 儿童 */
    rankingKidsTitle: 'Men',
    rankingKidsProduct: 'Product',
    rankingKidsSales: 'Sales',
    rankingKidsRank: 'Ranking',
    rankingKidsBtn: 'View Now',
    /* home 主页 */
    rankingHomeTitle: 'Home',
    rankingHomeProduct: 'Product',
    rankingHomeSales: 'Sales',
    rankingHomeRank: 'Ranking',
    rankingHomeBtn: 'View Now',
    /* Electronics 电子产品 */
    rankingElectronicsTitle: 'Electronics',
    rankingElectronicsProduct: 'Product',
    rankingElectronicsSales: 'Sales',
    rankingElectronicsRank: 'Ranking',
    rankingElectronicsBtn: 'View Now',
    /* Pets 宠物 */
    rankingPetsTitle: 'Pets',
    rankingPetsProduct: 'Product',
    rankingPetsSales: 'Sales',
    rankingPetsRank: 'Ranking',
    rankingPetsBtn: 'View Now',
    selectPlaceholder: '请选择'
  },
  'group': {

  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Sürüm yükseltmesi, lütfen onaylayın? ',
    loadingTitle: 'İndiriliyor...',
    systemUpgrade: 'Sistem Yükseltme',
    upgradeImmediately: 'Onayla',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Yükleniyor", // Yükleniyor
    finishedText: "Yok", //Yok
    loadingText: "Yükleniyor", // yükleniyor
    nullText: "Henüz veri yok"
  },
};
export default obj;