import service from '@/required/appAxios'
export default {
  // 获取全部分类数据 
  getClassIfy_List (form) {
    return service({
      url: '/category.get_tree',
      method: "get",
    })
  },
  getHomeList (form) {
    return service({
      url: 'home.home',
      method: "post",
    })
  },
  // 获取推荐数据
  get_recommend (data) {
    return service({
      url: 'home.get_recommend',
      method: "post",
      params: data
    })
  },
  // 获取推荐数据
  get_HotTop15 (data) {
    return service({
      url: 'product.top15',
      method: "get",
    })
  },
  // 获取预售数据
  get_preSaleList (data) {
    return service({
      url: 'presale.get_product_list',
      method: "get",
      params: data
    })
  },
  // 获取group数据
  get_groupList (data) {
    return service({
      url: 'group.get_product_list',
      method: "get",
      params: data
    })
  },
  // 获取flashTime
  get_flashTime (data) {
    return service({
      url: 'flash.get_time',
      method: "get",
      params: data
    })
  },
  // 秒杀活动 - 获取秒杀产品列表
  get_product_list (data) {
    return service({
      url: 'flash.get_product_list',
      method: "get",
      params: data
    })
  },
}