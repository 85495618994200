<template>
  <div class="appHome-shopCard-warper">
    <div class="appHome-shop-header flexbox">
      <div class="header-left flexbox">
        <svg-icon icon-class="app-shop" class="appHomeSvg"></svg-icon>
        <span class="title-left">{{ language.shopTitle }}</span>
      </div>
      <div class="header-right" @click="$router.push({ path: '/appShopsListIndex' })">
        <span class="title-right">{{ language.viewAll }}</span>
      </div>
    </div>
    <div class="appHome-shop-body">
      <div class="shop-card-list flexbox" v-if="shopListData.length">
        <div class="shopCard-list-item" v-for="(item, index) in shopListData" :key="index"
          @click="$router.push({ path: '/appShops', query: { id: item.id } })">
          <div class="list-item-img imgFlex">
            <img v-lazy="item.image" />
          </div>
          <div class="listItem-info-boxers">
            <div class="list-item-name text">{{ item.name }}</div>
            <div class="list-item-leaver flexbox">
              <svg-icon icon-class="app-heard" v-for="(svgItem, svgIndex) in 5" :key="svgIndex" :class="[
                'svgIcon',
                svgIndex < Number(item.star) && 'red-svgIcon',
              ]"></svg-icon>
            </div>
            <div class="list-item-option flexbox">
              <span class="option-btn flexbox noText">
                {{ language.shopOption }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <defaultPage v-else></defaultPage>
    </div>
  </div>
</template>

<script>
import imgApi from "@/utils/projectConfig.js";
export default {
  name: "appHomeShopCard",
  props: {
    shopList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data () {
    return {
      language: this.$language(),
      imgApi: imgApi.imgUrl,
      thumbnail: this.$thumbnail,
      shopListData: [],
    };
  },

  mounted () {
    this.onLoad();
  },
  methods: {
    onLoad () {
      this.shopListData = this.shopList;
    },
    getImgUrl (url) {
      return this.imgApi + url + this.thumbnail;
    },
  },
  watch: {
    shopList: {
      handler (newVal, oldVal) {
        this.shopListData = [...newVal];
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.appHome-shopCard-warper {
  margin-top: 15px;

  .appHome-shop-header {
    height: 110px;
    padding: 0px 36px;
    z-index: 2;
    justify-content: space-between;
    background-color: #fff;
  }

  .appHome-shop-body {
    width: 100%;
    overflow-x: scroll;
    background-color: #fff;

    .shop-card-list {
      padding: 15px 30px;
      width: 100%;
      justify-content: space-between;

      .shopCard-list-item {
        width: 230px;
        min-width: 230px;
        margin-right: 23px;

        .list-item-img {
          width: 100%;

          img {
            width: 100%;
            height: 210px;
          }
        }

        .listItem-info-boxers {
          background-color: #f5f6fa;
          border-radius: 0 0 28px 28px;
          padding: 23px 30px;
          text-align: center;

          .list-item-name {
            color: $appSizeColor;
            font-size: 28px;
          }

          .list-item-leaver {
            margin-top: 12px;
            justify-content: space-around;

            .svgIcon {
              width: 30px !important;
              height: 30px !important;
              color: #d4d4d4;
            }

            .red-svgIcon {
              color: #ff2f2f;
            }
          }

          .list-item-option {
            margin-top: 16px;
            justify-content: center;

            .option-btn {
              padding: 16px 22px;
              border-radius: $appOptionRadius;
              background-color: $appBlur;
              color: $appWhite;
            }
          }
        }
      }
    }
  }
}
</style>
