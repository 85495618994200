<template>
  <div class="appCell1-card">
    <div class="appCell-img">
      <img v-lazy="item.media.cover[0]" />
    </div>
    <div class="appCell-name text">
      {{ item.store_name }}
    </div>
    <div class="appCell-payment text2">1000+ {{ language.paymentTitle }}</div>
    <div class="appCell-price text">{{ moneyIcon }}{{ item.price }}</div>
    <div class="appCell-tags">
      <span>{{ language.listTag1 }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "appCellCard1",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      language: this.$language(),
    };
  },
  components: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.appCell1-card {
  font-family: "GrifaSlabRegular";
  .appCell-img {
    width: 100%;
    height: 420px;
    border-radius: 18px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .appCell-name {
    margin-top: 12px;
    font-size: 28px;
    color: #38393b;
  }
  .appCell-payment {
    margin-top: 12px;
    font-size: 20px;
    color: #8f959e;
  }
  .appCell-price {
    margin-top: 16px;
    color: #6d3580;
    font-size: 28px;
  }
  .appCell-tags {
    margin-top: 24px;
    span {
      font-size: 20px;
      padding: 6px 9px;
      color: #6d3580;
      border: 1px solid #6d3580;
    }
  }
}
</style>
