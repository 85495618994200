// 土耳其语

let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "Ev",
    },
    "searchLanguage": {
        searchBtn: 'Aramak',
        searchPlace: 'Aramak....'
    },
    'setDrawer': {
        label1: 'Çoklu dil ayarları',
        label2: 'Satıcı yerleşti',
        label3: 'Satıcı girişi',
        label4: 'Müşteri Hizmetleri',
        label5: 'Android uygulamasını indir',
        label6: 'ios uygulamasını indir',
        optionsBtn: 'İptal'
    },
    "appService": {
        navTitle: 'Müşteri Hizmetleri',
        iconInformation: 'Lütfen danışmak için iletişim yazılımını seçin',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
    },
    "appMerchantSettlement": {
        navTitle: 'Satıcı yerleşti',
        label1: 'Satıcı adı',
        label1Hint: 'Satıcı adı veya marka adı ayarlandıktan sonra değiştirilemez',
        visible1: 'Lütfen satıcı adını girin',

        label2: 'Posta Kutusu',
        label2Hint: 'İşyeri tarafından üye işyeri portalına giriş yapmak için kullanılan şifre. ',
        visible2: 'Lütfen e-posta adresinizi girin',
        label3: 'E-posta doğrulama kodu',
        label3Hint: 'Satıcı kimliğini doğrulamak ve kötü niyetli faaliyetleri önlemek için kod gerekli. Lütfen doğru doğrulama kodu bilgilerini girin',
        visible3: 'Lütfen e-posta doğrulama kodunu girin',

        label4: 'Satıcı şifresi',
        label4Hint: 'İşyeri tarafından üye işyeri portalına giriş yapmak için kullanılan şifre. ',
        visible4: 'Lütfen satıcı şifresini girin',
        label5: 'davetiye kodu',
        visible5: 'Lütfen davet kodunu girin',
        label5Hint: 'Sizi davet eden satıcının davet kodunu girin. ',

        label6: 'Resmi depola',
        label6Hint: 'Satıcının mağaza resmi',
        visible6: 'Lütfen doğrulama kodunu girin',
        label7: 'Satıcı Sertifikası',
        label7Hint: 'İşyerinin tacir lisansı, vergi sicil belgesi vb. sertifikasyon belgeleri. Bu dosyalar yüksek kaliteli resim dosyaları olmalıdır. ',
        visible7: 'Lütfen ticaret lisansınızın ve ilgili sektör yeterlilik sertifikalarınızın resimlerini yükleyin (en fazla 2 resim yükleyebilirsiniz, her resim maksimum 5 MB boyutundadır ve resim formatı Jpg ve Png\'yi desteklemektedir)',

        placeholderEnter: 'Lütfen girin',
        placeholderSelect: 'Lütfen seçin',
        sendEmail: 'Gönder',
        optionBtn1: 'Hizmet Şartları',
        optionBtn2: 'Satıcı Koruması',
        optionBtn3: 'İnceleme için gönder',
        uploadMessageError: 'Resim yükleme başarısız oldu',
        UploadLayoutError: 'Resim formatı hatası',
        fileFail: 'Lütfen resimleri yükleyin ve biçimlendirin',
        popupTitle1: 'Hizmet Şartları',
        popupTitle2: 'Satıcı Koruma Sözleşmesi'
    },
    'appSetLanguage': {
        navTitle: 'Çoklu dil ayarları',
        affirm: 'onayla',
    },
    'classifyDrawer': {
        navTitle: 'Kategori'
    },
    'appHome': {
        recommendTitle: 'Tavsiye Ediyorum',
        viewAll: 'tümünü görüntüle',
        usedTitle: 'Kullanılmış',
        buyNow: 'Şimdi Satın Al',
        shopTitle: 'Satıcı',
        shopOption: 'Dolap',
        hotTitle: 'Sıcak',
        ranking: 'Satış Sıralaması',
        preSaleTitle: 'Ön Satış',
        preSaleTabs1: 'Devam ediyor',
        preSaleTabs2: 'Başlatılmadı',
        preSaleTabs3: 'Bitti',
        groupTitle: 'Grup',
        peopleGroupBuying: 'insanlar katıldı',
        flashSaleTitle: 'Flash',
        preSaleStateTimeS: 'başlat',
        preSaleStateTimeE: 'bitiş',
        showNow: 'Şimdi alışverişe gidin'
    },
    "appShopsListIndex": {
        navTitle: 'Satıcı Listesi',
        searchPlace: 'Ara...',
        favoriteTitle: 'Favori Adet &',
        shopBtn: 'Dolabını Görüntüle',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'Kategori',
        viewAll: 'tümünü görüntüle',
    },

    'appClassProductIndex': {
        navTitle: 'Ürün',
        select1: 'Kapsamlı',
        select2: 'Satış miktarı',
        select3: 'Yeni ürün',
        select4: 'Fiyat yüksekten düşüğe',
        select5: 'Fiyat düşükten yükseğe',
        select6: 'Derecelendirme',
        paymentTitle: 'Ödeme',

    },
    'appPreSale': {
        navTitle: 'Satış Öncesi',
        tabs1: 'Devam ediyor',
        tabs2: 'Başlatılmadı',
        tabs3: 'Bitti',
        selectAll: 'Tümü',
        listLabel: 'Gönderim',
        listLabe2: 'gün',
    },
    "appGroupBuying": {
        navTitle: 'Grup',
        selectAll: 'Tümü',
        listLabel: 'İhtiyaç',
        listLabe2: 'insanlar',
    },
    "appFlashSale": {
        navTitle: 'Flash',
        selectAll: 'Tümü',
    },
    'appSalesRanking': {
        navTitle: 'Sıcak',
        payment: 'ödeme',
        optionBtn1: 'Topla',
        optionBtn2: 'Hemen Satın Al',
    },
    'appLogin': {
        navTitle: 'Giriş Yap',
        navTitleHint: 'Şimdi giriş yapın ve dünyanın her yerinden arkadaşlarınızla ticarete başlayın! ',
        singUp: 'Kayıt Ol',

        emailLabel: 'Cep telefonu numarası',
        emailPlaceholder: 'Lütfen cep telefonu numaranızı girin',
        emailErrorText: 'Hata mesajı! ',
        emailNullVerify: 'Lütfen cep telefonu numaranızı girin!',//Lütfen e-posta adresinizi girin
        emailErrorVerify: 'Lütfen cep telefonu numaranızı girin! ',//E-posta biçimi hatası

        passwordLabel: 'şifreniz',
        passPlaceholder: 'Lütfen şifreyi girin',
        passNullVerify: 'Lütfen şifrenizi giriniz', //Lütfen şifrenizi girin
        passErrorVerify: 'Şifre 6 karakterden az! ',//Şifre 6 karakterden az

        placeholder: 'Lütfen girin',

        emailRegistration: 'Şimdi kaydolun',
        forgetPassTitle: 'Şifrenizi mi unuttunuz? ',

        submitBtnTitle: 'Giriş Yap',
        loginMessage: 'Hesap veya şifre hatalı! '
    },
    'appForgotPass': {
        navTitle: 'Şifremi unuttum',
        navTitleHint: 'Lütfen cep telefonu numaranızı girin ve yeni bir şifre belirlemek için doğrulama kodunu alın. ',
        emailLabel: 'Cep telefonu numarası',
        emailPlaceholder: 'Lütfen cep telefonu numaranızı girin',
        emailErrorText: 'Hata mesajı!',//Lütfen e-posta adresinizi girin
        emailNullVerify: 'Lütfen cep telefonu numaranızı girin!',//Lütfen e-posta adresinizi girin
        emailErrorVerify: 'Lütfen cep telefonu numaranızı girin!',//E-posta biçimi hatası
        verificationCode: 'doğrulama kodu',
        verificationPlace: 'doğrulama kodu',
        verificationCodeError: 'Lütfen doğrulama kodunu girin! ',
        sendTitle: 'Doğrulama kodunu gönder',
        sendText: 'Yeniden doğrula',
        passwordLabel: 'Yeni şifre',
        passPlaceholder: 'Lütfen yeni bir şifre girin',
        confirmPassLabel: 'Yeni şifreyi onayla',
        confirmPlace: 'Yeni şifreyi onayla',
        passNullVerify: 'Yeni şifrenizi girin', //Lütfen şifrenizi girin
        passErrorVerify: 'Şifre 6 karakterden az! ',//Şifre 6 karakterden az
        passCompareErrorVerify: 'İki kez girilen şifreler tutarsız! ',
        passCompareErrorNull: 'Lütfen yeni şifreyi tekrar girin',
        submitBtnTitle: 'Şifreyi sıfırla',
        loginMessage: 'Doğrulama kodu hatalı! '
        // emailRegistration: 'E-posta kaydı',
        // forgetPassTitle: 'Şifrenizi mi unuttunuz?',

    },
    'appRegister': {
        navTitle: 'Kayıt Ol',
        navTitleHint: 'Tüm özelliklere erişmek için bir hesap oluşturun! ',
        namePlace: 'Lütfen girin',
        firstNameLabel: 'isim',
        firstNamePlace: 'Lütfen giriniz',
        firstNameErrorVerify: 'Lütfen adınızı girin',
        lastNameLabel: 'soyadı',
        lastNameErrorVerify: 'Lütfen soyadınızı girin',
        nickNameLabel: 'takma ad',
        nickNameErrorVerify: 'Lütfen takma adınızı girin',
        emailLabel: 'Cep telefonu numarası',
        emailPlaceholder: 'Lütfen cep telefonu numaranızı girin',
        emailErrorText: 'Hata mesajı!',//Lütfen e-posta adresinizi girin
        emailNullVerify: 'Lütfen cep telefonu numaranızı girin!',//Lütfen e-posta adresinizi girin
        emailErrorVerify: 'Lütfen cep telefonu numaranızı girin! ',//E-posta biçimi hatası
        verificationCode: 'doğrulama kodu',
        verificationPlace: 'Lütfen doğrulama kodunu girin',
        sendTitle: 'Doğrulama kodunu gönder',
        sendText: 'Geri sayım',
        verificationCodeError: 'Lütfen doğrulama kodunu girin! ',
        passwordLabel: 'şifre',
        passPlaceholder: 'Lütfen şifreyi girin',
        confirmPassLabel: 'Şifreyi onayla',
        confirmPlace: 'Şifreyi onayla',
        passNullVerify: 'Şifrenizi girin',//Lütfen şifrenizi girin
        passErrorVerify: 'Şifre 6 karakterden az! ',//Şifre 6 karakterden az
        passCompareErrorVerify: 'İki kez girilen şifreler tutarsız! ',
        passCompareErrorNull: 'Lütfen şifreyi tekrar girin',
        registerHint: 'Zaten bir hesabınız var mı? ',
        registerHintLogin: 'Giriş',
        submitBtnTitle: 'Kayıt Ol',
        loginMessage: 'Doğrulama kodu hatalı! '

        // gender: 'Gender',
        // gender1: 'Boy',
        // gender2: 'Girl',
        // gender3: 'Secrecy',
        // genderError: 'Please enter your gender',
    },
    'appGoodsDetail': {
        salesTitle: 'Satış',
        payHintTitle1: 'Bakiye',
        payHintTitle2: 'PayPal',
        payHintTitle3: 'Vize',
        conditionLabel: 'Durum:',
        conditionContent: 'Marka Kategorisi Destekli Ödeme Yöntemleri',
        frightLabel: 'Lojistik',
        frightContent: 'Ortalama 1~3 gün içinde teslimat',
        agreement: 'Alıcı Koruma Sözleşmesi',
        specSelect: 'Lütfen bir spesifikasyon seçin',
        specLabel: 'Şartname',
        specOptions: 'onayla',
        countLabel: 'Miktar',
        commentTitle: 'Yorum',
        deliverTime: 'Teslimat süresini ve günlerini değerlendirin',
        salesLabel: 'Satış miktarı:',
        productCount: "Ürün miktarı:",
        collectLabel: 'Koleksiyon miktarı:',
        averageLabel: 'Ortalama gün sayısı:',
        since: 'Üyelik Günü',
        shipper: 'hızlı yanıt veren',
        responder: 'Hızlı Gönderici',
        reliable: 'güvenilir',
        btnContact: 'İletişim',
        enter: 'dolap',
        followBtn: 'Koleksiyon',
        guessLike: 'Sanırım beğendin',
        buyNow: 'Hemen satın alın',
        dialogOverviewTitle: 'Genel Bakış',
        overConditionLabel: 'Koşul',
        overConditionContent: 'yeni',
        overBrandLabel: 'Marka',
        overCategoryLabel: 'Kategori',
        overPayMethodsLabel1: 'Desteklenen ödeme yöntemleri',
        overPayMethodsLabel2: 'Önemli bilgi',
        overCategoryEnter: 'Onayla',
        dialogFreightTitle: 'Lojistik Anlaşması',
        dialogAgreementTitle: 'Alıcı Koruma Sözleşmesi',
        footerLeft: 'Sepete ekle',
        footerRight: 'Hemen satın alın',
        activeLabel1: 'Grup satın alma',
        activeLabel2: 'İkinci Satış',
        activeLabel3: 'Satış Öncesi',
        flashStateNameE: 'Son',
        flashStateNameI: 'Devam ediyor',
        flashStateNameS: 'Başlatılmadı',
        flashHintSName: 'Başlat',
        flashHintEName: 'Sonlandı',
        flashHintIName: 'Kalan süre',
        preSaleStateTimeNO: 'Başlatılmadı',
        preSaleStateTimeE: 'Son',
        preSaleStateTimeS: 'Devam ediyor',
        preSaleHintNoName: 'Başlat',
        preSaleHintIEName: 'Sonlandı',
        preSaleHintEName: 'Son',
        groupHint: 'kişi',
        reviewTitle: 'Sistem varsayılan incelemesi'
    },
    "appShops": {
        select1: 'Kapsamlı',
        select2: 'Satış miktarı',
        select3: 'Yeni ürün',
        select4: 'Fiyat yüksekten düşüğe',
        select5: 'Fiyat düşükten yükseğe',
        select6: 'Derecelendirme',
        infoLabel1: 'Satış miktarı',
        infoLabel2: 'Ürün miktarı',
        infoLabel3: 'Toplama miktarı',
        infoLabel4: 'Teslimat süresini değerlendirin',
        memberLabel1: 'Üye Günü',
        memberLabel2: 'Hızlı Yanıtlayıcı',
        memberLabel3: 'Hızlı Gönderici',
        memberLabel4: 'güvenilir',
        commodity: 'emtia',
        classAll: 'tümü',

        // timeDay: 'Evaluate Delivery time & days',
        // productQuantity: 'Product Quantity',
        // shippedQuantity: 'Shipped Quantity',
        // collections: "Number of Collections",
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 商品评论页
    'appProductReviews': {
        navTitle: 'Yorumlar',
        reviewTitle: 'Sistem varsayılan yorumları',
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'Sürüm yükseltme, lütfen onaylayın mı? ',
        loadingTitle: 'İndiriliyor...',
        systemUpgrade: 'Sistem yükseltmesi',
        upgradeImmediately: 'Onayla',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "Yükleniyor", // Yükleniyor
        finishedText: "Yok", //Yok
        loadingText: "Yükleniyor", // Yükleniyor
        nullText: 'Henüz veri yok',
        loadingFinished: "Yükleme tamamlandı",
        listNullText: 'Artık yok',
        pullIngText: 'Yenilemek için aşağı çekin',
        looseText: 'Yenilemek için bırakın',
        pullingSuccess: 'Aşağı açılan yükleme başarılı',
        source: 'onayla'
    },

};
export default obj;
