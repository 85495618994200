var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"goods-comment-card"},[_c('div',{staticClass:"comment-header flexbox",on:{"click":function($event){return _vm.$router.push({
      path: '/appProductReviews',
      query: { id: _vm.$route.query.id },
    })}}},[_c('span',{staticClass:"comment-title"},[_vm._v(_vm._s(_vm.language.commentTitle))]),_c('svg-icon',{attrs:{"icon-class":"app-arrow"}})],1),_c('div',{staticClass:"comment-body"},[(_vm.commentObjData.length)?_vm._l((_vm.commentObjData),function(item,index){return _c('div',{key:index,staticClass:"body-list"},[_c('div',{staticClass:"commentBody-header flexbox"},[_c('div',{staticClass:"avatar-img imgFlex"},[(!item.avatarIcon)?_c('el-avatar',{staticClass:"about-sellers-logo",style:(`background:${_vm.extractColorByName(item.nickname)}`)},[_vm._v(" "+_vm._s(_vm.elAvatarSlice(item.nickname))+" ")]):_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.avatarIcon),expression:"item.avatarIcon"}]})],1),_c('div',{staticClass:"header-title flexbox"},[_c('div',{staticClass:"left"},[_c('p',{staticClass:"comment-name"},[_vm._v(_vm._s(item.nickname))])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"info-nav-stareLeave flexbox"},_vm._l((5),function(svgItem,svgIndex){return _c('div',{key:svgIndex,class:[
                  'stare-leave',
                  item.productScore >= svgIndex + 1 && 'star-active',
                ]},[_c('svg-icon',{attrs:{"width":"20px","height":"20px","icon-class":"pc-gray-star"}})],1)}),0)])])]),_c('div',{staticClass:"commentBody-info"},[_c('p',{staticClass:"text3"},[_vm._v(_vm._s(item.comment))])])])}):_c('default-page')],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }