import pcRouter from './routerIndex/pcRouters'
import h5Routers from './routerIndex/h5Routers'
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const result = /Android |webos| iPhone |iPod| BlackBerry | iPad/i.test(navigator.userAgent)
let routes = []
if (result) {
  routes = []
  routes = h5Routers
} else {
  routes = []
  routes = pcRouter
}  
console.log(routes)
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  const isMobile = /Android |webos| iPhone |iPod| BlackBerry | iPad/i.test(navigator.userAgent)
  console.log(isMobile)
  if (to.matched.some(record => record.meta.requiresMobile) && !isMobile) {
    next('/indexPage') // 如果路由需要移动设备而当前是桌面设备，则跳转到桌面路由
  } else if (to.matched.some(record => record.meta.requiresDesktop) && isMobile) {
    next('/appIndex') // 如果路由需要桌面设备而当前是移动设备，则跳转到移动路由
  } else {
    next() // 否则继续正常导航
  }
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router