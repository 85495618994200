<template>
  <div class="default-page-warper">
    <div class="default-images">
      <img src="../assets/h5/defaultNull.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "appDefaultNullPage",
  data() {
    return {
      language: this.$language("messageLanguage"),
    };
  },
};
</script>

<style lang="scss" scoped>
.default-page-warper {
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .default-images {
    width: 300px;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  p {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    margin-top: 40px;
    color: #aaa;
  }
}
</style>
