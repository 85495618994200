<template>
  <div class="app-login-warper">
    <appHeader></appHeader>
    <div class="app-login-body">
      <div class="login-header-body">
        <div class="login-title">{{ language.navTitle }}</div>
        <div class="login-hint">
          {{ language.navTitleHint }}
        </div>
      </div>
      <div class="field-boxers">
        <div class="field-item">
          <p class="field-item-label">{{ language.emailLabel }}</p>
          <van-field
            :class="['form-input', errorObj.emailErrorText && 'error-input']"
            v-model="formObj.email"
            :placeholder="language.emailPlaceholder"
            @input="onChangeInput('email', formObj)"
          >
            <template #left-icon>
              <svg-icon icon-class="app-mobile"></svg-icon>
            </template>
          </van-field>
          <p class="error-active error-text">{{ errorObj.emailErrorText }}</p>
        </div>
        <div class="field-item">
          <p class="field-item-label">{{ language.passwordLabel }}</p>
          <van-field
            class="form-input"
            v-model="formObj.pass"
            type="password"
            :placeholder="language.passPlaceholder"
          >
            <template #left-icon>
              <svg-icon icon-class="app-pass"></svg-icon>
            </template>
          </van-field>
          <div class="registration-forget">
            <p @click="$router.push('/appRegister')">
              <span>{{ language.emailRegistration }}</span>
              <span class="line"></span>
            </p>
            <p @click="$router.push('/appForgotPass')">
              <span> {{ language.forgetPassTitle }}</span>
              <span class="line"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="field-options" @click="onClickLogin">
        <div class="button btn-login">
          {{ language.submitBtnTitle }}
        </div>
      </div>
    </div>
    <appNotify
      v-if="notifyIsShow"
      :notifyIsShow="notifyIsShow"
      :notifyTitle="notifyTitle"
      @notifyIsShow="
        {
          notifyIsShow = false;
          notifyTitle = '';
        }
      "
    ></appNotify>
  </div>
</template>

<script>
import appHeader from "../component/app-header.vue";
export default {
  name: "appLoginIndex",
  data() {
    return {
      language: this.$language(),
      formObj: {
        email: "",
        pass: "",
      },
      notifyIsShow: false,
      notifyTitle: "",
      emailRegex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      errorObj: {
        emailErrorText: "",
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    onChangeInput(key, form) {
      if (key === "email") {
        // || !this.emailRegex.test(this.formObj[key])
        this.errorObj.emailErrorText = "";
        if (!this.formObj[key]) {
          this.errorObj.emailErrorText = this.language.emailErrorText;
          return false;
        }
      }
    },
    onClickLogin() {
      this.inputVerifyCallBack();
    },
    inputVerifyCallBack() {
      this.notifyIsShow = false;
      this.notifyTitle = "";
      const { email, pass } = this.formObj;
      if (!email) {
        this.notifyIsShow = true;
        this.notifyTitle = this.language.emailNullVerify;
        return false;
      }
      // if (!this.emailRegex.test(email)) {
      //   this.notifyIsShow = true;
      //   this.notifyTitle = this.language.emailErrorVerify;
      //   return false;
      // }
      if (!pass) {
        this.notifyIsShow = true;
        this.notifyTitle = this.language.passNullVerify;
        return false;
      }
      if (pass.length < 6) {
        this.notifyIsShow = true;
        this.notifyTitle = this.language.passErrorVerify;
        return false;
      }
      this.notifyIsShow = true;
      this.notifyTitle = this.language.loginMessage 
    },
  },
  components: { appHeader },
};
</script>

<style lang="scss" scoped>
.app-login-warper {
  width: 100%;
  height: 100%;
  font-family: "GrifaSlabRegular";
  .app-login-body {
    padding: 10px 36px;
    .login-header-body {
      .login-title {
        font-size: 52px;
        font-weight: 600;
        color: #6d3580;
      }
      .login-hint {
        width: 80%;
        margin-top: 22px;
        font-size: 32px;
        color: #1d1e20;
      }
    }
    .field-boxers {
      .field-item {
        padding-top: 62px;
        .field-item-label {
          font-size: 24px;
          color: #1d1e20;
          margin-bottom: 30px;
        }
        ::v-deep .form-input {
          height: 100px;
          border: 4px solid #6d3580;
          border-radius: 16px;
          padding: 0;
          padding-left: 35px;

          .van-field__left-icon {
            display: flex;
            align-items: center;
            svg {
              // padding-left: 35px;
              width: 40px !important;
              height: 40px !important;
              color: #6d3580;
            }
          }
          .van-field__body {
            height: 100%;
            input {
              caret-color: #6d3580 !important;
            }
            .van-field__control {
              height: 100%;
              padding: 0 20px;
              font-size: 32px;
              color: #6d3580;
            }
          }
        }
        .registration-forget {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;
          font-size: 22px;
          color: #6d3580;
          p {
            display: inline-block;
            position: relative;
            .line {
              position: absolute;
              bottom: -4px;
              display: flex;
              width: 100%;
              height: 2px;
              background-color: #6d3580;
            }
          }
        }
      }
    }
    .field-options {
      margin-top: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #6d3580;
      color: #fff;
      font-size: 28px;
      height: 96px;
      border-radius: 16px;
    }
  }
  .error-input {
    color: #ff2f2f !important;
    border-color: #ff2f2f !important;
    ::v-deep .van-field__left-icon {
      svg {
        color: #ff2f2f !important;
      }
    }
    ::v-deep .van-field__control {
      color: #ff2f2f !important;
    }
  }
  .error-text {
    color: #ff2f2f !important;
    padding-top: 8px;
    font-size: 24px;
    text-align: right;
  }
}
</style>
