import Vue from 'vue'
import Vuex from 'vuex'
import classIfyApi from '@/api/classIfy'
import imgApi from "@/utils/projectConfig";
//需要使用插件一次
Vue.use(Vuex)
import createPersistedState from 'vuex-persistedstate';
/* pc端 */
const pcState = {
  web_searchValue: '',//分类商品页搜索框数据
  web_selectClassifyItem: '',// 删除当前项
  web_selectClassifyData: [],// 分类搜索数据集
  web_drawerSelect: {},//下拉框选中项处理
  web_brandItem: {},//选中品牌数据  
  web_classIfy: {},//页面分类数据

  web_productDetail: {},//跳转详情页存储数据 
}
const appState = {
  app_ClassData: [],//分类选择数据
  app_ClassActive: null,//分类数据选择项
}
const appMutations = {
  // 分类数据选择
  app_ClassSelectFn (state, data) {
    state.app_ClassData = data
  },
  // 分类数据选择项
  app_ClassActiveFn (state, data) {
    state.app_ClassActive = data
  }
}
const pcMutations = {
  web_routeDetail (state, data) {
    state.web_productDetail = data
  },
  pcSearchInput (state, data) {
    state.web_searchValue = data
  },
  web_selectClassifyFn (state, data) {
    state.web_selectClassifyData = data
  },
  web_selectClassItemFn (state, data) {
    state.web_selectClassifyItem = data
  },
  // 下拉分类数据处理
  web_drawerFn (state, data) {
    state.web_drawerSelect = data
  },
  // 品牌数据选中
  web_brandItemFn (state, data) {
    state.web_brandItem = data
  },
 
}

const state = {
  ...pcState,
  ...appState
}
//mutations：存放了可以修改state中数据的方法，且仅支持同步操作，修改state的唯一手段
const mutations = {
  ...pcMutations,
  ...appMutations

}
//actions:同mutations类似，可以书写自己的业务逻辑，也可以处理异步，不直接更变数据状态，而是提交到mutations，由mutations进行变更
const actions = {
}
//getters:理解为计算属性，用于简化仓库数据，让组件获取仓库的数据更加方便
const getters = {}


//对外暴露Store类的一个实例
export default new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  state,
  mutations,
  actions,
  getters
})