<template>
  <div class="null-page-images">
    <img :src="nullImages" alt="" />
  </div>
</template>

<script>
export default {
  name: "pcPageNullError",
  data() {
    return {
      nullImages: require("../../../assets/pc/dev/empty.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
.null-page-images {
  width: 100%;
  padding: 37.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  .img {
    width: 200px;
    height: 200px;
  }
}
</style>
