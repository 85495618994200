<template>
  <div class="appLanguage-card flexbox">
    <div class="language-flag imgFlex2" @click="setLanguage">
      <img :src="languageObj.imgIcon" />
    </div>
    <!-- <div class="arrow-img" @click="setLanguage">
      <img src="../assets/arrow_down.png" />
    </div> -->
  </div>
</template>
<script>
export default {
  name: "appLanguageCard",
  data () {
    return {
      language: this.$language(),
      languageType: this.$languageType(),
      languageObj: {},
    };
  },
  mounted () {
    this.onLoad();
  },
  methods: {
    onLoad () {
      console.log(this.languageType);
      let language = localStorage.getItem("languageType");
      this.languageObj = {
        imgIcon: require(`../assets/h5/flag/${language}.png`),
        value: language,
      };
    },
    setLanguage () {
      this.$router.push({ path: "/appSetLanguage" });
    },
  },
};
</script>

<style lang="scss" scoped>
.appLanguage-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .language-flag {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }

}
</style>
