van-swipe-item<template>
  <div class="appHome-classCard-boxers">
    <template v-if="classCardList.length">
      <div class="class-card-list" v-for="(item, index) in classCardList" :key="index" @click="clickClassRow(item)">
        <div class="list-item-images imgFlex">
          <img v-lazy="getImgUrl(item.cover)" />
        </div>
        <p>{{ item.name }}</p>
      </div>
    </template>
    <defaultPage v-else></defaultPage>
  </div>
</template>

<script>
import imgApi from "@/utils/projectConfig.js";
export default {
  name: "appHomeClassUsedCard",
  props: {
    classList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data () {
    return {
      imgApi: imgApi.imgUrl,
      thumbnail: this.$thumbnail,
      classCardList: [],
    };
  },
  mounted () {
    this.onLoad();
    // this.arrayTreeAddLevel(this.classCardList);
  },
  methods: {
    clickClassRow (record) {
      this.$router.push({
        path: "/appClassProductIndex",
        query: { type: "homeClass", id: record.id },
      });
    },

    getImgUrl (url) {
      return this.imgApi + url + this.thumbnail;
    },
    onLoad () {
      this.classCardList = this.classList;
    },
  },
  watch: {
    classList: {
      handler (newVal, oldVal) {
        this.classCardList = [...newVal];
        // this.nodeTree(this.classCardList);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.appHome-classCard-boxers {
  margin-top: 15px;
  display: flex;
  padding: 30px 36px;
  width: 100%;
  overflow-x: scroll;
  background: #fff;

  .class-card-list {
    min-width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    padding: 20px;
    border-radius: 18px;

    .list-item-images {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 18px;
    }

    p {
      font-size: 28px;
      color: #1d1e20;
      margin-top: 12px;
      text-align: center;
      font-weight: 500;
    }
  }

  .class-card-list:last-child {
    margin-right: 0;
  }
}
</style>
