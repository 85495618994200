<template>
  <div class="specification-warper">
    <van-popup class="popup-specification-dialog" v-model="dialogIsShow" position="bottom" round @close="handlerClose">
      <div class="specification-body">
        <div class="body-boxers">
          <div class="body-title">{{ language.specLabel }}</div>
          <div class="body-info flexbox">
            <div class="info-img imgFlex">
              <img v-lazy="getImgUrl(specificationObj.specImg)" class="imgClass" />
            </div>
            <div class="info-boxers flexbox">
              <div class="info-title">
                <p class="text2">
                  {{ specificationObj.name }}
                </p>
              </div>
              <div>
                <div class="info-price">
                  {{ moneyIcon }}{{ specificationObj.unitAmount }}
                </div>
              </div>
            </div>
          </div>
          <div class="body-spec">
            <div class="spec-list" v-for="(item, index) in specificationObj.attributes" :key="index">
              <div class="list-title" v-if="item.name">{{ item.name }}</div>
              <div class="spec-info-tags">
                <div class="tags-list flexbox">
                  <p v-for="(child, childIndex) in item.attributesValue" :key="childIndex"
                    @click="onChangeSize(item, child, childIndex)">
                    <span :class="[
                      'tags-items',
                      item.activeIndex == childIndex ? 'tags-active' : '',
                    ]">
                      {{ child.name }}
                    </span>

                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="body-count flexbox">
            <div class="label">{{ language.countLabel }}</div>
            <div class="count-file">
              <van-stepper v-model="specificationObj.count" :max="specificationObj.stock" min="1" />
            </div>
          </div>
        </div>
        <div class="body-option flexbox">
          <div class="options" @click="onAffirmSize">
            {{ language.specOptions }}
          </div>

        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import imgApi from "@/utils/projectConfig.js";
export default {
  name: 'specificationDialog',
  props: {
    visible: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    dataObj: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
      imgApi: imgApi.imgUrl,
      language: this.$language(),
      moneyIcon: sessionStorage.getItem('moneyIcon'),
      dialogIsShow: false,
      specificationObj: {},
      checkSpace: [],
    }
  },
  mounted () {
    this.dialogIsShow = this.visible
    this.specificationObj = this.dataObj
  },
  methods: {
    handlerClose () {
      this.dialogIsShow = false
      this.$emit('dialogClose')
    },
    onChangeSize (record, child, childIndex) {
      record.activeIndex = childIndex
      let checkSpace = [];
      this.specificationObj.attributes.map((item) => {
        item.attributesValue.map((children, index) => {
          if (item.activeIndex == index) {
            checkSpace.push(children.id);
          }
        });
      });
      let checkSpaceStr = checkSpace.join("|");
      let index = this.specificationObj.skuSortByAttributesValue[checkSpaceStr];
      this.specificationObj.specImg = this.specificationObj.sku[index].skuImage[0].imageUrl
      this.specificationObj.stock = this.specificationObj.sku[index].stock
      this.specificationObj.unitAmount = this.specificationObj.sku[index].unitAmount
      this.specificationObj.count = 1
      this.checkSpace = checkSpace
      this.$forceUpdate()
    },
    onAffirmSize () {
      this.dialogIsShow = false
      this.$emit('onChangeSpec', this.checkSpace)
    },
    getImgUrl (url) {
      return this.imgApi + url
    },
  },
  watch: {
    dataObj: {
      handler (newVal, oldVal) {
        this.specificationObj = { ...newVal }
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.specification-warper {
  width: 100%;
  height: 100%;

  .popup-specification-dialog {
    width: 100%;
    height: 70%;

    .specification-body {
      width: 100%;
      height: 100%;
      padding: 40px;
      background-color: #fff;

      .body-boxers {
        width: 100%;
        height: 85%;
        overflow-y: scroll;
      }

      .body-title {
        font-size: 36px;
        font-weight: 600;
        line-height: 2;
        color: $appSizeColor;
        text-align: center;
      }

      .body-info {
        margin-top: 30px;
        align-items: flex-start;

        .info-img {
          cursor: pointer;
          width: 180px;
          height: 180px;
          justify-content: center;
          border-radius: 12px;
          overflow: hidden;
          border: 1px solid $size8f;

          img {
            height: 100%;
          }
        }

        .info-boxers {
          width: calc(100% - 180px - 24px);
          height: 180px;
          margin-left: 24px;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;

          .info-title {
            color: $appSizeColor;
            font-size: 24px;
            line-height: 1.5;
          }

          .info-price {
            font-size: 28px;
            font-weight: 600;
            color: #e0443a;
          }

          .info-sales {
            margin-top: 8px;
            color: $size8f;
            font-size: 23px;
          }
        }
      }

      .body-spec {
        padding: 46px 0;

        .spec-list {
          margin-bottom: 30px;
          color: $appSizeColor;

          .list-title {
            font-size: 30px;
            margin-bottom: 40px;
          }

          .spec-info-tags {
            .tags-list {
              flex-wrap: wrap;

              .tags-items {
                background-color: #ececf0;
                opacity: 0.9;
                border-radius: 8px;
                display: inline-block;
                cursor: pointer;
                padding: 16px 32px;
                font-size: 25px;
                margin-right: 24px;
                margin-bottom: 24px;
              }

              .tags-active {
                border: 1px solid $blur;
                background: rgba(241, 235, 243, 0.6);
                color: $blur;
                font-weight: 600;
              }
            }
          }
        }

        .spec-list:last-child {
          margin-bottom: 0;
        }
      }

      .body-count {
        justify-content: space-between;

        .label {
          font-size: 30px;
        }

        .count-file {
          ::v-deep .van-stepper {

            .van-stepper__minus--disabled,
            .van-stepper__plus--disabled {
              opacity: 0.5 !important;
            }

            .van-stepper__minus {
              height: 64px;
              background-color: $blur;
              color: #fff;
              font-size: 32px;
              font-weight: 600;
            }

            .van-stepper__minus::before {
              width: 30%;
              height: 4px;
              border-radius: 4px;
            }

            .van-stepper__input {
              margin: 0;
              height: 64px;
            }

            .van-stepper__plus {
              height: 64px;
              background-color: $blur;
              color: #fff;
              font-size: 32px;
              font-weight: 600;
            }

            .van-stepper__plus::before {
              width: 24px;
              height: 4px;
              border-radius: 4px;
            }

            .van-stepper__plus::after {
              width: 4px;
              border-radius: 4px;
              height: 24px;
            }
          }
        }
      }

      .body-option {
        // position: align-self: baseline;;
        justify-content: center;
        padding: 40px 0;

        .options {
          width: 80%;
          text-align: center;
          line-height: 1.5;
          padding: 12px 46px;
          border-radius: 16px;
          border: 1px solid $blur;
          font-size: 36px;
          background-color: #fff;
          background-color: $blur;
          color: #fff;
        }


      }
    }
  }
}
</style>
