import service from '@/required/appAxios'
export default {
  // 获取全部分类数据 
  sendEmailCode (data) {
    return service({
      url: '/common.send_email_verify_code',
      method: "post",
      data: data
    })
  },
  // 上传图片
  importUpload (data) {
    return service({
      url: 'common.upload',
      method: "POST",
      data: data
    })
  },
  // 店铺申请
  shop_application (data) {
    return service({
      url: 'auth.apply',
      method: "POST",
      data: data
    })
  },
  // 系统 - 系统内容配置数据
  getConfig_content (form) {
    return service({
      url: `system.config_content?`,
      method: "get",
      params: form
    })
  },
}