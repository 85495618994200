// 韩语

let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "집",
    },
    "searchLanguage": {
        searchBtn: '찾다',
        searchPlace: '찾다....'
    },
    'setDrawer': {
        label1: '다국어 설정',
        label2: '상인이 정착했습니다',
        label3: '가맹점 로그인',
        label4: '고객 서비스',
        label5: 'Android 애플리케이션 다운로드',
        label6: 'ios 애플리케이션 다운로드',
        optionsBtn: '취소'
    },
    "appService": {
        navTitle: '고객 서비스',
        iconInformation: '상담을 위한 통신 소프트웨어를 선택해주세요',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
    },
    "appMerchantSettlement": {
        navTitle: '상인 정착',
        label1: '판매자 이름',
        label1Hint: '판매자 이름이나 브랜드 이름은 한 번 설정하면 수정할 수 없습니다.',
        visible1: '판매자 이름을 입력하세요',

        label2: '사서함',
        label2Hint: '판매자가 판매자 포털에 로그인하는 데 사용하는 비밀번호입니다. ',
        visible2: '이메일을 입력해주세요',
        label3: '이메일 확인 코드',
        label3Hint: '판매자 신원을 확인하고 악의적인 활동을 방지하려면 코드가 필요합니다. 올바른 인증코드 정보를 입력해주세요',
        visible3: '이메일 인증번호를 입력해주세요',

        label4: '판매자 비밀번호',
        label4Hint: '판매자가 판매자 포털에 로그인하는 데 사용하는 비밀번호입니다. ',
        visible4: '판매자 비밀번호를 입력하세요',
        label5: '초대 코드',
        visible5: '초대코드를 입력해주세요',
        label5Hint: '당신을 초대한 판매자의 초대 코드를 입력하세요. ',

        label6: '이미지 저장',
        label6Hint: '가맹점의 상점 이미지',
        visible6: '인증번호를 입력해주세요',
        label7: '판매자 증명서',
        label7Hint: '가맹점 허가증, 세금 등록 증명서 등과 같은 판매자의 증명서 문서. 이 파일은 고품질 이미지 파일이어야 합니다. ',
        visible7: '판매자 면허증 및 관련 업계 자격증 사진을 업로드해주세요. (최대 2장까지 업로드 가능하며 각 사진의 최대 크기는 5MB이며 사진 형식은 Jpg 및 Png를 지원합니다.)',

        placeholderEnter: '입력하세요',
        placeholderSelect: '선택하세요',
        sendEmail: '보내기',
        optionBtn1: '서비스 약관',
        optionBtn2: '판매자 보호',
        optionBtn3: '검토를 위해 제출',
        uploadMessageError: '이미지 업로드 실패',
        UploadLayoutError: '이미지 형식 오류',
        fileFail: '사진을 업로드하고 형식을 지정해 주세요.',
        popupTitle1: '서비스 약관',
        popupTitle2: '판매자 보호 계약'
    },
    'appSetLanguage': {
        navTitle: '다국어 설정',
        affirm: '확인하다',
    },
    'classifyDrawer': {
        navTitle: '범주'
    },
    'appHome': {
        recommendTitle: '추천',
        viewAll: '모두 보기',
        usedTitle: '중고',
        buyNow: '지금 구매',
        shopTitle: '판매자',
        shopOption: '옷장',
        hotTitle: '핫',
        ranking: '판매 순위',
        preSaleTitle: '사전 판매',
        preSaleTabs1: '진행 중',
        preSaleTabs2: '시작되지 않음',
        preSaleTabs3: '오버',
        groupTitle: '그룹',
        peopleGroupBuying: '사람들이 참여했습니다',
        flashSaleTitle: '플래시',
        preSaleStateTimeS: '시작',
        preSaleStateTimeE: '종료',
        showNow: '지금 쇼핑하러 가세요'
    },
    "appShopsListIndex": {
        navTitle: '판매자 목록',
        searchPlace: '검색...',
        favoriteTitle: '즐겨찾는 수량 &',
        shopBtn: '옷장 보기',
    },
    // 移动分类页
    'appClassification': {
        navTitle: '카테고리',
        viewAll: '모두 보기',
    },

    'appClassProductIndex': {
        navTitle: '제품',
        select1: '종합',
        select2: '판매 수량',
        select3: '새 제품',
        select4: '가격이 높은 순으로',
        select5: '낮은 가격순',
        select6: '평가',
        paymentTitle: '결제',

    },
    'appPreSale': {
        navTitle: '사전 판매',
        tabs1: '진행 중',
        tabs2: '시작되지 않음',
        tabs3: '오버',
        selectAll: '모두',
        listLabel: '배송',
        listLabe2: '일',
    },
    "appGroupBuying": {
        navTitle: '그룹',
        selectAll: '모두',
        listLabel: '필요',
        listLabe2: '사람',
    },
    "appFlashSale": {
        navTitle: '플래시',
        selectAll: '모두',
    },
    'appSalesRanking': {
        navTitle: '핫',
        payment: '결제',
        optionBtn1: '수집',
        optionBtn2: '지금 구매',
    },
    'appLogin': {
        navTitle: '로그인',
        navTitleHint: '지금 로그인하여 전 세계의 친구들과 거래를 시작하세요! ',
        singUp: '등록',

        emailLabel: '휴대폰번호',
        emailPlaceholder: '휴대폰 번호를 입력하세요',
        emailErrorText: '오류 메시지! ',
        emailNullVerify: '휴대폰번호를 입력해주세요!',//이메일 주소를 입력해주세요
        emailErrorVerify: '휴대폰 번호를 입력하세요! ',//이메일 형식 오류

        passwordLabel: '귀하의 비밀번호',
        passPlaceholder: '비밀번호를 입력하세요',
        passNullVerify: '비밀번호를 입력하세요', //비밀번호를 입력하세요
        passErrorVerify: '비밀번호가 6자 미만입니다! ',//비밀번호가 6자 미만입니다.

        placeholder: '입력하세요',

        emailRegistration: '지금 등록하세요',
        forgetPassTitle: '비밀번호를 잊으셨나요? ',

        submitBtnTitle: '로그인',
        loginMessage: '계정 또는 비밀번호가 올바르지 않습니다! '
    },
    'appForgotPass': {
        navTitle: '비밀번호를 잊어버렸습니다',
        navTitleHint: '새 비밀번호를 설정하려면 휴대폰 번호를 입력하고 인증 코드를 받으세요. ',
        emailLabel: '휴대폰번호',
        emailPlaceholder: '휴대폰 번호를 입력하세요',
        emailErrorText: '오류 메시지!',//이메일 주소를 입력해주세요
        emailNullVerify: '휴대폰번호를 입력해주세요!',//이메일 주소를 입력해주세요
        emailErrorVerify: '휴대폰 번호를 입력하세요!',//이메일 형식 오류
        verificationCode: '인증 코드',
        verificationPlace: '인증 코드',
        verificationCodeError: '인증코드를 입력해주세요! ',
        sendTitle: '인증코드 보내기',
        sendText: '재확인',
        passwordLabel: '새 비밀번호',
        passPlaceholder: '새 비밀번호를 입력하세요',
        confirmPassLabel: '새 비밀번호 확인',
        confirmPlace: '새 비밀번호 확인',
        passNullVerify: '새 비밀번호를 입력하세요', //비밀번호를 입력하세요.
        passErrorVerify: '비밀번호가 6자 미만입니다! ',//비밀번호가 6자 미만입니다.
        passCompareErrorVerify: '두 번 입력한 비밀번호가 일치하지 않습니다! ',
        passCompareErrorNull: '새 비밀번호를 다시 입력하세요',
        submitBtnTitle: '비밀번호 재설정',
        loginMessage: '인증번호가 올바르지 않습니다! '
        // emailRegistration: '이메일 등록',
        // 잊어버리세요PassTitle: '비밀번호를 잊으셨나요?',

    },
    'appRegister': {
        navTitle: '등록',
        navTitleHint: '모든 기능에 액세스하려면 계정을 만드세요! ',
        namePlace: '입력하세요',
        firstNameLabel: '이름',
        firstNamePlace: '입력하세요',
        firstNameErrorVerify: '이름을 입력하세요',
        lastNameLabel: '성',
        lastNameErrorVerify: '성명을 입력하세요',
        nickNameLabel: '닉네임',
        nickNameErrorVerify: '닉네임을 입력하세요',
        emailLabel: '휴대폰번호',
        emailPlaceholder: '휴대폰 번호를 입력하세요',
        emailErrorText: '오류 메시지!',//이메일 주소를 입력해주세요
        emailNullVerify: '휴대폰번호를 입력해주세요!',//이메일 주소를 입력해주세요
        emailErrorVerify: '휴대폰 번호를 입력하세요! ',//이메일 형식 오류
        verificationCode: '인증 코드',
        verificationPlace: '인증번호를 입력하세요',
        sendTitle: '인증코드 보내기',
        sendText: '카운트다운',
        verificationCodeError: '인증코드를 입력해주세요! ',
        passwordLabel: '비밀번호',
        passPlaceholder: '비밀번호를 입력하세요',
        confirmPassLabel: '비밀번호 확인',
        confirmPlace: '비밀번호 확인',
        passNullVerify: '비밀번호를 입력하세요',//비밀번호를 입력하세요
        passErrorVerify: '비밀번호가 6자 미만입니다! ',//비밀번호가 6자 미만입니다.
        passCompareErrorVerify: '두 번 입력한 비밀번호가 일치하지 않습니다! ',
        passCompareErrorNull: '비밀번호를 다시 입력해 주세요',
        registerHint: '이미 계정이 있나요? ',
        registerHintLogin: '로그인',
        submitBtnTitle: '등록',
        loginMessage: '인증번호가 올바르지 않습니다! '

        // gender: 'Gender',
        // gender1: 'Boy',
        // gender2: 'Girl',
        // gender3: 'Secrecy',
        // genderError: 'Please enter your gender',
    },
    'appGoodsDetail': {
        salesTitle: '판매',
        payHintTitle1: '잔액',
        payHintTitle2: '페이팔',
        payHintTitle3: '비자',
        conditionLabel: '조건:',
        conditionContent: '브랜드 카테고리 지원 결제 수단',
        frightLabel: '물류',
        frightContent: '평균 1~3일 이내 배송',
        agreement: '구매자 보호 계약',
        specSelect: '사양을 선택해주세요',
        specLabel: '사양',
        specOptions: '확인',
        countLabel: '수량',
        commentTitle: '댓글',
        deliverTime: '배송 시간 및 요일 평가',
        salesLabel: '판매 수량:',
        productCount: "제품 수량:",
        collectLabel: '수집 수량:',
        averageLabel: '평균 일수:',
        since: '멤버 데이',
        shipper: '빠른 응답자',
        responder: '빠른 배송업체',
        reliable: '신뢰할 수 있는',
        btnContact: '연락처',
        enter: '옷장',
        followBtn: '컬렉션',
        guessLike: '좋아하는 것 같아요',
        buyNow: '지금 구매',
        dialogOverviewTitle: '개요',
        overConditionLabel: '조건',
        overConditionContent: '새',
        overBrandLabel: '브랜드',
        overCategoryLabel: '범주',
        overPayMethodsLabel1: '지원되는 결제 방법',
        overPayMethodsLabel2: '중요 정보',
        overCategoryEnter: '확인',
        dialogFreightTitle: '물류 계약',
        dialogAgreementTitle: '구매자 보호 계약',
        footerLeft: '장바구니에 추가',
        footerRight: '지금 구매',
        activeLabel1: '공동구매',
        activeLabel2: '두 번째 판매',
        activeLabel3: '사전 판매',
        flashStateNameE: '종료',
        flashStateNameI: '진행 중',
        flashStateNameS: '시작되지 않음',
        flashHintSName: '시작',
        flashHintEName: '종료됨',
        flashHintIName: '남은 시간',
        preSaleStateTimeNO: '시작되지 않음',
        preSaleStateTimeE: '종료',
        preSaleStateTimeS: '진행 중',
        preSaleHintNoName: '시작',
        preSaleHintIEName: '종료됨',
        preSaleHintEName: '종료',
        groupHint: '사람',
        reviewTitle: '시스템 기본 검토'
    },
    "appShops": {
        select1: '종합',
        select2: '판매 수량',
        select3: '새 제품',
        select4: '가격이 높은 순으로',
        select5: '낮은 가격순',
        select6: '평가',
        infoLabel1: '판매 수량',
        infoLabel2: '제품 수량',
        infoLabel3: '수집 수량',
        infoLabel4: '배송 시간 평가',
        memberLabel1: '멤버 데이',
        memberLabel2: '빠른 응답자',
        memberLabel3: '빠른 배송업체',
        memberLabel4: '신뢰할 수 있음',
        commodity: '상품',
        classAll: '모두',

        // timeDay: 'Evaluate Delivery time & days',
        // productQuantity: 'Product Quantity',
        // shippedQuantity: 'Shipped Quantity',
        // collections: "Number of Collections",
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 商品评论页
    'appProductReviews': {
        navTitle: '댓글',
        reviewTitle: '시스템 기본 댓글',
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: '버전 업그레이드를 확인해주세요. ',
        loadingTitle: '다운로드 중...',
        systemUpgrade: '시스템 업그레이드',
        upgradeImmediately: '확인',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "로드 중", // 로드 중
        finishedText: "없음", //없음
        loadingText: "로드 중", // 로드 중
        nullText: '아직 데이터가 없습니다',
        loadingFinished: "로드가 완료되었습니다",
        listNullText: '더 이상은 없습니다',
        pullIngText: '새로고침하려면 아래로 당기세요',
        looseText: '새로고침하려면 릴리즈하세요',
        pullingSuccess: '풀다운 로딩 성공',
        source: '확인'
    },

};
export default obj;
