// 英语

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '한국인', // 韩语
    '繁體中文', // 繁体中文
    // '日本語', // 日语
    // 'Português', // 葡萄牙语
   
    // 'Français', // 法语
    // 'Deutsch', // 德语
    // 'Türk', // 土耳其语
    // 'Español', // 西班牙语
    // 'عربي', // 阿拉伯语
   
    // 'Suomi', // 芬兰语
    // 'Русский', // 俄语
    // 'italiano', // 意大利语
    // 'แบบไทย', // 泰语
    // 'Nederlands', // 荷兰语
  ],
  'navigation': {
    home: "Home",
    categories: "CATEGORY",
    hot: "HOT",
    uset: "USED",
    seckill: "SECKILL",
    presale: "PRESALE",
    group: "GROUP",
    login: 'Login',
    singUp: 'Sign up',
  },
  'classifyDrawer': {
    navTitle: 'Category'
  },
  'home': {
    showNow: 'Go shopping now',
    viewMore: 'View more',
    recommendTitle: 'Recommend',
    merchantTitle: 'Merchant',
    preSaleTitle: 'Presale',
    preSaleStarted: 'Start',
    preSaleNotStarted: 'Not started',
    preSaleEnded: 'End',
    secKillTitle: 'Seckill',
    groupBuyingTitle: 'Group',
    groupBuyingViewAll: 'View more',
    usedTitle: 'Used',
    sales: 'Sales',
    product: 'Product',
    ranking: 'Ranking',
    viewBtn: 'View All',
  },
  "shopList": {
    searchPlaceholder: 'Search more...',
    salesLabel1: 'Product quantity',
    salesLabel2: 'Favorite quantity',
    salesLabel3: 'Sales quantity',

  },
  'downUploadLanguage': {
    downUpLoadTitle: 'Download application',
    downUpLoadText: 'Download the app for free on iPhone, iPad and Android',
    phoneText: 'APP STORE',
    chromeText: 'GOOGLE PLAY',
  },
  'footerLanguage': {
    // 分类
    cateGoriesTitle: 'Category',

    // 政策中心
    policyTitle: 'Policy Center',
    policyTerms: 'Terms of Service',
    policyPrivacy: 'Privacy Policy',
    policyItems: 'Prohibited Items',
    policyConduct: 'Prohibited Conduct',
    policyElectronics: 'Electronic Communication Policy',
    policyMinors: 'Minors',
    policyDMCA: 'DMCA/Copyright Notice',
    policySMS: 'SMS Policy',
    policyRequests: 'Requests from law enforcement',
    // 公司社区
    companyTitle: "Company",
    companyAboutUs: "About us",
    companyHiring: "We're Hiring",
    companyCommunity: "Our Community",
    // 支持
    supportTitle: 'Support',
    supportGuidelines: 'Marketplace Guidelines.',
    supportSafety: 'Safety Guidelines',
    supportLogistics: 'Logistics Services',
    supportSeller: 'Seller Protection',
    supportBuyer: 'Buyer Protection',
    supportAccessibility: 'Accessibility',

    footerRightTitle: 'BUY AND SELL ON APPS',
    contactUs: 'CONTACT US',
    setLanguage: 'Language Setting',
    footerLogoCopyright: '©',
    ingTitle: ',inc'
  },
  'login': {
    navTitle: 'Login',
    navTitleHint: "Don't have an ccount? ",
    singUp: 'Sign up',
    mobileLabel: 'Your mobile phone',
    mobileNullVerify: 'Please enter your email', //Please enter your email
    passwordLabel: 'Your password',
    passwordVerify: 'Enter your password', //Please enter your password
    placeholder: 'Please enter',
    forgetPass: 'Forgot password',//forgot password
    loginBtnTitle: 'Login',
    forgetPassTitle: 'Forgot password',
    loginMessage: 'Mobile phone number or password is wrong! ',
  },
  'shoppingCar': {
    navTitle: 'Shopping Cart',
    checkBoxTitle: 'Select all',
    removeTitle: 'Delete',
    selectPriceTitle1: 'Selected product',
    selectPriceTitle2: 'pieces, equivalent to',
    optionsBtn1: 'Payment'
  },
  "application": {
    navTitle: 'Merchant settled in',
    label1: 'Business name',
    label1Hint: 'Business name or brand name, once set, cannot be modified',
    visible1: 'Please enter the store name',

    label2: 'Email',
    label2Hint: 'The password used by the merchant to log in to the merchant portal. It should be a secure password of at least 8 characters. ',
    visible2: 'Please enter your email',
    label3: 'Verification code',
    label3Hint: 'Code required to confirm merchant identity and prevent malicious activity. Please provide correct verification code information',
    visible3: 'Please enter the verification code',

    label4: 'Merchant Password',
    label4Hint: 'The password used by the merchant to log in to the merchant portal. It should be a secure password of at least 8 characters. ',
    visible4: 'Please enter the merchant password',
    label5: 'Invitation Code',
    label5Hint: 'Enter the invitation code of the merchant that invited you',

    label6: 'Logo',
    label6Hint: "Merchant's store Logo",
    visible6: 'Please enter the verification code',
    label7: 'Proof of Identity',
    label7Hint: "Front and back images of identification documents",
    visible7: 'Please Upload Pictures Of Your Business License And RelevantIndustry Qualification Certificates ( Maximum Can Upload 10 lmagesWith A Maximum Size Of 2m Per lmage And lmage Format SupportJpg, Png, Jpeg)',

    placeholderEnter: 'Please enter',
    placeholderSelect: 'Please select',
    sendEmail: 'Send',
    optionBtn1: 'Terms of Service',
    optionBtn2: 'Seller Protection',
    optionBtn3: 'Submit for review',
    uploadMessageError: 'Image upload failed',
    UploadLayoutError: 'Image format error'
  },
  'forgetPass': {
    navTitle: 'Forgot password',
    navTitleHint: "Don't have an ccount? ",
    loginIn: 'Log in',

    emailLabel: 'Mobile phone number',
    mobileNullVerify: 'Please enter your mobile phone number', //Please enter your email address

    verificationLabel: 'Verification Code',
    verificationVerify: 'Enter verification code',//Please enter the verification code
    confirmEmail: 'Confirmation email',

    password1Label: 'Your password',
    password1Verify: 'Enter your password',//Please enter your password

    password2Label: 'Confirm password',
    password2Verify: 'Please enter your password again', //Please enter your password again
    passwordRepetitionVerify: 'The passwords entered twice are inconsistent', //Please enter the password again

    placeholder: 'Please enter',

    submitBtnTitle: 'Submit',

    forgetPassTitle: 'Forgot password',
    codeMessage: 'Verification code is incorrect!'
  },
  'singUpIndex': {
    navTitle: 'Register',
    navTitleHint: 'Already have an account? ',
    loginIn: 'Login',
    firstNameLabel: 'First Name', // Nickname
    firstNameVerify: 'Please enter your first name', //Please enter your name
    lastNameLabel: 'Last Name', // last name
    lastNameVerify: 'Please enter your last name',
    nicknameLabel: 'Nickname',//name
    nicknameVerify: 'Please enter your nickname',
    mobileLabel: 'Mobile phone number',
    mobileNullVerify: 'Please enter your mobile phone number', //Please enter your mobile phone number
    verificationLabel: 'Verification code',
    verificationVerify: 'Enter verification code',//Please enter the verification code
    confirmEmail: 'Confirmation email',
    password1Label: 'Your password',
    password1Verify: 'Enter your password',//Please enter your password
    password2Label: 'Confirm password',
    password2Verify: 'Please enter your password again', //Please enter your password again
    passwordRepetitionVerify: 'The passwords entered twice are inconsistent', //Please enter the password again
    placeholder: 'Please enter',
    submitBtnTitle: 'Registration',
    codeMessage: 'Verification code is incorrect!'
  },
  'hot': {
    hotTitle: 'Top 15 Sales Rankings',
    product: 'Product',
    sales: 'Sales',
    ranking: 'Ranking',
    viewBtn: 'View All',
    selectPlaceholder: 'Please select',
    viewPayment: 'Payment'
  },
  'used': {
    classTitle: "Category",
    brandCardTitle: 'Brand',
    brandPlace: 'Search brand',
    searchPlaceholder: 'Search more...',
    recommendTitle: 'recommended',
    crumbsTitle: 'Default',
    select1: 'Comprehensive',
    select2: 'Sales Volume',
    select3: 'New product',
    select4: 'Price High to Low',
    select5: 'Price Low to High',
    select6: 'Score',
    SearchCriteria: 'Search Criteria:',
    clearAll: 'Clear All',
    loading: 'Loading...',
    noMore: 'No more'
  },
  'classProduct': {
    classTitle: "Category",
    brandCardTitle: 'Brand',
    brandPlace: 'Search brand',
    searchPlaceholder: 'Search more...',
    recommendTitle: 'recommended',
    crumbsTitle: 'Default',
    select1: 'Comprehensive',
    select2: 'Sales Volume',
    select3: 'New product',
    select4: 'Price High to Low',
    select5: 'Price Low to High',
    select6: 'Score',
    SearchCriteria: 'Search Criteria:',
    clearAll: 'Clear All',
    loading: 'Loading...',
    noMore: 'No more'

  },

  'group': {
    defaultTitle: 'Default',
    selectTitle: 'Please select',
    requires: 'requires',
    people: 'people',
    classAll: 'All',

  },
  'presale': {
    defaultTitle: 'Default',
    selectTitle: 'Please select',
    requires: 'shipping requirements',
    people: 'day',
    classAll: 'All',
    started: 'start',
    notStarted: 'Not started',
    ended: 'end',

  },
  // 秒杀页面
  'seckill': {
    classAll: 'all',
    selectTitle:'please select'
  },
  'productDetail': {
    likeMessage: 'You are not logged in yet, please log in first',
    collectLabel: 'Like',
    shareLabel: 'share',
    commentLabel: 'Comment',
    defaultLabel: 'Default',
    numberLabel: 'Number',
    numberTotal: 'All:',
    buyNow: 'Buy now',
    addChart: 'Add to shopping cart',
    brand: 'Brand:',
    paymentMethod: 'Payment method:',
    deliveryInformation: 'All orders are shipped expedited (1-3 days)',
    payLabel1: 'Balance',
    payLabel2: 'PayPal',
    payLabel3: 'visa',
    policyLabel: 'Safety',
    policyTitle: 'Buyer Protection Policy',
    policyInfo: 'Follow instructions to obtain an order or receive a refund',
    policyMore: 'Learn more',
    aboutSellersTitle: 'About Sellers:',
    contactMerchants: 'Contact merchant',
    viewClosets: 'View closets',
    sales: 'Sales',
    product: 'Product count',
    care: 'Care count',
    average: 'Average days',
    memberSince: 'Member since',
    fastResponder: 'Fast responder',
    fastShipper: 'Fast shipper',
    reliable: "Reliable",
    guessTitle: 'Guess You Like It',
    activeLabel1: 'Group purchase',
    activeLabel2: 'Second Sale',
    activeLabel3: 'Presale',
    presaleHint: 'Shipment within % after successful payment',
    timeDays: 'day',
    activeState1Time1: 'End',
    activeState1Time2: 'Go',
    activeState1Time3: 'Start',
    activeTimeHint1: "It's over",
    activeTimeHint2: 'Remaining time',
    activeTimeHint3: 'The starting time is',
    activityPresellState: 'End',
    activityPresellHint: 'person',
    activeStart: 'Start',
    activeEnd: 'End',
  },
  "merchant": {
    follow: 'Follow',
    loginMessage: 'You are not logged in yet, please log in first! ',
    searchPlaceholder: 'Search product',
    salesProductTitle: 'Actual number of products sold',
    shippedQuantity: "Total number of products",
    collections: "number of collections",
    evaluate: "Evaluate delivery time",
    category: 'category',
    brandCardTitle: 'Brand',
    brandPlace: 'Please enter',
    memberSince: 'Member Day',
    responder: 'quick responder',
    shipper: 'fast shipper',
    reliable: 'reliable',
    brandPlace: 'Search brand',
    productTitle: 'Product',
    select1: 'comprehensive',
    select2: 'Sales quantity',
    select3: 'New product',
    select4: 'Price from high to low',
    select5: 'Price from low to high',
    select6: 'Rating',
    clearAll: 'Clear all',
    searchCriteria: 'Search criteria:'
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Version upgrade, please confirm? ',
    loadingTitle: 'Downloading...',
    systemUpgrade: 'System Upgrade',
    upgradeImmediately: 'Confirm',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Loading", // Loading
    finishedText: "None", //None
    loadingText: "Loading", // loading
    nullText: 'No data yet'
  },

};
export default obj;
