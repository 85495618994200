<template>
  <div class="search-warper">
    <van-field
      v-model="searchValue"
      :placeholder="searchLanguage.searchPlace"
      @click-input="searchClick"
    >
      <template #right-icon v-if="searchValue">
        <svg-icon icon-class="app-remove" @click="onClickRemove"></svg-icon>
      </template>
      <template #button>
        <div class="search-div" @click="onClickSearch">
          {{ searchLanguage.searchBtn }}
        </div>
      </template>
    </van-field>
  </div>
</template>

<script>
export default {
  name: "appSearchPageIndex",
  props: {
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      searchLanguage: this.$language("searchLanguage"),
      searchValue: "",
    };
  },
  methods: {
    searchClick() {
      if (this.disabled) {
        this.$emit("searchClick");
      }
    },
    onClickSearch() {
      if (this.disabled) {
        this.$emit("searchClick");
      } else {
        this.$emit("searchChange", this.searchValue);
      }
    },
    onClickRemove() {
      this.searchValue = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.search-warper {
  font-family: "GrifaSlabRegular";
  padding: 30px;

  .van-cell {
    background: #f5f6fa;
    border-radius: 18px;
    height: 80px;
    padding: 0 10px 0 30px;

    ::v-deep .van-field__left-icon {
      display: flex;
      align-items: center;

      svg {
        width: 46px !important;
        height: 46px !important;
        color: #232426;
      }
    }

    ::v-deep .van-cell__value {
      height: 100%;

      .van-field__body {
        height: 100%;

        .van-field__control {
          height: 100%;
          font-size: 28px;
          color: #232426;
          font-weight: 500;
        }
        .van-field__right-icon {
          display: flex;
          align-items: center;
        }
        .van-field__button {
          .search-div {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 32px;
            background: #fff;
            border-radius: 12px;
            color: #fff;
            font-size: 22px;
            background-color: #6d3580;
            background-image: linear-gradient(
              -15deg,
              $blur,
              #6d3580 61%,
              #795b83
            );
            border-radius: 3.73333vw;
            box-shadow: 0 0.4vw 1.73333vw 0 rgba(255, 149, 0, 0.17);
          }
        }
      }
    }
  }
}
</style>
