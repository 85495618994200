<template>
  <!-- 产品评论列表 -->
  <div class="appReviews-warper">
    <appHeader :navTitle="language.navTitle"></appHeader>
    <div class="productReviews-body">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="message.listNullText"
        :loading-text="message.loadingText"
        @load="onLoad"
      >
        <div class="reviews-card-list" v-if="reviewsList.length">
          <div
            class="card-lit-item"
            v-for="(item, index) in reviewsList"
            :key="index"
          >
            <div class="list-item-header flexbox">
              <div class="avatar-img imgFlex">
                <el-avatar
                  v-if="!item.avatarIcon"
                  class="about-sellers-logo"
                  :style="`background:${extractColorByName(item.nickname)}`"
                >
                  {{ elAvatarSlice(item.nickname) }}
                </el-avatar>
                <img v-lazy="item.avatarIcon" v-else />
              </div>
              <div class="item-header-introduce flexbox">
                <div class="left">
                  <p class="introduce-title noText">{{ item.nickname }}</p>
                  <!-- <p class="introduce-time">{{ item.createAt }}</p> -->
                </div>
                <div class="right">
                  <div class="info-nav-stareLeave flexbox">
                    <div
                      v-for="(svgItem, svgIndex) in 5"
                      :key="svgIndex"
                      :class="[
                        'stare-leave',
                        item.productScore >= svgIndex + 1 && 'star-active',
                      ]"
                    >
                      <svg-icon
                        width="20px"
                        height="20px"
                        icon-class="pc-gray-star"
                      ></svg-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-item-content">
              <div class="content-comment">
                {{ item.comment }}
              </div>
              <!-- <div class="content-unique">{{ item.unique }}</div> -->
            </div>
          </div>
        </div>
        <default-page v-else></default-page>
      </van-list>
    </div>
  </div>
</template>

<script>
import appHeader from "../component/app-header.vue";
import appGoodsDetailApi from "@/api/h5Api/appGoodsDetailApi";
import imgApi from "@/utils/projectConfig";
export default {
  name: "appReviewsPageIndex",
  data() {
    return {
      loading: false,
      imgApi: imgApi.imgUrl,
      finished: false,
      language: this.$language(),
      message: this.$language("messageLanguage"),
      tabsActive: 0,
      reviewsList: [],
      thumbnail:this.$thumbnail,
      formObj: {
        page: 1,
        size: 20,
        total: 0,
      },
    };
  },
  mounted() {},
  methods: {
    onLoad() {
      this.formObj.page = 1;
      this.formObj.total = 0;
      this.getCommentInfoData();
    },
    // 评论数据
    getCommentInfoData() {
      const params = {
        page: this.formObj.page,
        size: this.formObj.size,
        productId: this.$route.query.id,
      };
      appGoodsDetailApi.get_commentInfoPort(params).then(({ code, data }) => {
        if (code === 200) {
          data.list.map((item) => {
            item.avatarIcon = "";
            if (item.avatar) {
              item.avatarIcon = this.imgApi + item.avatar + this.thumbnail;
            }
            if (!item.comment) {
              item.comment = this.language.reviewTitle;
            }
            item.createAt = item.createAt?.split(" ")[0];
          });
          this.reviewsList = this.reviewsList.concat(data.list);
          this.formObj.total = data.total;
          if (this.reviewsList.length >= this.formObj.total) {
            this.finished = true;
          } else {
            this.finished = false;
            this.formObj.page++;
          }
        }
      });
    },
    // 用户名称截取作为头像
    elAvatarSlice(name) {
      if (name) {
        return name.slice(0, 1);
      }
    },
    /**
     * 根据名字提取颜色
     * @param name 名字
     */
    extractColorByName(name) {
      if (name) {
        let temp = [];
        temp.push("#");
        for (let index = 0; index < name.length; index++) {
          temp.push(parseInt(name[index].charCodeAt(0), 10).toString(16));
        }
        return temp.slice(0, 5).join("").slice(0, 4);
      }
    },
  },
  components: { appHeader },
};
</script>

<style lang="scss" scoped>
.appReviews-warper {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  ::v-deep .app-component-header-warper {
    position: fixed;
    top: 0px;
    width: 100%;
    background-color: #fff;
    .svg {
      background-color: transparent;
    }
  }

  .productReviews-body {
    width: 100%;
    height: 100%;
    padding-top: 105px;
    background-color: #fafafa;
    .van-list {
      padding: 30px 36px;
      background-color: #fafafa;
      .reviews-card-list {
        .card-lit-item {
          padding: 36px;
          background-color: #fff;
          border-radius: 18px;
          margin-bottom: 30px;
          box-shadow: 0 8px 10px 5px #f0f0f0;
          .list-item-header {
            width: 100%;
            padding-bottom: 26px;
            height: auto;
            border-bottom: 2px solid #f5f5f5;
            .avatar-img {
              min-width: 68px;
              width: 68px;
              height: 68px;
              border-radius: 50%;
              margin-right: 12px;
              background: #fff;
              border: 1px solid #ebebeb;
            }

            .item-header-introduce {
              width: calc(100% - 68px);
              justify-content: space-between;
              padding-left: 12px;
              .left {
                width: 65%;
                .introduce-title {
                  width: 75%;
                  margin-bottom: 12px;
                  font-size: 28px;
                  color: #1d1e20;
                }
                .introduce-time {
                  color: #999fa7;
                }
              }
              .right {
                .info-nav-stareLeave {
                  align-items: center;

                  svg {
                    width: 32px !important;
                    height: 32px !important;
                    color: #dbdbdf;
                  }

                  .star-active {
                    svg {
                      color: #ffcc33;
                    }
                  }
                }
              }
            }
          }
          .list-item-content {
            padding: 30px 0 0 0;
            font-size: 21px;
            color: #1d1e20;
            .content-img {
              margin-top: 30px;
              width: 380px;
              height: 380px;
              background-color: #f5f5f5;
              border-radius: 12px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .content-unique {
              font-size: 24px;
              margin-top: 30px;
              color: #8f959e;
            }
          }
        }
      }
    }
  }
}
</style>
