<template>
  <!-- 产品分类list页面 -->
  <div class="appClassList-page-warper">
    <appHeader :navTitle="language.navTitle"></appHeader>
    <searchCard @searchChange="searchChange"></searchCard>
    <div class="card-tabs-body">
      <div class="card-list-body">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="message.listNullText"
          :loading-text="message.loadingText"
          @load="onLoad"
        >
          <div class="card-list-boxers">
            <div
              v-for="(item, listIndex) in shopListData"
              :key="listIndex"
              class="card-list-item"
            >
              <div
                class="appShopClass-card"
                @click="
                  $router.push({
                    path: '/appShops',
                    query: { id: item.id, sourcePage: 'shopsList' },
                  })
                "
              >
                <div class="appShop-img">
                  <img v-lazy="getImgUrl(item.backgroundImage)" />
                </div>
                <div class="appShop-info">
                  <div class="appShop-info-left">
                    <div class="info-logo imgFlex">
                      <img v-lazy="getImgUrl(item.image)" />
                    </div>
                    <div class="info-name">
                      <p class="name noText">{{ item.name }}</p>
                      <div class="info-nav-stareLeave flexbox">
                        <div
                          v-for="(svgItem, svgIndex) in 5"
                          :key="svgIndex"
                          :class="[
                            'stare-leave',
                            item.star >= svgIndex + 1 && 'star-active',
                          ]"
                        >
                          <svg-icon icon-class="app-heard"></svg-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="appShop-options">
                  <span>
                    {{ language.shopBtn }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import appHeader from "../component/app-header.vue";
import searchCard from "../component/app-search.vue";
import imgApi from "@/utils/projectConfig";
import appCell1Card from "../component/appCell1-card.vue";
import appShopCard from "../component/appShopClass-card.vue";
import appHotCard from "../component/appHotClass-card.vue";
import appShopListApi from "@/api/h5Api/appShopListApi.js";
export default {
  name: "appClassListPage",
  data() {
    return {
      language: this.$language(),
      message: this.$language("messageLanguage"),
      imgApi: imgApi.imgUrl,
      thumbnail: this.$thumbnail,
      loading: false,
      finished: false,
      refreshing: false,
      routerPage: "",
      shopListData: [],
      formObj: {
        name: "",
        page: 1,
        size: 20,
        total: 0,
      },
    };
  },
  mounted() {
    this.routerPage = this.$route.query.pageType;
  },
  methods: {
    async onLoad() {
      this.loading = true;
      let params = {
        name: this.formObj.name,
        page: this.formObj.page,
        size: this.formObj.size,
      };
      const { code, data } = await appShopListApi.get_shopList(params);
      if (code == 200) {
        this.refreshing = false;
        this.loading = false;
        this.shopListData = this.shopListData.concat(data.list);
        this.formObj.total = data.total;
        if (this.shopListData.length >= this.formObj.total) {
          this.finished = true;
        } else {
          this.finished = false;
          this.formObj.page++;
        }
      }
    },
    onRefresh() {
      this.formObj.page = 1;
      this.formObj.total = 0;
      this.finished = false;
      this.loading = true;
      this.shopListData = [];
      this.onLoad();
    },

    getImgUrl(url) {
      return this.imgApi + url;
    },
    // 搜索
    searchChange(val) {
      this.formObj.name = val;
      this.onRefresh();
    },
  },
  components: { appHotCard, appHeader, searchCard, appCell1Card, appShopCard },
};
</script>

<style lang="scss" scoped>
.appClassList-page-warper {
  width: 100%;
  height: 100vh;
  font-family: "GrifaSlabRegular";

  ::v-deep .search-warper {
    background-color: #fff;
    width: 100%;
  }

  .card-tabs-body {
    width: 100%;
    height: calc(100% - 190px - 150px);

    height: 100%;

    .card-list-body {
      width: 100%;
      padding: 15px 0;
      height: calc(100%);
      overflow-y: scroll;
    }

    .van-list {
      background: #f7f7fb;
    }

    .card-list-boxers {
      width: 100%;
      padding: 0px 30px 30px 30px;
      flex-wrap: wrap;
      justify-content: space-between;
      background: #f7f7fb;

      .card-list-item {
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 0 15px 0px #eff0f1;
        border-radius: 18px;
        margin-bottom: 30px;
        overflow: hidden;

        .appShopClass-card {
          .appShop-img {
            width: 100%;
            height: 230px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img {
              max-width: 100%;
              object-fit: cover;
              height:100%;
            }
          }

          .appShop-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 30px;

            .appShop-info-left {
              display: flex;
              align-items: center;
              width: 70%;

              .info-logo {
                width: 110px;
                height: 110px;
                margin-right: 12px;
                border-radius: 16px;
                overflow: hidden;
                border: 1px solid #ccc;
                border-radius: 50%;
              }

              .info-name {
                width: calc(100% - 110px - 12px);
                font-size: 24px;
                font-weight: 600;

                .name {
                  width: 100%;
                  color: #262729;
                }

                .title {
                  margin-top: 20px;
                  color: #9399a2;
                }
              }

              .info-nav-stareLeave {
                margin-top: 2px;
                display: flex;
                align-items: center;

                .stare-leave {
                  cursor: pointer;
                  margin-right: 2px;

                  .svg-icon {
                    color: #dbdbdf;
                    cursor: pointer;
                    width: 24px !important;
                    height: 24px !important;
                  }
                }

                .star-active {
                  .svg-icon {
                    color: #ff2f2f !important;
                  }
                }
              }
            }

            .appShop-info-right {
              ::v-deep .van-rate {
                .van-rate__item {
                  .van-icon {
                    width: 12px !important;
                    height: 12px !important;
                    font-size: 26px !important;
                    margin-left: 6px;
                  }
                }
              }
            }
          }

          .appShop-options {
            display: flex;
            justify-content: flex-end;
            padding: 20px 30px;

            span {
              display: flex;
              align-items: center;
              height: 65px;
              padding: 0 16px;
              border-radius: 12px;
              background-color: #6d3580;
              color: #fff;
              font-size: 26px;
            }
          }
        }
      }
    }
  }
}
</style>
