// 西班牙语

let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "Hogar",
    },
    "searchLanguage": {
        searchBtn: 'Buscar',
        searchPlace: 'Buscar....'
    },
    'setDrawer': {
        label1: 'Configuración multilingüe',
        label2: 'Comerciante instalado',
        label3: 'Inicio de sesión de comerciante',
        label4: 'Servicio al cliente',
        label5: 'Descargar aplicación para Android',
        label6: 'Descargar aplicación ios',
        optionsBtn: 'Cancelar'
    },
    "appService": {
        navTitle: 'Servicio al cliente',
        iconInformation: 'Seleccione el software de comunicación para consulta',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
    },
    "appMerchantSettlement": {
        navTitle: 'Comerciante instalado',
        label1: 'Nombre del comerciante',
        label1Hint: 'El nombre del comerciante o la marca, una vez configurados, no se pueden modificar',
        visible1: 'Por favor ingrese el nombre del comerciante',

        label2: 'Buzón',
        label2Hint: 'La contraseña utilizada por el comerciante para iniciar sesión en el portal del comerciante. ',
        visible2: 'Por favor ingrese su correo electrónico',
        label3: 'Código de verificación de correo electrónico',
        label3Hint: 'Código necesario para confirmar la identidad del comerciante y evitar actividades maliciosas. Proporcione la información correcta del código de verificación',
        visible3: 'Por favor ingrese el código de verificación del correo electrónico',

        label4: 'Contraseña de comerciante',
        label4Hint: 'La contraseña utilizada por el comerciante para iniciar sesión en el portal del comerciante. ',
        visible4: 'Por favor ingrese la contraseña del comerciante',
        label5: 'código de invitación',
        visible5: 'Por favor ingrese el código de invitación',
        label5Hint: 'Ingrese el código de invitación del comerciante que lo invitó. ',

        label6: 'Guardar imagen',
        label6Hint: 'Imagen de la tienda del comerciante',
        visible6: 'Por favor ingrese el código de verificación',
        label7: 'Certificado de comerciante',
        label7Hint: 'Documentos de certificación del comerciante, como licencia de comerciante, certificado de registro fiscal, etc. Estos archivos deben ser archivos de imagen de alta calidad. ',
        visible7: 'Cargue imágenes de su licencia comercial y los certificados de calificación de la industria relevantes (puede cargar hasta 2 imágenes, cada imagen tiene un tamaño máximo de 5 MB y el formato de imagen admite Jpg y Png)',

        placeholderEnter: 'Por favor, introduzca',
        placeholderSelect: 'Por favor seleccione',
        sendEmail: 'Enviar',
        optionBtn1: 'Términos de servicio',
        optionBtn2: 'Protección del vendedor',
        optionBtn3: 'Enviar para revisión',
        uploadMessageError: 'Falló la carga de la imagen',
        UploadLayoutError: 'Error de formato de imagen',
        fileFail: 'Por favor cargue y formatee imágenes',
        popupTitle1: 'Términos de servicio',
        popupTitle2: 'Acuerdo de protección del vendedor'
    },
    'appSetLanguage': {
        navTitle: 'Configuración multilingüe',
        affirm: 'confirmar',
    },
    'classifyDrawer': {
        navTitle: 'Categoría'
    },
    'appHome': {
        recommendTitle: 'Recomendar',
        viewAll: 'ver todo',
        usedTitle: 'Usado',
        buyNow: 'Comprar Ahora',
        shopTitle: 'Comerciante',
        shopOption: 'Armario',
        hotTitle: 'Caliente',
        ranking: 'Ranking de ventas',
        preSaleTitle: 'Preventa',
        preSaleTabs1: 'En curso',
        preSaleTabs2: 'No iniciado',
        preSaleTabs3: 'Terminado',
        groupTitle: 'Grupo',
        peopleGroupBuying: 'la gente participó',
        flashSaleTitle: 'Flash',
        preSaleStateTimeS: 'inicio',
        preSaleStateTimeE: 'fin',
        showNow: 'Ir de compras ahora'
    },
    "appShopsListIndex": {
        navTitle: 'Lista de comerciantes',
        searchPlace: 'Buscar...',
        favoriteTitle: 'Cantidad favorita &',
        shopBtn: 'Ver armario',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'Categoría',
        viewAll: 'ver todo',
    },

    'appClassProductIndex': {
        navTitle: 'Producto',
        select1: 'Completo',
        select2: 'Cantidad de ventas',
        select3: 'Nuevo producto',
        select4: 'Precio de mayor a menor',
        select5: 'Precio de menor a mayor',
        select6: 'Calificación',
        paymentTitle: 'Pago',

    },
    'appPreSale': {
        navTitle: 'Preventa',
        tabs1: 'En curso',
        tabs2: 'No iniciado',
        tabs3: 'Terminado',
        selectAll: 'Todo',
        listLabel: 'Envío',
        listLabe2: 'día',
    },
    "appGroupBuying": {
        navTitle: 'Grupo',
        selectAll: 'Todo',
        listLabel: 'Necesidad',
        listLabe2: 'personas',
    },
    "appFlashSale": {
        navTitle: 'Flash',
        selectAll: 'Todo',
    },
    'appSalesRanking': {
        navTitle: 'Caliente',
        payment: 'pago',
        optionBtn1: 'Recoger',
        optionBtn2: 'Comprar ahora',
    },
    'appLogin': {
        navTitle: 'Iniciar sesión',
        navTitleHint: '¡Inicia sesión ahora y comienza a comerciar con amigos de todo el mundo! ',
        singUp: 'Registrarse',

        emailLabel: 'Número de teléfono móvil',
        emailPlaceholder: 'Ingrese su número de teléfono móvil',
        emailErrorText: '¡Mensaje de error! ',
        emailNullVerify: '¡Ingrese su número de teléfono móvil!',//Ingrese su dirección de correo electrónico
        emailErrorVerify: '¡Ingrese su número de teléfono móvil! ',//Error de formato de correo electrónico

        passwordLabel: 'su contraseña',
        passPlaceholder: 'Por favor ingrese la contraseña',
        passNullVerify: 'Ingrese su contraseña', //Ingrese su contraseña
        passErrorVerify: '¡La contraseña tiene menos de 6 caracteres! ',//La contraseña tiene menos de 6 caracteres

        placeholder: 'Por favor ingrese',

        emailRegistration: 'Registrarse ahora',
        forgetPassTitle: '¿Olvidaste tu contraseña? ',

        submitBtnTitle: 'Iniciar sesión',
        loginMessage: '¡La cuenta o la contraseña son incorrectas! '
    },
    'appForgotPass': {
        navTitle: 'Olvidé mi contraseña',
        navTitleHint: 'Ingrese su número de teléfono móvil y reciba el código de verificación para establecer una nueva contraseña. ',
        emailLabel: 'Número de teléfono móvil',
        emailPlaceholder: 'Ingrese su número de teléfono móvil',
        emailErrorText: '¡Mensaje de error!',//Por favor, introduzca su dirección de correo electrónico
        emailNullVerify: '¡Ingrese su número de teléfono móvil!',//Ingrese su dirección de correo electrónico
        emailErrorVerify: '¡Ingrese su número de teléfono móvil!',//Error de formato de correo electrónico
        verificationCode: 'código de verificación',
        verificationPlace: 'código de verificación',
        verificationCodeError: '¡Ingrese el código de verificación! ',
        sendTitle: 'Enviar código de verificación',
        sendText: 'Reverificar',
        passwordLabel: 'Nueva contraseña',
        passPlaceholder: 'Ingrese una nueva contraseña',
        confirmPassLabel: 'Confirmar nueva contraseña',
        confirmPlace: 'Confirmar nueva contraseña',
        passNullVerify: 'Ingrese su nueva contraseña', //Por favor ingrese su contraseña
        passErrorVerify: '¡La contraseña tiene menos de 6 caracteres! ',//La contraseña tiene menos de 6 caracteres
        passCompareErrorVerify: '¡Las contraseñas ingresadas dos veces son inconsistentes! ',
        passCompareErrorNull: 'Ingrese la nueva contraseña nuevamente',
        submitBtnTitle: 'Restablecer contraseña',
        loginMessage: '¡El código de verificación es incorrecto! '
        // emailRegistration: 'Registro por correo electrónico',
        // olvidarPassTitle: '¿Olvidaste tu contraseña?',

    },
    'appRegister': {
        navTitle: 'Registrarse',
        navTitleHint: '¡Crea una cuenta para acceder a todas las funciones! ',
        namePlace: 'Por favor ingrese',
        firstNameLabel: 'nombre',
        firstNamePlace: 'Por favor ingrese',
        firstNameErrorVerify: 'Por favor ingrese su nombre',
        lastNameLabel: 'apellido',
        lastNameErrorVerify: 'Por favor ingrese su apellido',
        nickNameLabel: 'apodo',
        nickNameErrorVerify: 'Por favor ingresa tu apodo',
        emailLabel: 'Número de teléfono móvil',
        emailPlaceholder: 'Ingrese su número de teléfono móvil',
        emailErrorText: '¡Mensaje de error!',//Por favor, introduzca su dirección de correo electrónico
        emailNullVerify: '¡Ingrese su número de teléfono móvil!',//Ingrese su dirección de correo electrónico
        emailErrorVerify: '¡Ingrese su número de teléfono móvil! ',//Error de formato de correo electrónico
        verificationCode: 'código de verificación',
        verificationPlace: 'Por favor ingrese el código de verificación',
        sendTitle: 'Enviar código de verificación',
        sendText: 'Cuenta regresiva',
        verificationCodeError: '¡Ingrese el código de verificación! ',
        passwordLabel: 'contraseña',
        passPlaceholder: 'Por favor ingrese la contraseña',
        confirmPassLabel: 'Confirmar contraseña',
        confirmPlace: 'Confirmar contraseña',
        passNullVerify: 'Ingrese su contraseña',//Por favor ingrese su contraseña
        passErrorVerify: '¡La contraseña tiene menos de 6 caracteres! ',//La contraseña tiene menos de 6 caracteres
        passCompareErrorVerify: '¡Las contraseñas ingresadas dos veces son inconsistentes! ',
        passCompareErrorNull: 'Ingrese la contraseña nuevamente',
        registerHint: '¿Ya tienes una cuenta? ',
        registerHintLogin: 'Iniciar sesión',
        submitBtnTitle: 'Registrarse',
        loginMessage: '¡El código de verificación es incorrecto! '

        // gender: 'Gender',
        // gender1: 'Boy',
        // gender2: 'Girl',
        // gender3: 'Secrecy',
        // genderError: 'Please enter your gender',
    },
    'appGoodsDetail': {
        salesTitle: 'Ventas',
        payHintTitle1: 'Saldo',
        payHintTitle2: 'PayPal',
        payHintTitle3: 'Visa',
        conditionLabel: 'Condición:',
        conditionContent: 'Métodos de pago admitidos por categoría de marca',
        frightLabel: 'Logística',
        frightContent: 'Entrega entre 1 y 3 días en promedio',
        agreement: 'Acuerdo de protección del comprador',
        specSelect: 'Por favor seleccione una especificación',
        specLabel: 'Especificación',
        specOptions: 'confirmar',
        countLabel: 'Cantidad',
        commentTitle: 'Comentario',
        deliverTime: 'Evaluar tiempo y días de entrega',
        salesLabel: 'Cantidad de ventas:',
        productCount: "Cantidad de producto:",
        collectLabel: 'Cantidad de colección:',
        averageLabel: 'Promedio de días:',
        since: 'Día del miembro',
        shipper: 'respuesta rápida',
        responder: 'Envío rápido',
        reliable: 'confiable',
        btnContact: 'Contacto',
        enter: 'armario',
        followBtn: 'Colección',
        guessLike: 'adivina que te gusta',
        buyNow: 'Comprar ahora',
        dialogOverviewTitle: 'Descripción general',
        overConditionLabel: 'Condición',
        overConditionContent: 'nuevo',
        overBrandLabel: 'Marca',
        overCategoryLabel: 'Categoría',
        overPayMethodsLabel1: 'Métodos de pago admitidos',
        overPayMethodsLabel2: 'Información importante',
        overCategoryEnter: 'Confirmar',
        dialogFreightTitle: 'Acuerdo de logística',
        dialogAgreementTitle: 'Acuerdo de protección del comprador',
        footerLeft: 'Agregar al carrito',
        footerRight: 'Comprar ahora',
        activeLabel1: 'Compra grupal',
        activeLabel2: 'Segunda venta',
        activeLabel3: 'Preventa',
        flashStateNameE: 'Fin',
        flashStateNameI: 'En curso',
        flashStateNameS: 'No iniciado',
        flashHintSName: 'Inicio',
        flashHintEName: 'Terminado',
        flashHintIName: 'Tiempo restante',
        preSaleStateTimeNO: 'No iniciado',
        preSaleStateTimeE: 'Fin',
        preSaleStateTimeS: 'En curso',
        preSaleHintNoName: 'Inicio',
        preSaleHintIEName: 'Finalizado',
        preSaleHintEName: 'Fin',
        groupHint: 'persona',
        reviewTitle: 'Revisión predeterminada del sistema'
    },
    "appShops": {
        select1: 'Completo',
        select2: 'Cantidad de ventas',
        select3: 'Nuevo producto',
        select4: 'Precio de mayor a menor',
        select5: 'Precio de menor a mayor',
        select6: 'Calificación',
        infoLabel1: 'Cantidad de ventas',
        infoLabel2: 'Cantidad de producto',
        infoLabel3: 'Cantidad de colección',
        infoLabel4: 'Evaluar tiempo de entrega',
        memberLabel1: 'Día del miembro',
        memberLabel2: 'Respuesta rápida',
        memberLabel3: 'Envío rápido',
        memberLabel4: 'confiable',
        commodity: 'mercancía',
        classAll: 'todos',

        // timeDay: 'Evaluate Delivery time & days',
        // productQuantity: 'Product Quantity',
        // shippedQuantity: 'Shipped Quantity',
        // collections: "Number of Collections",
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 商品评论页
    'appProductReviews': {
        navTitle: 'Comentarios',
        reviewTitle: 'Comentarios predeterminados del sistema',
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'Actualización de versión, ¿confirmar? ',
        loadingTitle: 'Descargando...',
        systemUpgrade: 'Actualización del sistema',
        upgradeImmediately: 'Confirmar',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "Cargando", // Cargando
        finishedText: "Ninguno", //Ninguno
        loadingText: "Cargando", // Cargando
        nullText: 'Aún no hay datos',
        loadingFinished: "Carga completa",
        listNullText: 'No más',
        pullIngText: 'Desplegar hacia abajo para actualizar',
        looseText: 'Liberar para actualizar',
        pullingSuccess: 'Carga desplegable exitosa',
        source: 'confirmar'
    },

};
export default obj;
