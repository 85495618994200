import service from '@/required/appAxios'
export default {
  // 获取全部分类数据 
  getClassIfy_List (form) {
    return service({
      url: '/category.get_tree',
      method: "get",
    })
  },
  // 获取flashTime
  get_flashTime (data) {
    return service({
      url: 'flash.get_time',
      method: "get",
      params: data
    })
  },
  // 秒杀活动 - 获取秒杀产品列表
  get_product_list (data) {
    return service({
      url: 'flash.get_product_list',
      method: "get",
      params: data
    })
  },
}