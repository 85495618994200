<template>
  <div class="dialog-condition-warper">
    <van-popup
      class="popup-dialog-warper"
      v-model="conditionIsShow"
      position="bottom"
      round
      @close="
        {
          (conditionIsShow = !conditionIsShow), $emit('conditionEnter');
        }
      "
    >
      <div class="condition-popup-body">
        <div class="popup-header">{{ language.dialogOverviewTitle }}</div>
        <div class="popup-card-body">
          <div class="popup-form-main">
            <div class="form-list-item">
              <span class="form-label">{{ language.overConditionLabel }}</span>
              <span>{{ language.overConditionContent }}</span>
            </div>
            <div class="form-list-item" v-if="conditionList.brand.name">
              <span class="form-label">{{ language.overBrandLabel }}</span>
              <span>{{ conditionList.brand.name }}</span>
            </div>
            <div class="form-list-item" v-if="conditionList.category">
              <span class="form-label">{{ language.overCategoryLabel }}</span>
              <span>{{ conditionList.category }}</span>
            </div>
          </div>
          <div class="pay-methods">
            <p class="title">{{ language.overPayMethodsLabel1 }}</p>
            <div class="pay-list-boxers flexbox">
              <div
                class="pay-list-item flexbox"
                v-for="(item, index) in conditionList.pay"
                :key="index"
              >
                <div class="imgFlex images">
                  <img v-lazy="getUrlImg(item.imgId)" />
                </div>
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
          <div class="pay-info">
            <p class="title">{{ language.overPayMethodsLabel2 }}</p>
            <div class="pay-info-hint text3">
              {{ conditionList.payHint }}
            </div>
          </div>
          <div
            class="condition-confirm-btn"
            @click="
              {
                (conditionIsShow = !conditionIsShow), $emit('conditionEnter');
              }
            "
          >
            <span>{{ language.overCategoryEnter }}</span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "appDetailConditionDialog",
  props: {
    isShow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    objList: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      language: this.$language(),
      conditionIsShow: false,
      conditionList: {},
    };
  },
  created() {
    this.conditionIsShow = this.isShow;
    this.conditionList = this.objList;
    console.log(this.conditionList, "this.conditionList");
  },
  mounted() {},
  methods: {
    getUrlImg(id) {
      return require(`../../../../assets/h5/payIcon${id}.png`);
    },
  },
  watch: {
    objList: {
      handler(newVal, oldVal) {
        console.log(newVal);
        this.conditionList = newVal;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-condition-warper {
  .popup-dialog-warper {
    max-height: 75%;

    .condition-popup-body {
      .popup-header {
        text-align: center;
        font-size: 32px;
        color: #1d1e20;
        padding: 30px;
        font-weight: 600;
        position: sticky;
        top: 0;
        background-color: #fff;
      }

      .popup-card-body {
        padding: 30px 42px;

        .popup-form-main {
          .form-list-item {
            height: 110px;
            border-bottom: 3px solid #a683b1;
            display: flex;
            align-items: center;
            padding: 0 10px;

            .form-label {
              display: inline-block;
              width: 30%;
              font-size: 24px;
              color: #1d1e20;
            }

            span:nth-child(2) {
              width: 70%;
              font-size: 24px;
              color: #979da5;
            }
          }
        }

        .pay-methods {
          padding-bottom: 40px;
          border-bottom: 3px solid #9268a0;

          .title {
            padding: 36px 0 25px 0;
            font-size: 26px;
            color: #1d1e20;
          }

          .pay-list-boxers {
            justify-content: space-around;

            .pay-list-item {
              width: 32%;
              padding: 15px 0;
              background-color: #f5f6fa;
              justify-content: center;
              border-radius: 8px;
              .images {
               
                height: 40px;
                margin-right: 12px;
              }
            }

            span {
              color: #a1a6ae;
              font-size: 20px;
            }
          }
        }

        .pay-info {
          .title {
            padding: 36px 0 25px 0;
            font-size: 26px;
            color: #1d1e20;
          }

          .pay-info-hint {
            color: #9a9fa7;
            font-size: 22px;
          }
        }

        .condition-confirm-btn {
          margin-top: 30px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 110px;
          background-color: #6d3580;
          border-radius: 10px;

          span {
            font-size: 34px;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
