import appIndex from "@/views/h5/app-index.vue"
import appHome from "@/views/h5/appHome/appHome-index.vue"
import appClassification from "@/views/h5/appClassification/appClassification-index.vue"
import appProductClass from "@/views/h5/appClassProduct/appClassProduct-index.vue"
// import appSearchHistory from "@/views/h5/appSearch-history/search-history.vue"
import appPreSale from "@/views/h5/appPreSale/app-preSale-index.vue"
import groupBuying from "@/views/h5/appGroupBuying/app-groupBuying.vue"
import flashSale from "@/views/h5/appFlashSale/app-flashSale.vue"
import salesRanking from "@/views/h5/appSalesRanking/app-salesRanking.vue"
import loginIndex from "@/views/h5/appLogin/app-login-index.vue"
import forgotPass from "@/views/h5/appForgotPass/app-forgot-pass.vue"
import registerPass from "@/views/h5/appRegister/app-register-index.vue"
import goodsDetail from "@/views/h5/appGoodsDetail/app-goods-detail.vue"
import reviewsDetail from "@/views/h5/appProductReviews/app-reviews-index.vue"
import shopsIndex from "@/views/h5/appShopsPage/app-shops-index.vue"
import shopsListIndex from "@/views/h5/appShopList/app-shop-list.vue"
import appSetLanguage from "@/views/h5/appSetLanguage/setLanguage-index.vue"
import appMerchantSettlement from "@/views/h5/appMerchantSettlement/appMerchant-settlement.vue"
const h5Routes = [
  {
    path: "/",
    name: "appIndex",
    redirect: "/appIndex",
  },

  {
    path: "/appIndex",
    name: "appIndex",
    component: appIndex,
    redirect: "/appHome",
    meta: { title: '首页', keepAlive: false, requiresMobile: true },
    children: [
      {
        path: "/appHome",
        name: "appHomePageIndex",
        component: appHome,
        meta: { title: '首页', keepAlive: true, requiresMobile: true },
      },
      {
        path: "/appClassification",
        name: "appClassificationIndex",
        component: appClassification,
        meta: { title: '分类', keepAlive: true, requiresMobile: true },
      },
      {
        path: "/appShopsListIndex",
        name: "appShopsListIndex",
        component: shopsListIndex,
        meta: { title: '店铺列表', keepAlive: false, requiresMobile: true },
      },
      {
        path: "/appClassProductIndex",
        name: "appClassProductIndex",
        component: appProductClass,
        meta: { title: '产品库', keepAlive: false, requiresMobile: true },
      },
      // {
      //   path: "/appSearchHistory",
      //   name: "appSearchHistoryIndex",
      //   component: appSearchHistory,
      //   meta: { title: '搜索', keepAlive: false, requiresMobile: true },
      // },

      {
        path: "/appPreSale",
        name: "appPreSaleIndex",
        component: appPreSale,
        meta: { title: '预售', requiresMobile: true },
      },
      {
        path: "/appGroupBuying",
        name: "appGroupBuyingIndex",
        component: groupBuying,
        meta: { title: '团购', requiresMobile: true },
      },
      {
        path: "/appFlashSale",
        name: "appFlashSalePage",
        component: flashSale,
        meta: { title: '限时抢购', requiresMobile: true },
      },
      {
        path: "/appSalesRanking",
        name: "appSalesRankingPage",
        component: salesRanking,
        meta: { title: '热销排名', requiresMobile: true },
      },
      {
        path: "/appLogin",
        name: "appLoginIndex",
        component: loginIndex,
        meta: { title: '登录页', keepAlive: false, requiresMobile: true },
      },
      {
        path: "/appForgotPass",
        name: "appForgotPassIndex",
        component: forgotPass,
        meta: { title: '忘记密码', keepAlive: false, requiresMobile: true },
      },
      {
        path: "/appRegister",
        name: "appRegisterIndex",
        component: registerPass,
        meta: { title: '注册', keepAlive: false, requiresMobile: true },
      },
      {
        path: "/appGoodsDetail",
        name: "appGoodsDetailIndex",
        component: goodsDetail,
        meta: { title: '商品详情', keepAlive: false, requiresMobile: true },
      },
      {
        path: "/appProductReviews",
        name: "appReviewsPageIndex",
        component: reviewsDetail,
        meta: { title: '产品评论', keepAlive: false, requiresMobile: true },
      },
      {
        path: "/appShops",
        name: "appShopsPage",
        component: shopsIndex,
        meta: { title: '店铺详情', keepAlive: false, requiresMobile: true },
      },
      {
        path: "/appSetLanguage",
        name: "appSetLanguage",
        component: appSetLanguage,
        meta: { title: '设置语言', keepAlive: false, requiresMobile: true },
      },
      {
        path: "/appMerchantSettlement",
        name: "appMerchantSettlement",
        component: appMerchantSettlement,
        meta: { title: '商家入驻', keepAlive: false, requiresMobile: true },
      },
    ]
  },


];
export default h5Routes