<template>
  <div class="setLanguage-page-warper">
    <appHeader class="language-header" :navTitle="languageObj.navTitle"></appHeader>
    <div class="setLanguage-page-conation">
      <div class="language-list">
        <div class="list-item flexbox" v-for="(item, index) in languageVersion" :key="index"
          @click="onChangeLanguage(item)">
          <div class="item-left flexbox">
            <div class="item-flag-icon imgFlex">
              <img :src="item.flagIcon" />
            </div>
            <span class="title">{{ item.state }}</span>
          </div>
          <van-icon name="success" v-if="item.optionType" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appHeader from "../component/app-header.vue";
export default {
  name: "appSetLanguageIndex",
  data () {
    return {
      languageObj: this.$language(),
      language: this.$language("languageVersion"),
      languageVersion: [],
      activeObj: {
        flagIcon: "",
        value: "",
      },
      countNum: 1,
    };
  },
  mounted () {
    this.languageVersion = [];
    this.onLoad();
  },
  methods: {
    onLoad () {
      for (let key of this.language) {
        let obj = {};
        obj[key] = key;
        obj["state"] = key;
        obj.optionType = false;
        if (key === "한국인") {
          obj["flagIcon"] = require(`../../../assets/h5/flag/Korean.png`);
        } else {
          obj["flagIcon"] = require(`../../../assets/h5/flag/${key}.png`);
        }
        this.languageVersion.push(obj);
      }
      this.contrastCallBack();
    },
    contrastCallBack () {
      const language = localStorage.getItem("languageType");
      this.languageVersion.map((item) => {
        item.optionType = false;
        if (item.state === language) {
          item.optionType = true;
          this.activeObj = {
            flagIcon: item.flagIcon,
            value: item.state,
          };
        }
      });
    },
    onChangeLanguage (record) {
      localStorage.setItem("languageType", record.state);
      this.contrastCallBack();
      let countNum = sessionStorage.getItem("_countNum")
      if (countNum) {
        countNum = Number(countNum)
      } else {
        countNum = 1
      }
      countNum += 1
      this.$resetSetItem("_countNum", "_countNum", countNum);
      setTimeout(() => {
        this.$router.go(-1);
      }, 800);
    },
  },
  components: { appHeader },
};
</script>

<style lang="scss" scoped>
.setLanguage-page-warper {
  width: 100%;
  height: 100vh;

  .setLanguage-page-conation {
    height: calc(100% - 110px);
    background: #fff;

    .language-list {
      height: 100%;
      overflow-y: scroll;

      .list-item {
        justify-content: space-between;
        padding: 44px 36px;
        border-bottom: 1px solid #e5e7ed;
        font-weight: 400;
        font-size: 36px;
        color: #000;

        .item-left {
          font-size: 26px;

          .item-flag-icon {
            width: 48px;
            height: 48px;
            margin-right: 24px;
          }
        }

        i {
          color: $blur;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
