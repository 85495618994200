import service from '@/required/appAxios'
export default {
  // 认证 - 店铺申请-合同
  getService (form) {
    return service({
      url: 'system.customer_service',
      method: "get",
      data: form,
      params: form
    })
  },
}