// 韩语

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '한국인', // 韩语
    '繁體中文', // 繁体中文
    // 'English',  // 英语
    // '日本語', // 日语
    // 'Português', // 葡萄牙语
    // '繁體中文', // 繁体中文
    // 'Français', // 法语
    // 'Deutsch', // 德语
    // 'Türk', // 土耳其语
    // 'Español', // 西班牙语
    // 'عربي', // 阿拉伯语
    // '한국인', // 韩语
    // 'Suomi', // 芬兰语
    // 'Русский', // 俄语
    // 'italiano', // 意大利语
    // 'แบบไทย', // 泰语
    // 'Nederlands', // 荷兰语
  ],
  'navigation': {
    home: "집",
    categories: "카테고리",
    hot: "핫 세일",
    uset: "사용됨",
    seckill: "세킬",
    presale: "사전 판매",
    group: "그룹 구매",
    login: '로그인',
    singUp: '등록',


  },
  'classifyDrawer': {
    navTitle: '범주'
  },
  'home': {
    showNow: '지금 쇼핑하러 가세요',
    viewMore: '더 보기',
    recommendTitle: '추천',
    merchantTitle: '판매자',
    preSaleTitle: '사전 판매',
    preSaleStarted: '시작',
    preSaleNotStarted: '시작되지 않음',
    preSaleEnded: '종료',
    secKillTitle: '두 번째 킬',
    groupBuyingTitle: '공동구매',
    groupBuyingViewAll: '더 보기',
    usedTitle: '사용됨',
    sales: '판매량',
    product: '제품',
    ranking: '순위',
    viewBtn: '지금 보기',
  },
  "shopList": {
    searchPlaceholder: '더 검색...',
    salesLabel1: '제품 수량',
    salesLabel2: '즐겨찾는 수량',
    salesLabel3: '판매 수량',

  },
  'downUploadLanguage': {
    downUpLoadTitle: '애플리케이션 다운로드',
    downUpLoadText: 'iPhone, iPad, Android에서 무료로 앱을 다운로드하세요',
    PhoneText: '앱 스토어',
    chromeText: 'GOOGLE PLAY',
  },
  'footerLanguage': {
    // 分类
    cateGoriesTitle: '범주',

    // 政策中心
    policyTitle: '정책 센터',
    policyTerms: '서비스 약관',
    policyPrivacy: '개인정보 보호정책',
    policyItems: '금지된 항목',
    policyConduct: '금지된 행동',
    policyElectronics: '전자 통신 정책',
    policyMinors: '미성년자',
    policyDMCA: 'DMCA/저작권 고지',
    policySMS: 'SMS 정책',
    policyRequests: '법 집행 기관의 요청',
    // 公司社区
    companyTitle: "회사",
    companyAboutUs: "회사 소개",
    companyHiring: "우리는 채용 중입니다",
    companyCommunity: "우리 커뮤니티",
    // 支持
    supportTitle: '지원',
    supportGuidelines: '시장 지침. ',
    supportSafety: '안전 가이드',
    supportLogistics: '물류 서비스',
    supportSeller: '판매자 보호',
    supportBuyer: '구매자 보호',
    supportAccessibility: '접근성',
    footerRightTitle: '앱에서 구매 및 판매',
    contactUs: '문의하기',
    setLanguage: '언어 설정',
    footerLogoCopyright: '©',
    ingTitle: ',inc'
  },
  'login': {
    navTitle: '로그인',
    navTitleHint: '계정이 없나요? ',
    singUp: '등록',
    mobileLabel: '귀하의 휴대전화',
    mobileNullVerify: '이메일을 입력하세요', //請輸入邮箱
    passwordLabel: '귀하의 비밀번호',
    passwordVerify: '비밀번호를 입력하세요', //請輸入密码
    placeholder: '입력하세요',
    forgetPass: '비밀번호를 잊으셨나요',//忘记密码
    loginBtnTitle: '로그인',
    forgetPassTitle: '비밀번호를 잊어버렸습니다',
    loginMessage: '휴대폰번호 또는 비밀번호가 틀렸습니다! ',
  },
  'shoppingCar': {
    navTitle: '장바구니',
    checkBoxTitle: '모두 선택',
    removeTitle: '삭제',
    selectPriceTitle1: '선택한 제품',
    selectPriceTitle2: '조각, 동등물',
    optionsBtn1: '결제'
  },
  "application": {
    navTitle: '상인 정착',
    label1: '업체 이름',
    label1Hint: '업체 이름이나 브랜드 이름은 한 번 설정하면 수정할 수 없습니다.',
    visible1: '상점 이름을 입력해주세요',
    label2: '이메일',
    label2Hint: '판매자가 판매자 포털에 로그인하는 데 사용하는 비밀번호입니다. 8자 이상의 안전한 비밀번호여야 합니다. ',
    visible2: '이메일을 입력해주세요',
    label3: '인증 코드',
    label3Hint: '판매자 신원을 확인하고 악의적인 활동을 방지하려면 코드가 필요합니다. 올바른 인증코드 정보를 입력해주세요',
    visible3: '인증번호를 입력해주세요',
    label4: '판매자 비밀번호',
    label4Hint: '판매자가 판매자 포털에 로그인하는 데 사용하는 비밀번호입니다. 8자 이상의 안전한 비밀번호여야 합니다. ',
    visible4: '판매자 비밀번호를 입력하세요',
    label5: '초대 코드',
    label5Hint: '당신을 초대한 판매자의 초대 코드를 입력하세요',
    label6: '이미지',
    label6Hint: '가맹점 이미지',
    visible6: '인증번호를 입력해주세요',
    label7: '신분증',
    label7Hint: "신분증 앞뒷면 사진 ",
    visible7: '사업자 등록증 및 관련 업계 자격 증명서 사진을 업로드해주세요. (최대 2장까지 업로드 가능하며, 각 사진의 최대 크기는 2m이며, 사진 형식은 Jpg, Png, Jpeg를 지원합니다.)',
    placeholderEnter: '입력하세요',
    placeholderSelect: '선택하세요',
    sendEmail: '보내기',
    optionBtn1: '서비스 약관',
    optionBtn2: '판매자 보호',
    optionBtn3: '검토를 위해 제출',
    uploadMessageError: '이미지 업로드 실패',
    UploadLayoutError: '이미지 형식 오류'
  },
  'forgetPass': {
    navTitle: '비밀번호를 잊어버렸습니다',
    navTitleHint: '계정이 없나요? ',
    loginIn: '로그인',
    emailLabel: '휴대폰번호',
    mobileNullVerify: '휴대폰 번호를 입력하세요', //請輸入邮箱
    verificationLabel: '인증 코드',
    verificationVerify: '인증코드를 입력하세요',//請輸入验证码
    confirmEmail: '확인 이메일',
    password1Label: '귀하의 비밀번호',
    password1Verify: '비밀번호를 입력하세요',//請輸入密码
    password2Label: '비밀번호 확인',
    password2Verify: '비밀번호를 다시 입력해주세요', //请再次输入密码
    passwordRepetitionVerify: '두 번 입력한 비밀번호가 일치하지 않습니다.', //请再次输入密码
    placeholder: '입력하세요',
    submitBtnTitle: '제출',
    forgetPassTitle: '비밀번호를 잊어버렸습니다',
    codeMessage: '인증 코드가 올바르지 않습니다!'
  },
  'singUpIndex': {
    navTitle: '등록',
    navTitleHint: '이미 계정이 있습니까? ',
    loginIn: '로그인',
    firstNameLabel: 'name', // 별명
    firstNameVerify: '이름을 입력하세요', //이름을 입력하세요
    lastNameLabel: '성', // 성
    lastNameVerify: '성명을 입력하세요',
    nicknameLabel: '닉네임',//이름
    nicknameVerify: '닉네임을 입력하세요',
    mobileLabel: '휴대폰번호',
    mobileNullVerify: '휴대폰 번호를 입력하세요', //휴대폰 번호를 입력하세요
    verificationLabel: '인증 코드',
    verificationVerify: '인증코드를 입력하세요',//인증코드를 입력해주세요
    confirmEmail: '확인 이메일',
    password1Label: '귀하의 비밀번호',
    password1Verify: '비밀번호를 입력하세요',//비밀번호를 입력하세요
    password2Label: '비밀번호 확인',
    password2Verify: '비밀번호를 다시 입력해주세요', //비밀번호를 다시 입력해주세요
    passwordRepetitionVerify: '두 번 입력한 비밀번호가 일치하지 않습니다.', //비밀번호를 다시 입력하세요.
    placeholder: '입력하세요',
    submitBtnTitle: '등록',
    codeMessage: '인증 코드가 올바르지 않습니다!'
  },
  'hot': {
    hotTitle: '판매 순위 상위 15위',
    product: '제품',
    sales: '판매량',
    ranking: '순위',
    viewBtn: '지금 보기',
    selectPlaceholder: '선택하세요',
    viewPayment: '결제'
  },
  'used': {
    classTitle: "카테고리",
    brandCardTitle: '브랜드',
    brandPlace: '브랜드 검색',
    searchPlaceholder: '더 검색...',
    recommendTitle: '추천',
    crumbsTitle: '기본값',
    select1: '종합',
    select2: '판매 수량',
    select3: '새 제품',
    select4: '높은 가격순',
    select5: '낮은 가격순',
    select6: '평가',
    SearchCriteria: '검색 기준:',
    clearAll: '모두 지우기',
    loading: '로드 중...',
    noMore: '더 이상'
  },
  'classProduct': {
    classTitle: "카테고리",
    brandCardTitle: '브랜드',
    brandPlace: '브랜드 검색',
    searchPlaceholder: '더 검색...',
    recommendTitle: '추천',
    crumbsTitle: '기본값',
    select1: '종합',
    select2: '판매 수량',
    select3: '새 제품',
    select4: '높은 가격순',
    select5: '낮은 가격순',
    select6: '평가',

    SearchCriteria: '검색 기준:',
    clearAll: '모두 지우기',
    loading: '로드 중...',
    noMore: "더 이상'"

  },

  'group': {
    defaultTitle: '기본값',
    selectTitle: '선택하세요',
    requires: '필요하다',
    people: '사람',
    classAll: '모두',
  },
  'presale': {
    defaultTitle: '기본값',
    selectTitle: '선택하세요',
    requires: '배송 요구 사항',
    people: '천',
    classAll: '모두',
    started: '시작',
    notStarted: '시작되지 않음',
    ended: '끝'
  },
  // 秒杀页面
  'seckill': {
    classAll: '모두',
    selectTitle: '선택 해주세요'
  },
  'productDetail': {
    likeMessage: '아직 로그인하지 않으셨습니다. 먼저 로그인해주세요',
    collectLabel: '좋아요',
    shareLabel: '공유',
    commentLabel: '댓글',
    defaultLabel: '기본값',
    numberLabel: '숫자',
    numberTotal: '모두:',
    buyNow: '지금 구매',
    addChart: '장바구니에 추가',
    brand: '브랜드:',
    paymentMethod: '결제 방법:',
    deliveryInformation: '모든 주문은 빠른 배송(1~3일)',
    payLabel1: '잔액',
    payLabel2: '페이팔',
    payLabel3: '비자',
    policyLabel: '안전',
    policyTitle: '구매자 보호 정책',
    policyInfo: '주문을 받거나 환불을 받으려면 지침을 따르십시오.',
    policyMore: '자세히 알아보기',
    aboutSellersTitle: '판매자 정보',
    contactMerchants: '판매자에게 연락하기',
    viewClosets: '옷장 보기',
    sales: '판매량',
    product: '제품 수량',
    care: '수집 수량',
    average: '평균 일수',
    memberSince: '멤버 데이',
    fastResponder: '빠른 응답자',
    fastShipper: '빠른 배송업체',
    reliable: "신뢰할 수 있는",
    guessTitle: '좋아하는 것 같아요',
    activeLabel1: '공동구매',
    activeLabel2: '두 번째 판매',
    activeLabel3: '사전 판매',
    presaleHint: '결제 성공 후 % 이내 배송',
    timeDays: '일',
    activeState1Time1: '종료',
    activeState1Time2: '이동',
    activeState1Time3: '시작',
    activeTimeHint1: '끝났습니다',
    activeTimeHint2: '남은 시간',
    activeTimeHint3: '시작 시간은',
    activityPresellState: '종료',
    activityPresellHint: '사람',
    activeStart: '시작',
    activeEnd: '끝',
  },
  "merchant": {
    follow: '팔로우',
    loginMessage: '아직 로그인하지 않았습니다. 먼저 로그인하세요! ',
    searchPlaceholder: '제품 검색',
    salesProductTitle: '실제 판매된 제품 수',
    shippedQuantity: "총 제품 수",
    collections: "컬렉션 수",
    evaluate: "배송 시간 평가",
    category: '카테고리',
    brandCardTitle: '브랜드',
    brandPlace: '입력하세요',
    memberSince: '멤버 데이',
    responder: '빠른 응답자',
    shipper: '빠른 배송업체',
    reliable: '신뢰할 수 있는',
    brandPlace: '브랜드 검색',
    productTitle: '제품',
    select1: '포괄적',
    select2: '판매 수량',
    select3: '새 제품',
    select4: '높은 가격순',
    select5: '낮은 가격순',
    select6: '평가',
    clearAll: '모두 지우기',
    searchCriteria: '검색 기준:'
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: '버전 업그레이드, 확인하시겠습니까? ',
    loadingTitle: '다운로드 중...',
    systemUpgrade: '시스템 업그레이드',
    upgradeImmediately: '확인',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "로드 중", // 로드 중
    finishedText: "없음", //없음
    loadingText: "로드 중", // 로드 중
    nullText: '아직 데이터 없음'
  },
};
export default obj;
