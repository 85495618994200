<template>
  <div class="app-component-header-warper flexbox">
    <div class="svg flexbox" v-if="isShow">
      <svg-icon icon-class="app-header-back" @click="$router.go('-1')"></svg-icon>
    </div>
    <span v-if="navTitle">{{ navTitle }}</span>
    <div class="nav-right" v-if="rightIcon">
      <slot name="navRight"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "appHeaderPage",
  props: {
    navTitle: {
      type: String,
      default: () => {
        return "";
      },
    },
    isShow: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    rightIcon: {
      type: Boolean,
      default: () => {
        return false;
      },
    }
  },
  data () {
    return {
      language: this.$language(),
    };
  },
  mounted () { },
  methods: {},
};
</script>

<style lang="scss" scoped>
.app-component-header-warper {
  font-family: "GrifaSlabRegular";
  justify-content: center;
  width: 100%;
  height: 110px;
  background-color: #fff;
  position: sticky;
  top: 0;
  font-size: 38px;
  font-weight: 600;
  color: $appSizeColor;
  z-index: 2;

  // position: relative;
  .svg {
    position: absolute;
    left: 20px;
    top: 15px;
    background-color: #f5f6fa;
    width: 75px;
    height: 75px;
    border-radius: 18px;
    justify-content: center;

    svg {
      width: 40px !important;
      height: 40px !important;
      color: #232426;
    }
  }

  .nav-right {
    position: absolute;
    right: 20px;
    top: 15px;
    background-color: #f5f6fa;
    width: 75px;
    height: 75px;
    border-radius: 18px;
    justify-content: center;

    svg {
      width: 40px !important;
      height: 40px !important;
      color: #232426;
    }
  }
}
</style>
