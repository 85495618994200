<template>
  <div class="recommend-card-warper">
    <div class="recommend-header flexbox">
      <div class="header-left flexbox">
        <svg-icon icon-class="app-collect" class="appHomeSvg"></svg-icon>
        <span class="title-left">{{ language.recommendTitle }}</span>
      </div>
      <div class="header-right title-right" @click="clickClassRow()">
        {{ language.viewAll }}
      </div>
    </div>
    <div class="recommend-list-boxers">
      <van-tabs
        v-if="tabsListData.length"
        v-model="recommendActive"
        class="recommend-tabs"
        ref="tab2"
        swipe-threshold="3"
        @change="onChangeTabs"
      >
        <van-tab
          v-for="(item, index) in tabsListData"
          :key="index"
          :title="item.name"
        >
        </van-tab>
      </van-tabs>
      <div class="recommend-list-data">
        <div class="tabs-list-boxers">
          <van-swipe
            class="list-item-swipe"
            indicator-color="#6d3580"
            @change="onChangeSwipe"
            v-if="recommendListData.length"
          >
            <van-swipe-item
              v-for="(item, lenIndex) in countLen"
              :key="lenIndex"
            >
              <div
                class="tabs-list-item"
                v-for="(item, index) in setList(lenIndex)"
                :key="index"
                @click="
                  $router.push({
                    path: '/appGoodsDetail',
                    query: { id: item.id },
                  })
                "
              >
                <div class="list-img imgFlex">
                  <img v-lazy="getUrl(item.image)" />
                </div>
                <div class="list-name">
                  <p class="text2">{{ item.name }}</p>
                </div>
                <div class="list-price">
                  <span>{{ moneyIcon }} {{ item.unitAmount }}</span>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
          <defaultPage v-else></defaultPage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/components/component-h5/css/index.css";
import imgApi from "@/utils/projectConfig.js";
export default {
  name: "appHomeRecommendCard",
  props: {
    recommendList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tabsList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      language: this.$language(),
      imgApi: imgApi.imgUrl,
      thumbnail: this.$thumbnail,
      recommendListData: [],
      recommendActive: 0,
      tabsListData: [],
      tabsIsShow: true,
      countLen: 1,
      current: 0,
    };
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    clickClassRow(record) {
      this.$router.push({
        path: "/appClassProductIndex",
      });
    },
    onChangeSwipe(e) {
      this.current = e;
    },
    onChangeTabs(e) { 
      this.$emit("onChangeTabsClass", e);
    },
    onLoad() {
      this.tabsListData = this.tabsList;
      this.recommendListData = this.recommendList;
      this.countLen = Math.ceil(this.recommendListData.length / 4);
    },
    getUrl(url) {
      return this.imgApi + url;
    },
    setList(index) {
      if (index === 0) {
        return this.recommendListData.slice(0, 4);
      } else {
        let count = index * 4;
        return this.recommendListData.slice(count, count + 4);
      }
    },
  },
  watch: {
    recommendList: {
      handler(newVal, oldVal) { 
        this.recommendListData = [...newVal];
        this.current = 0;
        this.countLen = 1;
        setTimeout(() => {
          this.countLen = Math.ceil(this.recommendListData.length / 4);
        });
      },
      deep: true,
    },
    tabsList: {
      handler(newVal, oldVal) {
        this.tabsListData = [...newVal];
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.recommend-card-warper {
  margin-top: 15px;

  .recommend-header {
    height: 110px;
    padding: 0px 36px;
    justify-content: space-between;
    background-color: #fff;
    .header-right {
      cursor: pointer;
    }
  }

  .recommend-list-boxers {
    .recommend-tabs {
      ::v-deep .van-tabs__wrap {
        .van-tabs__nav {
          .van-tab {
            .van-tab__text {
              font-size: 28px;
              color: #9ba0a8;
            }
          }

          .van-tab--active {
            .van-tab__text {
              color: #6d3580;
            }
          }
        }

        .van-tabs__line {
          background-color: #6d3580;
        }
      }

      .van-tabs__content {
        .van-tab__pane {
          overflow-x: hidden;
          background-image: linear-gradient(
            rgb(255, 255, 255) -20%,
            #f5f5f5 50%
          );
        }
      }
    }

    .recommend-list-data {
      width: 100%;
      overflow-x: scroll;

      .tabs-list-boxers {
        .list-item-swipe {
          ::v-deep .van-swipe__track {
            background-color: #fff;
            width: 100% !important;
          }

          ::v-deep .van-swipe__indicators {
            bottom: 15px;
            .van-swipe__indicator {
              width: 40px;
              height: 6px;
              border-radius: 68px;
              background: #bcbcbc;
            }

            .swipe__indicator--active {
              color: $blur;
            }
          }

          .van-swipe-item {
            width: 100% !important;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            background-color: #fff;
            padding: 20px;
            border-radius: 18px;

            .tabs-list-item {
              width: calc((100% / 2) - 30px);
              margin-right: 30px;
              margin-bottom: 30px;

              .list-img {
                width: 100%;
                height: 240px;
                background: #fff;
                border-radius: 18px;
              }

              .list-name {
                margin-top: 30px;
                font-size: 24px;
                color: #1d1e20;
              }

              .list-price {
                margin-top: 30px;
                color: #1d1e20;
                font-size: 24px;
              }
            }

            .tabs-list-item:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>
