import { loadingNode, loadingClear } from '@/layout/loading-custom/loadingjs'
import axios from 'axios'
import Vue from 'vue'
import router from 'vue-router'
import baseUrl from '@/utils/projectConfig'
import { app_languageList } from "@/utils/language/h5-language/language";
import { Dialog } from 'vant';
import { Toast } from 'vant'
Vue.use(router)
let messageLoading, dialogLanguage
const ServerRequestUrl = baseUrl.baseUrl
// 创建axios实例
const service = axios.create({
  baseURL: ServerRequestUrl,
  timeout: 5000 * 2 // 仅为这个请求设置超时时间为5秒
})
getLanguage()
window.addEventListener("_countNum", (val) => { getLanguage() });

// request拦截器==>对请求参数做处理
let loadingCount = 0
service.interceptors.request.use(config => {
  loadingCount += 1
  loadingNode(2500, messageLoading + '...')
  config.headers.Authorization = window.localStorage.getItem('authentication');
  config.headers['Accept-Language'] = baseUrl.lang[window.localStorage.getItem('languageType')]
  return config
}, error => {
  Promise.reject(error)
})
service.interceptors.response.use(response => {
  loadingCount--
  if (response.data.code === 200) {
    if (loadingCount == 0) {
      loadingClear(1200)
    }

  } else if (response.data.code == 1002) {
    Toast.fail({
      message: response.data.message,
      forbidClick: true,
    })


  } else if (response.data.code == 500) {
    dialogCall(response.data.message)
  } else {
    if (loadingCount == 0) {
      loadingClear(0)
      Toast.clear()
    }
    Toast.fail({
      message: response.data.message,
      forbidClick: true,
    })
  }
  return response.data
},
  err => {
    loadingCount--
    if (loadingCount == 0) {
      loadingClear(0)
      Toast.clear()
    }
    const text = JSON.parse(JSON.stringify(err))
    let errText
    if (text.code === 'ECONNABORTED') {
      errText = "The request has timed out. Click Refresh to try again!"
    } else {
      errText = "Service abnormality, click refresh to try again!"
    }
    dialogCall(errText)
    return Promise.reject(err)
  }
)
function dialogCall (message) {

  Dialog.confirm({
    className: 'axios-dialog-class',
    message: message,
    showCancelButton: false,
    confirmButtonText: dialogLanguage,
    beforeClose
  })
}
function beforeClose (action, done) {
  if (action === 'confirm') {
    setTimeout(() => {
      location.reload();
    }, 1000)
  } else {
    done();
  }
}
function getLanguage () {
  Vue.nextTick(() => {
    messageLoading = app_languageList('messageLanguage').loadingTitle
    dialogLanguage = app_languageList('messageLanguage').sure
  })
}
export default service