<template>
  <div
    class="appGoods-detail-warper"
    id="goodsDetailId"
    @scroll="handlerScroll"
  >
    <div class="goods-detail-header flexbox">
      <span class="flexbox" @click="$router.go(-1)">
        <svg-icon icon-class="app-header-back"></svg-icon>
      </span>
      <span class="flexbox" @click="onClickClass">
        <svg-icon :icon-class="headerRightSvg"></svg-icon>
      </span>
      <div class="header-svg-list" v-if="headerRightSvgIsShow">
        <p
          v-for="(item, index) in classSvgList"
          :key="index"
          @click="$router.push('/appLogin')"
        >
          <span class="flexbox">
            <svg-icon :icon-class="item"></svg-icon>
          </span>
        </p>
      </div>
    </div>
    <div class="goods-detail-switch">
      <van-swipe
        class="my-swipe"
        :autoplay="3000"
        :loop="true"
        indicator-color="#6d3580"
        v-if="skuImageList"
      >
        <van-swipe-item v-for="(item, index) in skuImageList">
          <div class="switch-img imgFlex">
            <img v-lazy="getImgUrl(item.imageUrl)" />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="goods-detail-introduce-body">
      <div class="goods-detail-price flexbox">
        <p>
          <span v-if="productDataList.unitAmount"
            >{{ moneyIcon }}{{ productDataList.unitAmount }}</span
          >
          <del v-if="productDataList.originalAmount"
            >{{ moneyIcon }}{{ productDataList.originalAmount }}</del
          >
        </p>
      </div>
      <div class="goods-introduce-name text3">
        {{ productInfObj.name }}
      </div>

      <div class="goods-introduce-special" v-if="activityIsShow">
        <div class="special-card">
          <div class="special-left">
            <img :src="getSpecialUrl('presale')" />
            <div class="special-text">
              <p class="title" v-if="activityTitle">{{ activityTitle }}</p>
              <div class="special-info">
                <span v-if="activityState">{{ activityState }}</span>
                <template v-if="routerParams.sourcePage === 'seckill'">
                  <span v-if="activityTime">{{ activityTime }}</span>
                  <span v-if="activityTime">{{ activityHint }}</span>
                </template>
                <template v-else>
                  <span>{{ activityTime }}</span>
                  <span>{{ activityHint }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="special-right" v-if="sourceImgType">
            <img :src="getUrlImage(sourceImgType)" />
          </div>
        </div>
      </div>
      <div class="goods-introduce-sales flexbox" v-if="productDataList.sales">
        {{ language.salesTitle }} {{ productDataList.sales }}+
      </div>

      <div class="goods-introduce-info flexbox" v-if="productInfObj.highlights">
        <P class="text3">{{ productInfObj.highlights }}</P>
      </div>
      <!-- 商品购买服务 -->
      <div class="goods-buy-serve-boxers">
        <div class="buy-serve-list" @click="buyServeClick('condition')">
          <div class="buy-serve-left">
            <svg-icon icon-class="app-goodsDetail-class"></svg-icon>
            {{ language.conditionLabel }}{{ language.conditionContent }}
          </div>
          <div class="buy-serve-right">
            <svg-icon icon-class="app-arrow"></svg-icon>
          </div>
        </div>
        <div class="buy-serve-list freight" @click="buyServeClick('freight')">
          <div class="buy-serve-left">
            <svg-icon icon-class="app-goodsDetail-class"></svg-icon>
            <p class="flexbox">
              <span class="label"> {{ language.frightLabel }}</span>
              <span class="title">{{ language.frightContent }}</span>
            </p>
          </div>
          <div class="buy-serve-right">
            <svg-icon icon-class="app-arrow"></svg-icon>
          </div>
        </div>
        <div
          class="buy-serve-list agreement"
          @click="buyServeClick('agreement')"
        >
          <div class="buy-serve-left">
            <svg-icon icon-class="app-goodsDetail-class"></svg-icon>
            <span class="label">{{ language.agreement }}</span>
          </div>
          <div class="buy-serve-right">
            <svg-icon icon-class="app-arrow"></svg-icon>
          </div>
        </div>
        <div class="buy-serve-list spec-list" @click="buyServeClick('spec')">
          <div class="buy-serve-left">
            <svg-icon icon-class="app-goodsDetail-class"></svg-icon>
            <div class="buy-serve-specImg">
              <div class="specImg-list imgFlex" v-if="skuImageList.length">
                <img v-lazy="getImgUrl(skuImageList[0].imageUrl + thumbnail)" />
              </div>
            </div>
            <span class="title">{{ language.specSelect }}</span>
          </div>
          <div class="buy-serve-right">
            <svg-icon icon-class="app-arrow"></svg-icon>
          </div>
        </div>
      </div>
      <commentCard
        :commentObj="commentInfoObj"
        v-if="commentInfoObj.length"
      ></commentCard>
      <deliver-card
        :objList="shopInfoObj"
        v-if="Object.keys(shopInfoObj).length"
      ></deliver-card>
      <div class="goods-detail-html">
        <div
          class="html-text text-warper"
          v-html="productDataList.description"
        ></div>
        <div class="html-images-boxers">
          <div
            class="html-image-item imgFlex"
            v-for="(item, index) in skuImageList"
            :key="index"
          >
            <img v-lazy="getImgUrl(item.imageUrl)" />
          </div>
        </div>
      </div>
    </div>
    <!-- 推荐喜欢 -->
    <guessLikeCard
      v-if="recommendList.length"
      :listData="recommendList"
      @hotDetailCheck="hotDetailCheck"
    >
    </guessLikeCard>
    <div class="goods-detail-footer">
      <div class="option-left options" @click="$router.push('/appLogin')">
        {{ language.footerLeft }}
      </div>
      <div class="option-right options" @click="$router.push('/appLogin')">
        {{ language.footerRight }}
      </div>
    </div>
    <conditionDialog
      v-if="conditionIsShow"
      :isShow="conditionIsShow"
      :objList="conditionObj"
      @conditionEnter="conditionIsShow = false"
    ></conditionDialog>
    <detailDialog
      v-if="visibleIsShow"
      :isShow="visibleIsShow"
      :navTitle="dialogTitle"
      :htmlData="htmlTemplate"
      @dialogClose="visibleIsShow = false"
    ></detailDialog>
    <specificationDialog
      v-if="specificationIsShow"
      :visible="specificationIsShow"
      :dataObj="specification"
      @dialogClose="specificationIsShow = false"
      @onChangeSpec="onChangeSpec"
    />
  </div>
</template>
<script>
import commentCard from "./comment/goods-comment-card.vue";
import deliverCard from "./comment/good-deliver-card.vue";
import guessLikeCard from "./comment/goods-guessLike-card.vue";
import conditionDialog from "./comment/detail-condition-dialog.vue";
import detailDialog from "./comment/detail-dialog.vue";
import appGoodsDetailApi from "@/api/h5Api/appGoodsDetailApi";
import imgApi from "@/utils/projectConfig.js";
import specificationDialog from "./comment/spec-dialog.vue";
import { timeZone } from "@/utils/timeZone";
import moment from "moment-timezone";
export default {
  name: "appGoodsDetailIndex",
  data() {
    return {
      imgApi: imgApi.imgUrl,
      thumbnail: this.$thumbnail,
      commentInfoObj: [],
      deliverObj: {},
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      language: this.$language(),

      recommendList: [],
      headerRightSvg: "app-detail-class",
      headerRightSvgIsShow: false,
      classSvgList: [],
      conditionIsShow: false,
      visibleIsShow: false,
      dialogTitle: "",
      htmlTemplate: "",
      shopInfoObj: {},
      productDataList: {},
      conditionObj: {},
      productInfObj: {},
      skuImageList: [],

      productBrandObj: {},
      productImagesObj: {},
      productShopObj: {},
      specificationIsShow: false,
      specification: {},
      checkSpace: [],
      routerParams: {},
      detailType: "default",
      activityObj: {},
      sourceImgType: "",
      activityTitle: "",
      activityState: "",
      activityHint: "",
      activityTime: "",
      activityIsShow: false,
      timeZone: timeZone,
      presaleHint: "",
      activityIntervalTime: "",
      buyHtml1: "",
      buyHtml2: "",
    };
  },

  mounted() {
    this.routerParams = this.$route.query;
    this.onLoad();
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handlerScroll);
    });
    this.getGoodsDetailPort();
    this.getCommentInfoData();
  },
  methods: {
    getGoodsDetailPort() {
      let params = {
        activityType: this.detailType,
        idByActivityType: this.routerParams.id,
      };
      let port;
      if (this.routerParams.sourcePage === "shopDetail") {
        params = {
          shopId: this.routerParams.shopId,
          shopProductId: this.routerParams.id,
        };
        port = appGoodsDetailApi.getShop_detail(params);
      } else {
        port = appGoodsDetailApi.getDetailPort(params);
      }
      port.then((res) => {
        const { code, data } = res;
        if (code == 200) {
          this.activityObj = data.activity;

          if (data.product && Object.keys(data.product).length) {
            let productItem = data.product;
            productItem.unitAmount = (productItem.unitAmount / 100).toFixed(2);
            productItem.originalAmount = (
              productItem.originalAmount / 100
            ).toFixed(2);
            productItem.imageIcon = "";

            if (productItem.image) {
              productItem.imageIcon = this.imgApi + productItem.image;
            }
            if (productItem.colorImage) {
              productItem.colorImage.map((colorItem) => {
                colorItem.imageIcon = this.imgApi + colorItem.image;
              });
            }
            if (productItem.sku) {
              productItem.sku.map((skuItem) => {
                skuItem.unitAmount = (skuItem.unitAmount / 100).toFixed(2);
                skuItem.originalAmount = (skuItem.originalAmount / 100).toFixed(
                  2
                );
                skuItem.totalPrice = skuItem.unitAmount;
              });
            }
            data.shop.imageIcon = "";
            data.shop.backgroundImage = "";
            if (data.shop.backgroundImage) {
              data.shop.backgroundImage =
                this.imgApi + data.shop.backgroundImage;
            }
            if (data.shop.image) {
              data.shop.imageIcon = this.imgApi + data.shop.image;
            }
            let time = moment().tz(this.timeZone);
            data.shop.createAt = moment()
              .tz(this.timeZone)
              .format("YYYY-MM-DD", time)
              .valueOf();
            data.product.attributes &&
              data.product.attributes.map((item, index) => {
                item.attributesValue.map((record, recordIndex) => {
                  record.isShow = false;
                  if (recordIndex == 0) {
                    record.isShow = true;
                  }
                });
              });

            this.shopInfoObj = data.shop;

            this.skuImageList = productItem.sku
              ? productItem.sku[0].skuImage
              : "";
            let categoryList = [];
            data.product.category.map((item) => {
              categoryList.unshift(item.name);
            });
            const payList = [
              { imgId: "1", name: this.language.payHintTitle1 },
              { imgId: "2", name: this.language.payHintTitle2 },
              { imgId: "3", name: this.language.payHintTitle3 },
            ];
            this.conditionObj = {
              brand: data.product.brand,
              category: categoryList.join("/"),
              pay: payList,
              payHint: data.product.highlights,
            };

            this.productDataList = {
              unitAmount: data.product.unitAmount,
              originalAmount: data.product.originalAmount,
              sales: data.product.sales,
              description: data.product.description,
            };
            this.specification = data.product;
            this.specification.count = 1;
            this.specification.specImg = this.skuImageList[0].imageUrl;
            this.specification.stock = data.product.sku[0].stock;
            this.specification.attributes.map((item, index) => {
              item.activeIndex = 0;
              item.attributesValue.map((record, recordIndex) => {
                if (recordIndex === item.activeIndex) {
                  this.checkSpace.push(record.id);
                }
              });
            });
            this.productInfObj = data.product;

            this.getRecommendData(data.product.categoryId, data.shop.id);
            if (Object.keys(this.activityObj).length) {
              this.activeCallBack();
            }
          }
        }
      });
    },
    onChangeSpec(checkSpace) {
      console.log(checkSpace);
      if (checkSpace.length) {
        let checkSpaceStr = checkSpace.join("|");
        let index = this.productInfObj.skuSortByAttributesValue[checkSpaceStr];
        this.skuImageList = this.productInfObj.sku[index].skuImage;
        this.productDataList.unitAmount =
          this.productInfObj.sku[index].unitAmount;
        this.productDataList.originalAmount =
          this.productInfObj.sku[index].originalAmount;
        this.productDataList.sales = this.productInfObj.sku[index].sales;
        this.specification.specImg = this.skuImageList[0].imageUrl;
        this.specification.stock = this.productInfObj.sku[index].stock;
        this.specification.count = 1;
        this.$nextTick(() => {
          document.getElementById("goodsDetailId").scrollTop = 0;
        });
      }

      this.specificationIsShow = false;
    },
    // 猜你喜欢查看详情
    hotDetailCheck(record) {
      this.detailType = "default";
      this.routerParams = { id: record.id };
      // this.$router.push({ path: "/appGoodsDetail", query: { id: record.id } });
      this.getGoodsDetailPort();
      this.getCommentInfoData();
      this.$nextTick(() => {
        document.getElementById("goodsDetailId").scrollTop = 0;
      });
    },

    buyServeClick(key) {
      if (key === "condition") {
        this.conditionIsShow = true;
      }
      if (key === "freight") {
        this.dialogTitle = this.language.dialogFreightTitle;
        this.getDialog_HTML(key, "ProductDetailsBuyerProtectionLearnMore");
      }
      if (key === "agreement") {
        this.dialogTitle = this.language.dialogAgreementTitle;
        this.getDialog_HTML(key, "ProductDetailsBuyerProtection");
      }
      if (key === "spec") {
        this.specificationIsShow = true;
      }
    },
    onLoad() {
      this.classSvgList = ["app-heart", "app-share", "app-comment"];
      this.detailType = "default";
      this.activityIsShow = false;
      if (this.routerParams.sourcePage === "group") {
        this.detailType = "group";
        this.sourceImgType = "group_icon";
        this.activityTitle = this.language.activeLabel1;
        this.activityHint = this.language.groupHint;
      }
      if (this.routerParams.sourcePage === "seckill") {
        this.detailType = "flash";
        this.sourceImgType = "seckill_icon";
        this.activityTitle = this.language.activeLabel2;
      }
      if (this.routerParams.sourcePage === "presale") {
        this.detailType = "presale";
        this.sourceImgType = "presale_icon";
        this.activityTitle = this.language.activeLabel3;
      }
      let enumType = ["group", "flash", "presale"];
      this.activityIsShow = enumType.includes(this.detailType);
    },
    activeCallBack() {
      if (this.routerParams.sourcePage === "group") {
        this.activityState = this.activityObj.peopleNumber;
      }
      const { startTime, endTime, startTimeString, endTimeString } =
        this.activityObj;
      const {
        flashHintSName,
        flashStateNameE,
        flashHintEName,
        flashStateNameI,
        flashStateNameS,
        flashHintIName,
        preSaleStateTimeS,
        preSaleStateTimeE,
        preSaleStateTimeNO,
        preSaleHintNoName,
        preSaleHintIEName,
        preSaleHintEName,
      } = this.language;
      if (this.routerParams.sourcePage === "seckill") {
        let time = moment().tz(this.timeZone).valueOf();
        if (startTime * 1000 > time) {
          this.activityState = flashStateNameS;
          this.activityHint = flashHintSName;
          this.activityTime = startTimeString;
        } else if (endTime * 1000 < time) {
          this.activityState = flashStateNameE;
          this.activityHint = flashHintEName;
          this.activityTime = endTimeString;
        } else if (startTime * 1000 < time < endTime * 1000) {
          this.activityState = flashStateNameI;
          this.activityHint = flashHintIName;
          clearInterval(this.activityIntervalTime);
          let timeCount = endTime * 1000;
          this.activityCountDown(timeCount - time);
        }
      }
      if (this.routerParams.sourcePage === "presale") {
        this.activityHint = "";
        let time = moment().tz(this.timeZone).valueOf();
        if (startTime * 1000 > time) {
          this.activityState = preSaleStateTimeNO;
          if (endTimeString)
            this.activityHint = endTimeString + " " + preSaleHintNoName;
        } else if (endTime * 1000 < time) {
          this.activityState = preSaleStateTimeE;
          if (endTimeString)
            this.activityHint = endTimeString + " " + preSaleHintIEName;
        } else if (startTime * 1000 < time < endTime * 1000) {
          this.activityState = preSaleStateTimeS;
          if (endTimeString)
            this.activityHint = endTimeString + " " + preSaleHintEName;
        }
      }
    },
    activityCountDown(duration) {
      let durationTem;
      if (duration > 0) {
        this.activityIntervalTime = setInterval(() => {
          durationTem = !durationTem ? duration : durationTem - 1000;
          if (durationTem <= 0) {
            this.activityTime = "";
            clearInterval(this.activityIntervalTime);
          }
          this.$nextTick(() => {
            if (!this.countDownCallBack(durationTem) && durationTem <= 0) {
              const { activity } = this.productDataList;
              this.activityState = this.language.activeState1Time1;
              this.activityHint = this.language.activeTimeHint1;
              this.activityTime = activity.endTimeString;
            } else {
              const { hours, minutes, seconds } =
                this.countDownCallBack(durationTem);
              this.activityTime = `${hours}:${minutes}:${seconds}`;
            }
          });
        }, 1000);
      }
    },
    countDownCallBack(timer) {
      let newTime = moment().tz(this.timeZone).valueOf(); //获取当前时间
      let stopeTimes = newTime + timer;
      let mistiming = stopeTimes - newTime; //   获取截止时间距离现在的毫秒差
      let hours = Math.floor((mistiming / 1000 / 60 / 60) % 24); // 获取小时
      let minutes = Math.floor((mistiming / 1000 / 60) % 60); //获取分钟数
      let seconds = Math.floor((mistiming / 1000) % 60); //获取秒数
      hours < 10 ? (hours = "0" + hours) : hours;
      minutes < 10 ? (minutes = "0" + minutes) : minutes;
      seconds < 10 ? (seconds = "0" + seconds) : seconds;
      let rels = `${hours}:${minutes}:${seconds}`;

      // 判断时间差是不是正数，就是截止时间是不是比现在的时间晚
      let mis = mistiming > 0 ? rels : "";
      if (mis) {
        return { hours, minutes, seconds };
      } else {
        return "";
      }
      // return mis;
    },
    onClickClass() {
      this.headerRightSvgIsShow = !this.headerRightSvgIsShow;
      if (this.headerRightSvgIsShow) {
        this.headerRightSvg = "app-close";
      } else {
        this.headerRightSvg = "app-detail-class";
      }
    },
    // 评论数据
    getCommentInfoData() {
      this.commentInfoObj = [];
      const params = {
        page: 1,
        size: 4,
        productId: this.routerParams.id,
      };
      appGoodsDetailApi.get_commentInfoPort(params).then(({ code, data }) => {
        if (code === 200) {
          data.list.map((item) => {
            item.avatarIcon = "";
            if (item.avatar) {
              item.avatarIcon = this.imgApi + item.avatar + this.thumbnail;
            }
            if (!item.comment) {
              item.comment = this.language.reviewTitle;
            }
            item.createAt = item.createAt?.split(" ")[0];
          });

          this.commentInfoObj = data.list;
        }
      });
    },
    // 推荐数据
    getRecommendData(categoryId, shopId) {
      const params = {
        page: 1,
        size: 20,
        categoryId: categoryId,
      };
      appGoodsDetailApi.get_recommendList(params).then((res) => {
        const { code, data } = res;
        if (code == 200) {
          data.list.map((item) => {
            item.unitAmount = (item.unitAmount / 100).toFixed(2);
          });
          this.recommendList = [];
          this.recommendList = data.list;
        }
      });
    },
    // 对话框文本
    getDialog_HTML(key, content) {
      if (key == "freight" && this.buyHtml1) {
        this.htmlTemplate = this.buyHtml1;
        this.visibleIsShow = true;
        return false;
      }
      if (key == "agreement" && this.buyHtml2) {
        this.htmlTemplate = this.buyHtml2;
        this.visibleIsShow = true;
        return false;
      }
      const params = {
        contentType: content,
      };
      appGoodsDetailApi.getHTML_content(params).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          this.visibleIsShow = true;
          this.htmlTemplate = data.content;
          if (key == "freight") {
            this.buyHtml1 = data.content;
          }
          if (key == "agreement") {
            this.buyHtml2 = data.content;
          }
        }
      });
    },
    getImgUrl(url) {
      return this.imgApi + url;
    },
    getSpecialUrl(url) {
      return require(`../../../assets/h5/${url}Icon.png`);
    },
    getUrlImage(url) {
      return require(`../../../assets/h5/${url}.png`);
    },
    handlerScroll(e) {},
  },
  components: {
    commentCard,
    deliverCard,
    guessLikeCard,
    conditionDialog,
    detailDialog,
    specificationDialog,
  },
};
</script>

<style lang="scss" scoped>
.appGoods-detail-warper {
  width: 100%;
  height: 100%;
  font-family: "GrifaSlabRegular";
  background: #f7f7fb;
  overflow-y: scroll;

  .goods-detail-header {
    position: fixed;
    top: 50px;
    width: 100%;
    justify-content: space-between;
    padding: 0 30px;
    z-index: 99;

    span {
      background-color: rgba(190, 183, 189, 0.2);
      border-radius: 18px;
      justify-content: center;
      width: 86px;
      height: 86px;

      svg {
        width: 38px !important;
        height: 38px !important;
        color: #6d3580;
      }
    }

    span:nth-child(2) {
      position: relative;
    }

    .header-svg-list {
      position: absolute;
      right: 35px;
      top: 116px;

      p {
        margin-bottom: 24px;

        svg {
          width: 46px !important;
          height: 46px !important;
        }
      }
    }
  }

  .goods-detail-switch {
    width: 100%;
    height: 45%;
    background: #fff;

    .my-swipe {
      height: 100% !important;

      .van-swipe__track {
        height: 100% !important;

        .van-swipe-item {
          height: 100% !important;

          .switch-img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    ::v-deep .van-swipe__indicator {
      width: 52px !important;
      background: #9e9e9e !important;
      border-radius: 10px;
      height: 6px;
    }

    ::v-deep .van-swipe__indicator--active {
      background-color: #6d3580 !important;
    }
  }

  .goods-detail-introduce-body {
    margin-top: 15px;
    padding: 0 36px 30px 36px;
    background: #fff;

    .goods-detail-price {
      height: 96px;

      p {
        span {
          color: #ff2f2f;
          font-size: 34px;
          font-weight: 600;
          margin-right: 12px;
        }

        del {
          font-size: 28px;
          color: #90969f;
          vertical-align: bottom;
          line-height: 28px;
        }
      }
    }

    .goods-introduce-name {
      color: #1d1e20;
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 30px;
    }

    .goods-introduce-presale {
      margin-bottom: 20px;
      justify-content: space-between;
      border-radius: 12px;
      overflow: hidden;
      border: 1px solid #6d3580;

      .boxers {
        width: 50%;
        padding: 15px 30px;

        p {
          font-size: 24px;
          color: #fff;
          line-height: 1.5;
          text-align: center;
        }

        p:nth-child(2) {
          margin-top: 6px;
        }
      }

      .presale-left {
        p {
          color: #6d3580;
        }
      }

      .presale-right {
        background: #6d3580;
      }
    }

    .goods-introduce-type {
      margin-bottom: 20px;

      span {
        font-size: 28px;
        color: #fff;
        background: #6d3580;
        border-radius: 12px;
        padding: 6px 12px;
      }
    }

    .goods-introduce-sales {
      margin-bottom: 20px;
      color: #9aa0a8;
      font-size: 22px;
    }

    .goods-introduce-special {
      background-color: #f5f6fa;
      border-radius: 18px;
      background: linear-gradient(
        to right,
        rgb(120, 31, 205),
        rgb(178, 36, 192)
      );
      padding: 30px;
      margin-bottom: 20px;

      .special-card {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .special-left {
          display: flex;
          align-items: center;
          justify-content: space-between;

          img {
            width: 68px;
            height: 68px;
          }

          .special-text {
            padding-left: 30px;

            .title {
              font-size: 24px;
              color: #fff;
              line-height: 1.5;
            }

            .special-info {
              margin-top: 12px;
              color: #fff;
              display: flex;
              align-items: center;
              font-size: 22px;

              span:nth-child(1) {
                padding: 4px 12px;
                border-radius: 6px;
                border: 1px solid #fff;
              }

              span:nth-child(2),
              span:nth-child(3) {
                margin-left: 12px;
                font-size: 22px;
                line-height: 1.5;
              }

              span:nth-child(3) {
                margin-left: 4px;
              }
            }
          }
        }

        .special-right {
          width: 88px;
          height: 88px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .goods-introduce-info {
      background-color: #f5f6fa;
      height: 155px;
      font-size: 24px;
      color: #90969f;
      padding: 0 22px;
      border-radius: 18px;
      font-weight: 500;

      P {
        line-height: 32px;
      }
    }

    .goods-buy-serve-boxers {
      background-color: #f5f6fa;
      padding: 36px 30px;
      margin-top: 40px;
      border-radius: 18px;

      .buy-serve-list {
        margin-bottom: 68px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #1d1e20;
        font-size: 22px;
        font-weight: 600;

        .buy-serve-left {
          max-width: 94%;
          display: flex;
          align-items: center;

          svg {
            width: 40px !important;
            height: 40px !important;
            color: #747477;
            margin-right: 12px;
          }

          .title {
            color: #8f959e;
          }
        }

        .buy-serve-right {
          display: flex;
          align-items: center;

          svg {
            width: 28px !important;
            height: 28px !important;
            color: #37383b;
          }
        }
      }

      .freight {
        .label {
          margin-right: 4px;
        }
      }

      .spec-list {
        .buy-serve-specImg {
          display: flex;
          align-items: center;
          margin-right: 8px;

          .specImg-list {
            display: flex;
            align-items: center;
            width: 60px;
            height: 60px;
            border-radius: 16px;
            overflow: hidden;
            margin-right: 8px;
          }
        }

        .title {
          color: #1d1e20 !important;
        }
      }

      .buy-serve-list:last-child {
        margin-bottom: 0;
      }
    }
  }

  .goods-detail-html {
    padding: 0;
    margin-top: 40px;

    .html-text {
      ::v-deep div {
        padding: 0 !important;

        p {
          font-size: 24px;
          color: #1d1e20 !important;
        }
      }
    }
  }

  .goodsDetail-guess-like {
    margin-top: 15px;
    padding: 30px 0;
    margin-bottom: 180px;
    background-color: #fff;
  }

  .goods-detail-footer {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 150px;
    background-color: #fff;
    box-shadow: 0 0 15px 0 #f5f6fa;

    .options {
      width: 40%;
      height: 82px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      font-weight: 600;
      border-radius: 18px;
      border: 4px solid #6d3580;
    }

    .option-left {
      color: #6d3580;
    }

    .option-right {
      color: #fff;
      background-color: #6d3580;
    }
  }
}
</style>
