// 日语

let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "家",
    },
    "searchLanguage": {
        searchBtn: '検索',
        searchPlace: '検索....'
    },
    'setDrawer': {
        label1: '多言語設定',
        label2: '加盟店の入店',
        label3: '加盟店ログイン',
        label4: 'カスタマーサービス',
        label5: 'Android アプリケーションのダウンロード',
        label6: 'iOS アプリケーションのダウンロード',
        optionsBtn: 'キャンセル'
    },
    "appService": {
        navTitle: 'カスタマーサービス',
        iconInformation: 'ご相談には通信ソフトウェアを選択してください',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
    },
    "appMerchantSettlement": {
        navTitle: '加盟店が登録されました',
        label1: '加盟店名',
        label1Hint: '加盟店名またはブランド名は,一度設定すると変更できません',
        visible1: '加盟店名を入力してください',
        label2: 'メールボックス',
        label2Hint: '加盟店が加盟店ポータルにログインするために使用するパスワード。',
        visible2: 'メールアドレスを入力してください',
        label3: 'メールアドレス確認コード',
        label3Hint: '加盟店の身元を確認し,悪意のあるアクティビティを防止するために必要なコードです。正しい確認コード情報を入力してください',
        visible3: 'メールアドレス確認コードを入力してください',
        label4: '加盟店パスワード',
        label4Hint: '加盟店が加盟店ポータルにログインするために使用するパスワード。 ',
        visible4: '販売者のパスワードを入力してください',
        label5: '招待コード',
        visible5: '招待コードを入力してください',
        label5Hint: '招待した販売者の招待コードを入力してください。',

        label6: 'ストア画像',
        label6Hint: '販売者のストア画像',
        visible6: '認証コードを入力してください',
        label7: '販売者の証明書',
        label7Hint: '販売者の証明書類（販売者ライセンス,税務登録証明書など）。これらのファイルは高品質の画像ファイルである必要があります。 ',
        visible7: '販売者ライセンスと関連する業界資格証明書の写真をアップロードしてください (最大 2 枚の写真をアップロードできます。各写真の最大サイズは 5MB で,写真の形式は Jpg と PNG に対応しています)',

        placeholderEnter: '入力してください',
        placeholderSelect: '選択してください',
        sendEmail: '送信',
        optionBtn1: '利用規約',
        optionBtn2: '販売者保護',
        optionBtn3: '審査のために送信',
        uploadMessageError: '画像のアップロードに失敗しました',
        UploadLayoutError: '画像形式エラー',
        fileFail: '写真をアップロードしてフォーマットしてください',
        popupTitle1: '利用規約',
        popupTitle2: '販売者保護契約'
    },
    'appSetLanguage': {
        navTitle: '多言語設定',
        affirm: '確認',
    },
    'classifyDrawer': {
        navTitle: 'カテゴリー'
    },
    'appHome': {
        recommendTitle: 'おすすめ',
        viewAll: 'すべて表示',
        usedTitle: '中古',
        buyNow: '今すぐ購入',
        shopTitle: '販売店',
        shopOption: 'クローゼット',
        hotTitle: '人気',
        ranking: '販売ランキング',
        preSaleTitle: '先行販売',
        preSaleTabs1: '進行中',
        preSaleTabs2: '開始されていません',
        preSaleTabs3: '終了',
        groupTitle: 'グループ',
        peopleGroupBuying: '参加した人',
        flashSaleTitle: 'フラッシュ',
        preSaleStateTimeS: '開始',
        preSaleStateTimeE: '終了',
        showNow: '今すぐ買い物'
    },
    "appShopsListIndex": {
        navTitle: '販売店リスト',
        searchPlace: '検索...',
        favoriteTitle: 'お気に入り数量 &',
        shopBtn: 'クローゼットを表示',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'カテゴリ',
        viewAll: 'すべて表示',
    },

    'appClassProductIndex': {
        navTitle: '製品',
        select1: '総合',
        select2: '販売数量',
        select3: '新製品',
        select4: '価格の高い順',
        select5: '価格の低い順',
        select6: '評価',
        paymentTitle: '支払い',

    },
    'appPreSale': {
        navTitle: '先行販売',
        tabs1: '進行中',
        tabs2: '開始されていません',
        tabs3: '終了',
        selectAll: 'すべて',
        listLabel: '発送中',
        listLabe2: '日',
    },
    "appGroupBuying": {
        navTitle: 'グループ',
        selectAll: 'すべて',
        listLabel: '必要',
        listLabe2: '人',
    },
    "appFlashSale": {
        navTitle: 'Flash',
        selectAll: 'すべて',
    },
    'appSalesRanking': {
        navTitle: 'Hot',
        payment: 'payment',
        optionBtn1: 'Collect',
        optionBtn2: 'Buy Now',
    },
    'appLogin': {
        navTitle: 'ログイン',
        navTitleHint: '今すぐログインして,世界中の友達と取引を始めましょう! ',
        singUp: '登録',

        emailLabel: '携帯電話番号',
        emailPlaceholder: '携帯電話番号を入力してください',
        emailErrorText: 'エラー メッセージ! ',
        emailNullVerify: '携帯電話番号を入力してください! ',//メールアドレスを入力してください
        emailErrorVerify: '携帯電話番号を入力してください! ',//メール形式エラー

        passwordLabel: 'パスワード',
        passPlaceholder: 'パスワードを入力してください',
        passNullVerify: 'パスワードを入力してください',//パスワードを入力してください
        passErrorVerify: 'パスワードは 6 文字未満です! ',//パスワードは 6 文字未満です

        placeholder: '入力してください',

        emailRegistration: '今すぐ登録',
        forgetPassTitle: 'パスワードを忘れましたか? ',

        submitBtnTitle: 'ログイン',
        loginMessage: 'アカウントまたはパスワードが間違っています! '
    },
    'appForgotPass': {
        navTitle: 'パスワードを忘れました',
        navTitleHint: '携帯電話番号を入力して確認コードを受け取り,新しいパスワードを設定してください。',
        emailLabel: '携帯電話番号',
        emailPlaceholder: '携帯電話番号を入力してください',
        emailErrorText: 'エラー メッセージ!',//メール アドレスを入力してください
        emailNullVerify: '携帯電話番号を入力してください!',//メール アドレスを入力してください
        emailErrorVerify: '携帯電話番号を入力してください!',//メール フォーマット エラー
        verificationCode: '確認コード',
        verificationPlace: '確認コード',
        verificationCodeError: '確認コードを入力してください! ',
        sendTitle: '認証コードを送信',
        sendText: '再確認',
        passwordLabel: '新しいパスワード',
        passPlaceholder: '新しいパスワードを入力してください',
        confirmPassLabel: '新しいパスワードを確認',
        confirmPlace: '新しいパスワードを確認',
        passNullVerify: '新しいパスワードを入力してください', //パスワードを入力してください
        passErrorVerify: 'パスワードが6文字未満です! ',//パスワードが6文字未満です
        passCompareErrorVerify: '2回入力したパスワードが一致していません! ',
        passCompareErrorNull: '新しいパスワードをもう一度入力してください',
        submitBtnTitle: 'パスワードをリセット',
        loginMessage: '認証コードが間違っています! '
        // emailRegistration: 'メール登録',
        // forgetPassTitle: 'パスワードを忘れましたか?',

    },
    'appRegister': {
        navTitle: '登録',
        navTitleHint: 'すべての機能にアクセスするにはアカウントを作成してください! ',
        namePlace: '入力してください',
        firstNameLabel: '名前',
        firstNamePlace: '入力してください',
        firstNameErrorVerify: '名前を入力してください',
        lastNameLabel: '姓',
        lastNameErrorVerify: '姓を入力してください',
        nickNameLabel: 'ニックネーム',
        nickNameErrorVerify: 'ニックネームを入力してください',
        emailLabel: '携帯電話番号',
        emailPlaceholder: '携帯電話番号を入力してください',
        emailErrorText: 'エラー メッセージ!',//メール アドレスを入力してください
        emailNullVerify: '携帯電話番号を入力してください!',//メール アドレスを入力してください
        emailErrorVerify: '携帯電話番号を入力してください! ',//メール形式エラー
        verificationCode: '認証コード',
        verificationPlace: '認証コードを入力してください',
        sendTitle: '認証コードを送信',
        sendText: 'カウントダウン',
        verificationCodeError: '認証コードを入力してください! ',
        passwordLabel: 'パスワード',
        passPlaceholder: 'パスワードを入力してください',
        confirmPassLabel: 'パスワードを確認',
        confirmPlace: 'パスワードを確認',
        passNullVerify: 'パスワードを入力してください',//パスワードを入力してください
        passErrorVerify: 'パスワードが6文字未満です! ',//パスワードが6文字未満です
        passCompareErrorVerify: '2回入力したパスワードが矛盾しています! ',
        passCompareErrorNull: 'パスワードをもう一度入力してください',
        registerHint: 'すでにアカウントをお持ちですか? ',
        registerHintLogin: 'ログイン',
        submitBtnTitle: '登録',
        loginMessage: '認証コードが間違っています! '

        // gender: 'Gender',
        // gender1: 'Boy',
        // gender2: 'Girl',
        // gender3: 'Secrecy',
        // genderError: 'Please enter your gender',
    },
    'appGoodsDetail': {
        salesTitle: '販売',
        payHintTitle1: '残高',
        payHintTitle2: 'PayPal',
        payHintTitle3: 'Visa',
        conditionLabel: '条件:',
        conditionContent: 'ブランド-カテゴリ-サポートされている支払い方法',
        frightLabel: '物流',
        frightContent: '平均1～3日以内に配送',
        agreement: '購入者保護契約',
        specSelect: '仕様を選択してください',
        specLabel: '仕様',
        specOptions: '確認',
        countLabel: '数量',
        commentTitle: 'コメント',
        deliverTime: '配送時間と日数を評価',
        salesLabel: '販売数量:',
        productCount: "製品数量:",
        collectLabel: '集荷数量:',
        averageLabel: '平均日数:',
        since: '会員の日',
        shipper: '迅速な対応',
        responder: '迅速な発送',
        reliable: '信頼できる',
        btnContact: 'お問い合わせ',
        enter: 'クローゼット',
        followBtn: 'コレクション',
        guessLike: 'お好きだと思います',
        buyNow: '今すぐ購入',
        dialogOverviewTitle: '概要',
        overConditionLabel: '状態',
        overConditionContent: '新着',
        overBrandLabel: 'ブランド',
        overCategoryLabel: 'カテゴリ',
        overPayMethodsLabel1: 'サポートされている支払い方法',
        overPayMethodsLabel2: '重要な情報',
        overCategoryEnter: '確認',
        dialogFreightTitle: '物流契約',
        dialogAgreementTitle: '購入者保護契約',
        footerLeft: 'カートに追加',
        footerRight: '今すぐ購入',
        activeLabel1: 'グループ購入',
        activeLabel2: 'セカンドセール',
        activeLabel3: '先行販売',
        flashStateNameE: '終了',
        flashStateNameI: '進行中',
        flashStateNameS: '開始されていません',
        flashHintSName: '開始',
        flashHintEName: '終了',
        flashHintIName: '残り時間',
        preSaleStateTimeNO: '開始されていません',
        preSaleStateTimeE: '終了',
        preSaleStateTimeS: '進行中',
        preSaleHintNoName: '開始',
        preSaleHintIEName: '終了',
        preSaleHintEName: '終了',
        groupHint: '人',
        reviewTitle: 'システムデフォルトレビュー'
    },
    "appShops": {
        select1: '包括的',
        select2: '販売数量',
        select3: '新製品',
        select4: '価格の高い順',
        select5: '価格の低い順',
        select6: '評価',
        infoLabel1: '販売数量',
        infoLabel2: '製品数量',
        infoLabel3: '集荷数量',
        infoLabel4: '配達時間の評価',
        memberLabel1: '会員デー',
        memberLabel2: '迅速な対応',
        memberLabel3: '迅速な発送',
        memberLabel4: '信頼性',
        commodity: '商品',
        classAll: 'すべて',

        // timeDay: 'Evaluate Delivery time & days',
        // productQuantity: 'Product Quantity',
        // shippedQuantity: 'Shipped Quantity',
        // collections: "Number of Collections",
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 商品评论页
    'appProductReviews': {
        navTitle: 'コメント',
        reviewTitle: 'システムのデフォルトコメント',
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'バージョンアップグレード,確認してください?',
        loadingTitle: 'ダウンロード中...',
        systemUpgrade: 'システムアップグレード',
        upgradeImmediately: '確認',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "読み込み中", // 読み込み中
        finishedText: "なし", // なし
        loadingText: "読み込み中", // 読み込み中
        nullText: 'まだデータがありません',
        loadingFinished: "読み込みが完了しました",
        listNullText: 'これ以上はありません',
        pullIngText: 'プルダウンして更新',
        looseText: 'リリースして更新',
        pullingSuccess: 'プルダウンの読み込みに成功しました',
        source: '確認'
    },

};
export default obj;
