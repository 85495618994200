<template>
  <div class="appHome-warper">
    <div class="appHome-header">
      <searchCard
        :disabled="true"
        @searchClick="
          $router.push({
            path: '/appClassProductIndex',
          })
        "
      ></searchCard>
      <div class="header-svg" @click="drawerIsShow = true">
        <svg-icon icon-class="app-home-class"></svg-icon>
      </div>
    </div>
    <div class="appHome-body">
      <!--  -->
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in switchDataList" :key="index">
          <div class="switch-img" @click="onClickSwitch(item)">
            <img v-lazy="item.image" />
            <!-- <div class="switch-button" v-if="item.jumpUrl !== 'null'">
              {{ language.showNow }}
            </div> -->
          </div>
        </van-swipe-item>
      </van-swipe>
      <!-- <classCard :classList="classificationList"></classCard> -->
      <recommendCard
        :tabsList="classificationList"
        :recommendList="recommendDataList"
        @onChangeTabsClass="onChangeTabsClass"
      ></recommendCard>
      <usedCard
        :tabsList="classificationList"
        :usedList="usedDataList"
        @onChangeTabsUsed="onChangeTabsUsed"
      ></usedCard>
      <shopCard :shopList="shopDataList"></shopCard>
      <hotCard :hotList="hotDataList"></hotCard>
      <preSaleCard
        v-if="classificationList.length"
        :classId="classificationList[0].id"
      >
      </preSaleCard>
      <groupPurchaseCard
        v-if="classificationList.length"
        :tabsList="classificationList"
      />
      <flashSaleCard></flashSaleCard>
      <div
        class="scroll-top-boxers flexbox"
        v-if="scrollToIsShow"
        @click="onClickScrollTop"
      >
        <svg-icon icon-class="app-back-top"></svg-icon>
      </div>
    </div>
    <drawer-card
      v-if="drawerIsShow"
      :visibleIsShow="drawerIsShow"
      @handlerDrawerClose="drawerIsShow = false"
      @drawerItem="drawerClickItem"
    ></drawer-card>
    <appServiceDialog
      v-if="serviceVisible"
      :visible="serviceVisible"
      :serviceObj="serviceData"
      @handlerCloseService="serviceVisible = false"
    >
    </appServiceDialog>
  </div>
</template>

<script>
import appHomeApi from "@/api/h5Api/appHomeApi";
import imgApi from "@/utils/projectConfig.js";
import classCard from "./component/class-card.vue";
import recommendCard from "./component/recommend-card.vue";
import usedCard from "./component/used-card.vue";
import shopCard from "./component/shop-card.vue";
import hotCard from "./component/hot-card.vue";
import preSaleCard from "./component/preSale-card.vue";
import groupPurchaseCard from "./component/groupPurchase-card.vue";
import flashSaleCard from "./component/flashSale-card.vue";
import drawerCard from "./component/drawer-card.vue";
import searchCard from "../component/app-search.vue";
import appServiceDialog from "@/views/h5/appService/appService-index.vue";
import serviceApi from "@/api/h5Api/service";
export default {
  name: "appHomePageIndex",
  data() {
    return {
      drawerIsShow: false,
      serviceVisible: false,
      language: this.$language(),
      imgApi: imgApi.imgUrl,
      switchDataList: [],
      count: 0,
      isLoading: false,
      searchValue: "",
      recommendDataList: [], //推荐
      usedDataList: [],
      shopDataList: [],
      hotDataList: [],
      scrollToIsShow: false,
      classificationList: [],
      activeClassIndex: 0,
      svgColor: "",
      countNumber: 0,
      _countNum: 1,
      classId: "",
      serviceData: {},
    };
  },
  activated() {
    if (this.$route.path === "/appHome") {
      this.language = this.$language();
      this.$forceUpdate();
    }
    if (this.$route.path === "/appHome" && !this.countNumber) {
      this.onLoad();
    }
  },
  mounted() {
    window.addEventListener("_countNum", (val) => {
      this._countNum += 1;
    });
    if (this.$route.path === "/appHome" && !this.countNumber) {
      this.onLoad();
      this.countNumber = 1;
    }
  },
  methods: {
    onClickSwitch(record) {
      if (record.jumpUrl == "group") {
        this.$router.push({ path: "/appGroupBuying" });
      }
      if (record.jumpUrl == "hot") {
        this.$router.push({ path: "/appSalesRanking" });
      }
      if (record.jumpUrl == "used") {
        this.$router.push({ path: "/appClassProductIndex" });
      }
      if (record.jumpUrl == "flash") {
        this.$router.push({ path: "/appFlashSale" });
      }
      if (record.jumpUrl == "presale") {
        this.$router.push({ path: "/appPreSale" });
      }
      if (record.jumpUrl == "merchantSettlement") {
        this.$router.push({ path: "/appMerchantSettlement" });
      }
    },
    drawerClickItem(key) {
      if (key === "service") {
        this.drawerIsShow = false;
        this.onChangeService();
      }
    },
    async onChangeService() {
      const { code, data, message } = await serviceApi.getService({
        code: localStorage.getItem("code") ?? "",
      });
      if (code == 200) {
        this.serviceData = data;
        this.serviceVisible = true;
      }
    },
    onClickScrollTop() {
      document.getElementById("pullRefresh").scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    onLoad() {
      this.getHomeDataList();
      this.get_classTree();
      this.getHotData();
    },
    // 首页数据获取
    async getHomeDataList() {
      const { code, data } = await appHomeApi.getHomeList();
      if (code == 200) {
        data.carouselPicture.map((item) => {
          item.image = this.imgApi + item.image;
        });
        this.switchDataList = data.carouselPicture;
        data.category.map((item) => {
          item.list.map((record) => {
            record.child = [];
          });
        });
        data.shop.map((item) => {
          item.image = this.imgApi + item.image;
          item.backgroundImage = this.imgApi + item.backgroundImage;
        });
        this.shopDataList = data.shop;
      }
    },
    // 获取分类数据
    async get_classTree() {
      this.recommendIsShow = false;
      let { code, data } = await appHomeApi.getClassIfy_List();
      if (code === 200) {
        this.classificationList = data;
        this.activeClassIndex = data[0].id;
        this.classId = data[0].id;
        this.getRecommendData();
        this.getUsedData(data[0].id);
      }
    },
    // 推荐tag切换
    onChangeTabsClass(key) {
      this.activeClassIndex = null;
      this.activeClassIndex = this.classificationList[key].id;
      this.getRecommendData();
    },
    onChangeTabsUsed(key) {
      let id = this.classificationList[key].id;
      this.getUsedData(id);
    },
    // 推荐数据获取
    async getRecommendData() {
      let dataObj = {
        categoryId: this.activeClassIndex,
        page: 1,
        size: 20,
      };

      const { code, data } = await appHomeApi.get_recommend(dataObj);
      if (code == 200 && data.list) {
        data.list.map((item) => {
          item.unitAmount = (item.unitAmount / 100).toFixed(2);
        });
        this.recommendDataList = [...data.list];
      }
    },
    async getUsedData(categoryId) {
      let dataObj = {
        categoryId: categoryId,
        page: 1,
        size: 20,
      };

      const { code, data } = await appHomeApi.get_recommend(dataObj);
      if (code == 200 && data.list) {
        data.list.map((item) => {
          item.unitAmount = (item.unitAmount / 100).toFixed(2);
        });
        this.usedDataList = [...data.list];
      }
    },
    async getHotData() {
      const { code, data } = await appHomeApi.get_HotTop15();
      if (code == 200 && data) {
        this.hotDataList = [];
        data.map((item, index) => {
          this.hotDataList.push({ ...item.category, product: item.product });
        });
        this.hotDataList.map((item) => {
          item.product = item.product.slice(0, 3);
        });
      }
    },
  },
  components: {
    drawerCard,
    classCard,
    recommendCard,
    usedCard,
    shopCard,
    hotCard,
    preSaleCard,
    groupPurchaseCard,
    flashSaleCard,
    searchCard,
    appServiceDialog,
  },
};
</script>

<style lang="scss" scoped>
.appHome-warper {
  width: 100%;
  height: 100%;

  .appHome-header {
    height: 98px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    align-items: center;
    padding: 0 20px;

    ::v-deep .search-warper {
      padding: 0;
      width: 90%;
    }

    ::v-deep .van-cell {
      height: 70px !important;
      width: 100%;
      margin: 0 auto;
      background-color: #ffffff;
      border-radius: 38px;
      font-size: 36px;
      border: 1px solid $blur;
      padding: 0 10px 0 20px;

      .van-field__body {
        height: 100%;
      }

      .van-field__control {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .van-field__left-icon {
        display: flex;
        align-items: center;

        svg {
          width: 36px !important;
          height: 36px !important;
          color: #c8c9cc;
        }
      }
    }

    .header-svg {
      height: 70%;
      padding: 15px 0 0 15px;

      svg {
        color: #000;
        width: 36px !important;
        height: 36px !important;
      }
    }
  }

  .appHome-body {
    height: calc(100% - 98px);
    overflow: scroll;
    background: #f7f7fb;
    padding: 15px 0 30px 0;

    .scroll-top-boxers {
      position: fixed;
      right: 5%;
      bottom: 15%;
      background-color: #6d3580;
      border-radius: 50%;
      width: 120px;
      height: 120px;
      justify-content: center;
      z-index: 999;

      svg {
        color: #fff;
        width: 38px !important;
        height: 38px !important;
      }
    }
  }

  .scroll-header-1 {
    background: #fff;
    z-index: 99;
    border-bottom: 1px solid #ebebeb;

    .header-svg {
      background: #f7f7fb;
      opacity: 1;
    }

    svg {
      color: #3f4041 !important;
    }
  }

  .my-swipe {
    width: 100%;
    height: 30%;

    .van-swipe-item {
      height: 100%;
      //background: #fff8ec;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .switch-img {
        width: 100% !important;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          height: 100%;
        }

        .switch-button {
          position: absolute;
          bottom: 50px;
          right: 20px;
          z-index: 100;
          font-size: 26px;
          font-weight: 600;
          color: #ffffff;
          border-radius: 8px;
          border: 1px solid #ffffff;
          padding: 12px 32px;
        }
      }
    }

    // }
  }
}
</style>
