<template>
  <div class="appPage-warper" :key="countNum">
    <div :class="['appPage-body', footerIsShow() && 'appIndexBody']">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
    <appFooterVue v-if="footerIsShow()"></appFooterVue>
  </div>
</template>

<script>
import { getParams } from "@/utils/publicMethods";
import appFooterVue from "./component/app-footer/app-footer.vue";
export default {
  name: "appIndex",
  data () {
    return {
      language: this.$language(),
      routerPath: "",
      scroll: 0, //记录浏览位置
      countNum: 1,
      countNumIsShow: true,
    };
  },
  beforeDestroy () {
    window.removeEventListener("_countNum", (val) => {
      let countNum = sessionStorage.getItem("_countNum")
      this.countNum = countNum
    });
  },
  watch: {
    $route: {
      handler (newVal, oldVal) {
        this.routerPath = newVal.path;
        this.footerIsShow();
      },
      deep: true,
    },
  },
  mounted () {
    let language = getParams('language')
    language = decodeURI(language)
    if (language && language !== 'null' && language !== 'undefined') {
      localStorage.setItem('languageType', language)
      let countNum = sessionStorage.getItem("_countNum") || 1
      countNum = Number(countNum)
      countNum += 1
      this.$resetSetItem("_countNum", "_countNum", countNum);
      this.countNum += countNum
      this.language = this.$language()
    }
    let code = getParams("code")
    if (code == 0 || code) {
      localStorage.setItem('code', code)
    }
    window.addEventListener("_countNum", (val) => {
      this.countNum += 1
    });
    sessionStorage.setItem("moneyIcon", "$");
    this.routerPath = this.$route.path;
    this.footerIsShow();
  },
  methods: {
    footerIsShow () {
      let isShow = false;
      let enumType = ["/appHome", "/appClassification"];
      isShow = enumType.some((item) => {
        return item == this.routerPath;
      });
      return isShow;
    },
  },
  components: { appFooterVue },
};
</script>
<style lang="scss" scoped>
.appPage-warper {
  margin: 0 auto;
  max-width: 768px;
  width: 100%;
  height: 100vh;
  font-family: "GrifaSlabRegular";
  background: #f7f7fb;

  .appPage-body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    background: #f7f7fb;
  }

  .appIndexBody {
    height: calc(100vh - 160px);
  }
}
</style>
