import Vue from 'vue'
import { Message } from 'element-ui'
import { Loading } from 'element-ui';
let v = new Vue()
v.$message = Message;
v.$loading = Loading;
import axios from 'axios'
import baseUrl from '@/utils/projectConfig'
const ServerRequestUrl = baseUrl.baseUrl
// 创建axios实例
const service = axios.create({
  baseURL: ServerRequestUrl
})
let loadingCount = 0
let loadingInstance
// request拦截器==>对请求参数做处理
service.interceptors.request.use(config => {
  config.headers.Authorization = window.localStorage.getItem('authenticationToKen_');
  config.headers['Accept-Language'] = baseUrl.lang[window.localStorage.getItem('languageType')]
  loadingCount += 1
  if (loadingCount == 1) {
    loadingInstance = Loading.service({ background: 'rgba(0, 0, 0, .2)', });
  }
  return config
}, error => { // 请求错误处理
  Promise.reject(error)
})

// respone拦截器==>对响应做处理
service.interceptors.response.use(
  response => { // 成功请求到数据
    // 这里根据后端提供的数据进行对应的处理 
    if (response.data.code === 200) {
      loadingCount--
      if (loadingCount == 0) {
        loadingInstance.close()
      }
    } else if (response.data.code == 1002) {
      loadingCount--
      if (loadingCount == 0) {
        loadingInstance.close()
      }
      sessionStorage.clear();
      localStorage.clear()
    } else if (response.data.code == 400) {
      loadingCount--
      if (loadingCount == 0) {
        loadingInstance.close()
      }
      sessionStorage.clear();
      localStorage.clear()
    } else {
      loadingCount--
      if (loadingCount == 0) {
        loadingInstance.close()
      }
      sessionStorage.clear();
      localStorage.clear()
    }
    return response.data
  },
  error => {
    loadingInstance.close()
    let text = JSON.parse(JSON.stringify(error))
    Message({
      message: text.message,
      type: 'warning'
    })
    return Promise.reject(error)
  }
)
export default service