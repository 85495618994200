import service from '@/required/appAxios'
export default {
  // 获取全部分类数据 
  getClassIfy_List (form) {
    return service({
      url: '/category.get_tree',
      method: "get",
    })
  },
  // 获取产品数据
  getProductPort (data) {
    return service({
      url: '/product.get',
      method: "get",
      params: data
    })
  },
}