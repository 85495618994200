<template>
  <div class="appHome-hotList-warper">
    <div class="appHome-hot-header flexbox">
      <div class="hot-header-left flexbox">
        <svg-icon icon-class="app-statistics" class="appHomeSvg"></svg-icon>
        <span class="title-left">{{ language.hotTitle }}</span>
      </div>
      <div class="hot-header-right flexbox" @click="onClickViewLink">
        <span class="title-right">{{ language.viewAll }}</span>
      </div>
    </div>
    <div class="appHome-hot-body">
      <div class="hotBody-list-data flexbox" v-if="hotListData.length">
        <div class="hotBody-list-item" v-for="(item, index) in hotListData" :key="index" @click="onClickViewRow(item)">
          <div class="child-data-boxers">
            <div class="hot-list-item-header flexbox">
              <div class="list-item-left noText">
                <span>{{ item.name }}</span>
                <span>{{ language.ranking }}</span>
              </div>
              <svg-icon icon-class="app-right"></svg-icon>
            </div>
            <div class="child-warper flexbox">
              <div class="list-item-child" v-for="(child, childIndex) in item.product" :key="childIndex">
                <div class="list-item-images imgFlex">
                  <img v-lazy="getUrlImg(child)" />
                  <div class="child-img-ranking">
                    <img v-lazy="getImg(childIndex + 1)" alt="" />
                  </div>
                </div>
                <div class="list-item-info">
                  <span class="text">{{ child.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <defaultPage v-else></defaultPage>
    </div>
  </div>
</template>

<script>
import imgApi from "@/utils/projectConfig.js";
export default {
  name: "appHomeHotListCard",
  props: {
    hotList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data () {
    return {
      imgApi: imgApi.imgUrl,
      thumbnail: this.$thumbnail,
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      language: this.$language(),
      hotListData: [],
    };
  },
  mounted () {
    this.onLoad();
  },
  methods: {
    onClickViewLink () {
      this.$router.push({
        path: "/appSalesRanking",
        query: {
          pageType: 'homeHot',
        }
      });
    },
    onClickViewRow (record) {
      this.$router.push({
        path: "/appSalesRanking",
        query: {
          pageType: 'homeHotRow',
          id: record.id
        }
      });
    },
    dataCallBack (data) {
      data.map((item) => {
        if (item.product) {
          item.product.sort((a, b) => {
            return a.sales - b.sales;
          });
        }

      });
    },
    onLoad () {
      this.hotListData = this.hotList;
      if (this.hotListData.length) {
        this.dataCallBack(this.hotListData);
      }

    },
    getImg (type) {
      let state = null;
      if (type == 2) state = 1;
      if (type == 1) state = 2;
      if (type == 3) state = 3;
      return require(`../../../../assets/h5/ranking_${state}.png`);
    },
    getUrlImg (record) {
      return this.imgApi + record.image
    },
  },
  watch: {
    hotList (newVal, oldVal) {
      this.hotListData = [...newVal];
      if (this.hotListData.length) {
        this.dataCallBack(this.hotListData);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.appHome-hotList-warper {
  width: 100%;
  margin-top: 15px;

  .appHome-hot-header {
    height: 110px;
    z-index: 2;
    background-color: #fff;
    padding: 0px 36px;
    justify-content: space-between;
    width: auto;
  }

  .appHome-hot-body {
    width: 100%;
    background-color: #fff;
    padding: 15px 30px;

    .hotBody-list-data {
      width: 100%;
      overflow-x: auto;

      .hotBody-list-item {
        width: 100%;
        border-radius: 18px;
        margin-right: 15px;
        background-color: #fff;
        padding: 40px 30px;

        .hot-list-item-header {
          width: 100%;
          justify-content: space-between;

          .list-item-left {
            width: 90%;
            overflow: hidden;
            /* 超出的文字省略号表示 */
            text-overflow: ellipsis;
            color: $appSizeColor;
            font-size: 32px;
            font-weight: 600;

            span:nth-child(1) {
              color: $appBlur;
              font-size: 36px;
              margin-right:6px;
            }
          }
        }

        .child-warper {
          margin-top: 38px;
          align-items: flex-end;

          .list-item-child {
            width: 196px;
            margin-right: 30px;
            border-radius: 8px;
            overflow: hidden;

            .list-item-images {
              width: 100%;
              height: 180px;
              position: relative;

              .child-img-ranking {
                position: absolute;
                top: 0;
                left: 0;

                img {
                  width: 48px;
                  height: 56px;
                }
              }
            }

            .list-item-info {
              margin-top: 12px;
              color: $appSizeColor;
              font-size: 24px;
            }
          }

          .list-item-child:nth-child(2) {
            .list-item-images {
              height: 210px;
            }
          }
        }
      }

      .hotBody-list-item:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
