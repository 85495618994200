import service from '@/required/appAxios'
export default {
  // 获取全部分类数据 
  getClassIfy_List (form) {
    return service({
      url: '/category.get_tree',
      method: "get",
    })
  },
  // 店铺信息获取
  get_shopInfoData (data) {
    return service({
      url: 'shop.info',
      method: "post",
      params: data
    })
  },
  // 店铺产品列表数据获取
  get_shopProductData (data) {
    return service({
      url: 'shop.get_product',
      method: "get",
      params: data
    })
  },
  // 页面图片获取
  get_imageList (data) {
    return service({
      url: 'system.config_image',
      method: "get", 
    })
  },
  
}