<template>
  <div class="appHome-used-warper">
    <div class="appHome-used-header flexbox">
      <div class="header-left flexbox">
        <svg-icon icon-class="app-reversal" class="appHomeSvg"></svg-icon>
        <span class="title-left">{{ language.usedTitle }}</span>
      </div>
      <div class="header-right" @click="onClickViewLink">
        <span class="title-right">{{ language.viewAll }}</span>
      </div>
    </div>
    <div class="appHome-used-list">
      <van-tabs
        v-if="tabsListData.length"
        v-model="usedActive"
        class="appHome-used-tabs"
        ref="tab2"
        swipe-threshold="3"
        @change="onChangeTabs"
        swipeable
      >
        <van-tab
          v-for="(item, index) in tabsListData"
          :key="index"
          :title="item.name"
        >
        </van-tab>
      </van-tabs>
      <div class="appHome-usedList-data">
        <div class="tabs-list-boxers" v-if="usedDataList.length">
          <div
            class="tabs-list-item"
            v-for="(item, index) in usedDataList"
            :key="index"
            @click="
              $router.push({ path: '/appGoodsDetail', query: { id: item.id } })
            "
          >
            <div class="list-img imgFlex">
              <img v-lazy="getUrl(item.image)" />
            </div>
            <div class="list-info">
              <div class="list-name text">{{ item.name }}</div>
              <div class="list-price">
                {{ moneyIcon }} {{ item.unitAmount }}
              </div>
            </div>
            <div class="list-options flexbox">
              <span>{{ language.buyNow }}</span>
            </div>
          </div>
        </div>
        <defaultPage v-else></defaultPage>
      </div>
    </div>
  </div>
</template>

<script>
import "@/components/component-h5/css/index.css";
import imgApi from "@/utils/projectConfig.js";
export default {
  name: "appHomeUsedCard",
  props: {
    usedList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tabsList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      imgApi: imgApi.imgUrl,
      thumbnail: this.$thumbnail,
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      language: this.$language(),
      tabsListData: [],
      usedDataList: [],
      usedActive: 0,
    };
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onChangeTabs(key) {
      this.$emit("onChangeTabsUsed", key);
    },
    onLoad() {
      this.tabsListData = this.tabsList;
      this.usedDataList = this.usedList;
    },
    onClickViewLink() {
      this.$router.push({
        path: "/appClassProductIndex",
        query: { type: "used" },
      });
    },
    getUrl(url) {
      return this.imgApi + url;
    },
  },
  watch: {
    usedList: {
      handler(newVal, oldVal) {
        this.usedDataList = [...newVal];
      },
      deep: true,
    },
    tabsList: {
      handler(newVal, oldVal) {
        this.tabsListData = [...newVal];
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.appHome-used-warper {
  width: 100%;
  margin-top: 15px;

  .appHome-used-header {
    justify-content: space-between;
    padding: 0px 36px;
    height: 110px;
    background-color: #fff;
  }

  .appHome-used-list {
    .appHome-used-tabs {
      ::v-deep .van-tabs__wrap {
        .van-tabs__nav {
          .van-tab {
            .van-tab__text {
              font-size: 28px;
              color: #9ba0a8;
            }
          }

          .van-tab--active {
            .van-tab__text {
              color: #6d3580;
            }
          }
        }

        .van-tabs__line {
          background-color: #6d3580;
        }
      }

      .van-tabs__content {
        .van-tab__pane {
          overflow-x: hidden;
          background-image: linear-gradient(
            rgb(255, 255, 255) -20%,
            #f5f5f5 50%
          );
        }
      }
    }

    .appHome-usedList-data {
      background-color: #fff;
      padding: 15px 30px;
      width: 100%;
      overflow-x: scroll;

      .tabs-list-boxers {
        width: 100%;
        display: flex;
        justify-content: space-between;
        overflow-x: scroll;

        .tabs-list-item {
          min-width: 336px;
          width: 336px;
          background-color: #fff;
          border-radius: 20px;
          overflow: hidden;
          padding-bottom: 16px;
          margin-right: 30px;

          .list-img {
            width: 100%;
            height: 310px;
          }

          .list-info {
            margin-top: 15px;
            padding: 0 30px;

            .list-name {
              line-height: 30px;
              font-size: 24px;
              color: #1d1e20;
            }

            .list-price {
              margin-top: 12px;
              color: #1d1e20;
              font-weight: 600;
              font-size: 36px;
              text-align: center;
            }
          }

          .list-options {
            margin-top: 12px;
            justify-content: center;

            span {
              align-items: center;
              justify-content: center;
              padding: 12px 32px;
              background-color: $appBlur;
              color: $appWhite;
              border-radius: 46px;
            }
          }
        }
      }
    }
  }
}
</style>
