import service from '../required/axios'
export default {
  // 获取全部分类 
  getClassIfy_List (form) {
    return service({
      url: 'category.get_tree',
      method: "get",
    })
  },
  // 系统 - 系统内容配置数据
  getConfig_content (form) {
    return service({
      url:`system.config_content?`,
      method: "get",
      params: form
    })
  },
  // 系统 - 系统默认图片数据
  getSystem_img (form) {
    return service({
      url:`system.config_image`,
      method: "get",
      params: form
    })
  },
}