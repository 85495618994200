import service from '@/required/appAxios'
export default {
  // 获取全部分类数据 
  getDetailPort (data) {
    return service({
      url: '/product.detail',
      method: "get",
      params: data
    })
  },
  // 获取全部分类数据 
  getShop_detail (data) {
    return service({
      url: '/shop.get_product_detail',
      method: "get",
      params: data
    })
  },
  // 获取文本信息
  getHTML_content (data) {
    return service({
      url: '/system.config_content',
      method: "get",
      params: data
    })
  },
  // 获取推荐数据
  get_recommendList (data) {
    return service({
      url: '/product.get_recommend',
      method: "get",
      params: data
    })
  },
  // 获取商品评论信息
  get_commentInfoPort (data) {
    return service({
      url: '/product.get_comment',
      method: "get",
      params: data
    })
  },
}