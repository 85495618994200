import { environment } from './constant'
/**
 * @description 按钮展示权限
 * @params {string} 工作台环境
 * @returns Boolean
 */

let permissionObj = {}

// 下载APP入口clscl
let downloadApp = true

// 前往商家入口
let goToMerchants = true

// 商家入驻入口
let merchantsSettledIn = true

function permissionCall(key) {

    if (key ===  environment.tiktok) {
        goToMerchants = false
        merchantsSettledIn = false
    }

    if (key !==  environment.web) {
        downloadApp = false
    }

    return permissionObj = {downloadApp, goToMerchants, merchantsSettledIn}
}

export {permissionCall}