import english from './languageModule/english'
import japanese from './languageModule/japanese'
import chineseTraditional from './languageModule/chineseTraditional'
import portuguese from './languageModule/portuguese'
import german from './languageModule/german'
import french from './languageModule/french'
import turkish from './languageModule/turkish'
import spanish from './languageModule/spanish'
import arabic from './languageModule/arabic'
import korean from './languageModule/korean'
import finland from './languageModule/finland'
import russian from './languageModule/russian'
import italian from './languageModule/italian'
import thai from './languageModule/thai'
import dutch from './languageModule/dutch'

function web_languageType (key) {
  if (key == 'English' || key == 'english' || key == null) {
    return english
  }

  if (key == '日本語') {
    return japanese
  }

  if (key == 'Português') {
    return portuguese
  }

  if (key == '繁體中文') {
    return chineseTraditional
  }

  if (key == 'Français') {
    return french
  }

  if (key == 'Deutsch') {
    return german
  }

  if (key == 'Türk') {
    return turkish
  }

  if (key == 'Español') {
    return spanish
  }

  if (key == 'عربي') {
    return arabic
  }

  if (key == '한국인') {
    return korean
  }

  if (key == 'Suomi') {
    return finland
  }

  if (key == 'Русский') {
    return russian
  }

  if (key == 'italiano') {
    return italian
  }

  if (key == 'แบบไทย') {
    return thai
  }

  if (key == 'Nederlands') {
    return dutch
  }
}

function web_languageList (key) {
  let languageObj = {}
  let language = localStorage.getItem("languageType")
  languageObj = web_languageType(language)

  if (key) {
    return languageObj[key]
  }
  let UrlPath = window.location.hash.slice(2)
  if (!UrlPath) {
    UrlPath = 'home'
  }
  if (UrlPath.includes("?")) {
    UrlPath = UrlPath.split("?")[0]
  }
  return languageObj[UrlPath]
}
function web_settingLanguage (type, key) {
  let languageObj = web_languageType(type);
  if (key) {
    return languageObj[key]
  }
  let UrlPath = window.location.hash.slice(2) || 'home'

  if (UrlPath.includes("?")) {
    UrlPath = UrlPath.split("?")[0]
  }
  console.log(UrlPath);
  return languageObj[UrlPath]
}
export { web_languageList, web_languageType, web_settingLanguage }