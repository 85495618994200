<template>
  <div class="app-forgot-warper">
    <appHeader></appHeader>
    <div class="app-forgot-body">
      <div class="forgot-header-body">
        <div class="forgot-title">{{ language.navTitle }}</div>
        <div class="forgot-hint">
          {{ language.navTitleHint }}
        </div>
      </div>
      <div class="field-boxers">
        <div class="field-item">
          <p class="field-item-label">{{ language.emailLabel }}</p>
          <van-field
            :class="['form-input', errorObj.emailErrorText && 'error-input']"
            v-model="formObj.email"
            :placeholder="language.emailPlaceholder"
            @input="onChangeInput('email', formObj)"
          >
            <template #left-icon>
              <svg-icon icon-class="app-mobile"></svg-icon>
            </template>
          </van-field>
          <p class="error-active error-text">{{ errorObj.emailErrorText }}</p>
        </div>
        <div class="field-item">
          <p class="field-item-label">{{ language.verificationCode }}</p>
          <van-field
            class="form-input form-verification"
            v-model="formObj.verificationCode"
            :placeholder="language.verificationPlace"
          >
            <template #button>
              <van-button
                :class="timer && 'send-btn'"
                :disabled="codeIsShow"
                @click="sendCode"
                size="small"
              >
                {{ verificationCodeText }}
              </van-button>
            </template>
          </van-field>
        </div>
        <div class="field-item">
          <p class="field-item-label">{{ language.passwordLabel }}</p>
          <van-field
            class="form-input"
            v-model="formObj.pass"
            type="password"
            :placeholder="language.passPlaceholder"
          >
            <template #left-icon>
              <svg-icon icon-class="app-pass"></svg-icon>
            </template>
          </van-field>
        </div>
        <div class="field-item">
          <p class="field-item-label">{{ language.confirmPassLabel }}</p>
          <van-field
            class="form-input"
            type="password"
            v-model="formObj.confirmPass"
            :placeholder="language.confirmPlace"
          >
            <template #left-icon>
              <svg-icon icon-class="app-pass"></svg-icon>
            </template>
          </van-field>
        </div>
      </div>
      <div class="field-options" @click="onChangePass">
        <div class="button btn-login">
          {{ language.submitBtnTitle }}
        </div>
      </div>
    </div>
    <appNotify
      v-if="notifyIsShow"
      :notifyIsShow="notifyIsShow"
      :notifyTitle="notifyTitle"
      @notifyIsShow="
        {
          notifyIsShow = false;
          notifyTitle = '';
        }
      "
    ></appNotify>
  </div>
</template>

<script>
import appHeader from "../component/app-header.vue";
export default {
  name: "appForgotPassIndex",
  data() {
    return {
      language: this.$language(),
      formObj: {
        email: "",
        pass: "",
        verificationCode: "",
        confirmPass: "",
      },
      notifyIsShow: false,
      notifyTitle: "",
      emailRegex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      errorObj: {
        emailErrorText: "",
      },
      verificationCodeText: "",
      timer: null,
      codeIsShow: false,
      codeSetTime:''
    };
  },
  created() {},
  mounted() {
    this.verificationCodeText = this.language.sendTitle;
  },
  methods: {
    sendCode() {
      if (!this.formObj.email) {
        this.checkRuleCallBack("emailNullVerify");
        return false;
      }
      // if (!this.emailRegex.test(this.formObj.email)) {
      //   this.checkRuleCallBack("emailErrorVerify");
      //   return false;
      // }
      // if (!this.formObj.verificationCode) {
      //   this.checkRuleCallBack("verificationCodeError");
      //   return false;
      // }
      this.timer = 60;
      this.codeIsShow = false;
      clearTimeout(this.codeSetTime);
      this.codeSetTime = setInterval(() => {
        this.codeIsShow = true;
        this.timer -= 1;
        this.verificationCodeText = `${this.language.sendText}（${this.timer}）s`;
        if (this.timer === 0) {
          this.codeIsShow = false;
          this.verificationCodeText = this.language.sendTitle;
          clearTimeout(this.codeSetTime);
        }
      }, 1000);
    },
    onChangePass() {
      this.notifyIsShow = false;
      this.notifyTitle = "";
      this.inputVerifyCallBack();
    },
    onChangeInput(key, form) {
      if (key === "email") {
        this.errorObj.emailErrorText = "";
        if (!this.formObj[key]) {
          this.errorObj.emailErrorText = this.language.emailErrorText;
          return false;
        }
        // if (!this.emailRegex.test(this.formObj[key])) {
        //   this.errorObj.emailErrorText = this.language.emailErrorText;
        //   return false;
        // }
      }
    },
    inputVerifyCallBack() {
      if (!this.formObj.email) {
        this.checkRuleCallBack("emailNullVerify");
        return false;
      }
      // if (!this.emailRegex.test(this.formObj.email)) {
      //   this.checkRuleCallBack("emailErrorVerify");
      //   return false;
      // }
      if (!this.formObj.verificationCode) {
        this.checkRuleCallBack("verificationCodeError");
        return false;
      }
      if (!this.formObj.pass) {
        this.checkRuleCallBack("passNullVerify");
        return false;
      }
      if (this.formObj.pass.length < 6) {
        this.checkRuleCallBack("passErrorVerify");
        return false;
      }
      if (!this.formObj.confirmPass) {
        this.checkRuleCallBack("passCompareErrorNull");
        return false;
      }
      if (this.formObj.pass !== this.formObj.confirmPass) {
        this.checkRuleCallBack("passCompareErrorVerify");
        return false;
      }
      this.notifyIsShow = true;
      this.notifyTitle = this.language.loginMessage;
    },
    checkRuleCallBack(key) {
      this.notifyIsShow = true;
      this.notifyTitle = this.language[key];
    },
  },
  components: { appHeader },
};
</script>

<style lang="scss" scoped>
.app-forgot-warper {
  width: 100%;
  height: 100%;
  font-family: "GrifaSlabRegular";
  .app-forgot-body {
    padding: 10px 36px;
    .forgot-header-body {
      .forgot-title {
        font-size: 52px;
        font-weight: 600;
        color: #6d3580;
      }
      .forgot-hint {
        width: 100%;
        margin-top: 22px;
        font-size: 32px;
        color: #1d1e20;
      }
    }
    .field-boxers {
      .field-item {
        padding-top: 62px;
        .field-item-label {
          font-size: 24px;
          color: #1d1e20;
          margin-bottom: 30px;
        }
        ::v-deep .form-input {
          height: 100px;
          border: 4px solid #6d3580;
          border-radius: 16px;
          padding: 0;
          padding-left: 35px;

          .van-field__left-icon {
            display: flex;
            align-items: center;
            svg {
              // padding-left: 35px;
              width: 40px !important;
              height: 40px !important;
              color: #6d3580;
            }
          }
          .van-field__body {
            height: 100%;
            input {
              caret-color: #6d3580 !important;
            }
            .van-field__control {
              height: 100%;
              padding: 0 20px;
              font-size: 32px;
              color: #6d3580;
            }
          }
        }
        ::v-deep .form-verification {
          padding: 0;
          border: 0;
          .van-field__control {
            border: 4px solid #6d3580;
            border-radius: 16px;
          }
          .van-field__button {
            height: 100%;
            .van-button {
              font-size: 28px;
              border-radius: 16px;
              padding: 0 32px;
              height: 100%;
              color: #fff;
              background-color: #6d3580;
            }
          }
        }
      }
    }
    .field-options {
      margin-top: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #6d3580;
      color: #fff;
      font-size: 28px;
      height: 96px;
      border-radius: 16px;
    }
  }
  .error-input {
    color: #ff2f2f !important;
    border-color: #ff2f2f !important;
    ::v-deep .van-field__left-icon {
      svg {
        color: #ff2f2f !important;
      }
    }
    ::v-deep .van-field__control {
      color: #ff2f2f !important;
    }
  }
  .error-text {
    color: #ff2f2f !important;
    padding-top: 8px;
    font-size: 24px;
    text-align: right;
  }
}
</style>
