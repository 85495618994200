<template>
  <div class="app-product-card">
    <div class="app-product-img imgFlex">
      <img v-lazy="getUrlImg(dataItem.image)" />
    </div>
    <div class="app-product-name text">
      {{ dataItem.name }}
    </div>
    <div class="app-product-payment text2">
      1000+ {{ language.paymentTitle }}
    </div>
    <div class="app-product-price text">
      {{ moneyIcon }}{{ dataItem.unitAmount }}
    </div>
    <div class="app-product-tags">
      <span>{{ language.listTag1 }}</span>
    </div>
  </div>
</template>

<script>
import imgApi from "@/utils/projectConfig.js";
export default {
  name: "appProductCard",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      language: this.$language(),
      imgApi: imgApi.imgUrl,
      thumbnail: this.$thumbnail,
      dataItem: {},
    };
  },
  components: {},
  created() {},
  mounted() {
    this.dataItem = this.item;
  },
  methods: {
    getUrlImg(url) {
      return this.imgApi + url 
    },
  },
  watch: {
    item: {
      handler(newVal, oldVal) {
        this.dataItem = newVal
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-product-card {
  font-family: "GrifaSlabRegular";
  .app-product-img {
    width: 100%;
    height: 420px;
    border-radius: 18px;
    overflow: hidden;
  }
  .app-product-name {
    margin-top: 12px;
    font-size: 28px;
    color: #38393b;
  }
  .app-product-payment {
    margin-top: 12px;
    font-size: 20px;
    color: #8f959e;
  }
  .app-product-price {
    margin-top: 16px;
    color: #6d3580;
    font-size: 28px;
  }
  .app-product-tags {
    margin-top: 24px;
    span {
      font-size: 20px;
      padding: 6px 9px;
      color: #6d3580;
      border: 1px solid #6d3580;
    }
  }
}
</style>
