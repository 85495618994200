<template>
  <div class="appShopClass-card"  @click="pageTumps(item)">
    <div class="appShop-img">
      <img v-lazy="item.media.mer_banner[0]" />
    </div>
    <div class="appShop-info">
      <div class="appShop-info-left">
        <div class="info-logo">
          <img v-lazy="item.media.avatar[0]" />
        </div>
        <div class="info-name">
          <p class="name">{{ item.name }}</p>
          <p class="title">
            {{ item.countTitle }}
          </p>
        </div>
      </div>
      <div class="appShop-info-right">
        <van-rate v-model="item.service_score" />
      </div>
    </div>
    <div class="appShop-options">
      <span>{{ this.language.shopBtn }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "appShopClassCard",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      language: this.$language(),
    };
  },
  methods: {
    pageTumps() {
      this.$router.push({ path: "/appShops" });
    },
  },
};
</script>

<style lang="scss" scoped>
.appShopClass-card {
  .appShop-img {
    width: 100%;
    height: 230px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .appShop-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 36px;
    .appShop-info-left {
      display: flex;
      align-items: center;
      .info-logo {
        width: 110px;
        height: 110px;
        margin-right: 12px;
        border-radius: 16px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info-name {
        font-size: 24px;
        font-weight: 600;
        .name {
          color: #262729;
        }
        .title {
          margin-top: 20px;
          color: #9399a2;
        }
      }
    }
    .appShop-info-right {
      ::v-deep .van-rate {
        .van-rate__item {
          .van-icon {
            width: 12px !important;
            height: 12px !important;
            font-size: 26px !important;
            margin-left: 6px;
          }
        }
      }
    }
  }
  .appShop-options {
    display: flex;
    justify-content: flex-end;
    padding: 30px 36px;
    span {
      display: flex;
      align-items: center;
      height: 80px;
      padding: 0 16px;
      border-radius: 12px;
      background-color: #6d3580;
      color: #fff;
      font-size: 26px;
    }
  }
}
</style>
