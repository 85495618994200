<template>
  <!-- 产品分类list页面 -->
  <div class="app-presSale-warper">
    <appHeader :navTitle="language.navTitle"></appHeader>
    <van-tabs
      v-model="tabsActive"
      class="card-tabs-boxers"
      swipe-threshold="3"
      @change="onChangeTabs"
    >
      <van-tab
        v-for="(item, index) in tabsDataList"
        :key="index"
        :title="item.name"
      >
      </van-tab>
    </van-tabs>
    <div class="card-list-boxers">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="message.listNullText"
        :loading-text="message.loadingText"
        @load="onLoad"
      >
        <div
          class="list-item"
          v-for="(item, index) in groupDataList"
          :key="index"
          @click="
            $router.push({
              path: '/appGoodsDetail',
              query: { id: item.id, sourcePage: 'group' },
            })
          "
        >
          <div class="list-item-img imgFlex">
            <img v-lazy="getImgUrl(item.image)" />
            <div class="img-count-down flexbox text-warper">
              {{ language.listLabel }}
              {{ item.peopleNumber }}
              {{ language.listLabe2 }}
            </div>
          </div>
          <div class="list-item-payment text">
            {{ item.highlights }}
          </div>
          <div class="list-item-name text2">
            {{ item.name }}
          </div>
          <div class="list-item-price">
            {{ moneyIcon }}{{ item.unitAmount }}
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import appHeader from "../component/app-header.vue";
import appGroupBuyingApi from "@/api/h5Api/appGroupBuying";
import searchCard from "../component/app-search.vue";
import imgApi from "@/utils/projectConfig.js";
export default {
  name: "appClassListPage",
  data() {
    return {
      imgApi: imgApi.imgUrl,
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      loading: false,
      finished: false,
      refreshing: false,
      language: this.$language(),
      message: this.$language("messageLanguage"),
      tabsDataList: [],
      tabsActive: 0,
      formObj: {
        page: 1,
        size: 20,
        total: 0,
        categoryId: "",
      },
      groupDataList: [],
    };
  },
  created() {
    this.get_classTree();
  },
  methods: {
    // 获取分类数据
    async get_classTree() {
      this.tabsDataList = [];
      let { code, data } = await appGroupBuyingApi.getClassIfy_List();
      if (code === 200) {
        this.tabsDataList = data;
        this.tabsDataList.unshift({
          id: "all",
          name: this.language.selectAll,
        });
        this.tabsActive = 0;
        this.formObj.categoryId = this.tabsDataList[this.tabsActive].id;
      }
    },
    onLoad() {
      this.getGroupList();
    },
    getGroupList() {
      this.selectIsShow = false;
      this.loading = true;
      let categoryId = this.formObj.categoryId;
      if (this.formObj.categoryId == "all") {
        categoryId = "";
      }
      const params = {
        page: this.formObj.page,
        size: this.formObj.size,
        categoryId: categoryId,
      };
      appGroupBuyingApi.get_groupList(params).then(({ code, data }) => {
        this.loading = false;
        if (code == 200) {
          data.list.map((item) => {
            item.unitAmount = (item.unitAmount / 100).toFixed(2);
          });
          this.groupDataList = this.groupDataList.concat(data.list);
          this.formObj.total = data.total;
          if (this.groupDataList.length >= this.formObj.total) {
            this.finished = true;
          } else {
            this.finished = false;
            this.formObj.page++;
          }
        }
      });
    },

    onChangeTabs() {
      this.formObj.categoryId = this.tabsDataList[this.tabsActive].id;
      this.finished = false;
      this.formObj.page = 1;
      this.groupDataList = [];
      this.getGroupList();
    },
    getImgUrl(url) {
      return this.imgApi + url;
    },
  },
  components: { appHeader, searchCard },
};
</script>

<style lang="scss" scoped>
.app-presSale-warper {
  width: 100%;
  height: 100vh;
  font-family: "GrifaSlabRegular";
  background: #f7f7fb;
  .card-tabs-boxers {
    margin-top: 15px;
    width: 100%;

    ::v-deep .van-tabs__wrap {
      .van-tabs__nav {
        padding-left: 20px;
        padding-right: 20px;
        .van-tab {
          margin: 0 12px;
          flex: none !important;
          text-align: center;
          line-height: 2.5;
          padding: 0 15px;
          .van-tab__text {
            font-weight: 500;
            font-size: 32px;
            color: #9ba0a8;
          }
        }
        .van-tab--active {
          .van-tab__text {
            font-weight: 600;
            color: #6d3580;
          }
        }
      }
      .van-tabs__line {
        height: 4px;
        background-color: #6d3580;
      }
    }
  }

  .card-list-boxers {
    width: 100%;
    height: calc(100% - 249.5px);
    overflow-y: scroll;
    padding: 20px 23px;
    background-color: #fff;
    ::v-deep .van-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: #fff;
      .van-list__loading {
        width: 100%;
      }
      .van-list__finished-text {
        width: 100%;
      }
    }
    .list-item {
      width: 48%;
      margin-bottom: 30px;
      background-color: #fff;
      .list-item-img {
        width: 100%;
        height: 412px;
        overflow: hidden;
        border-radius: 18px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .img-count-down {
          text-align: center;
          padding: 20px 15px;
          background-color: #6d3580;
          justify-content: center;
          font-size: 20px;
          color: #fff;
          width: 100%;
          min-height: 60px;
          position: absolute;
          bottom: 0;
          line-height: 1.5;
        }
      }
      .list-item-name {
        margin-top: 12px;
        color: #1d1e20;
        font-size: 26px;
      }
      .list-item-payment {
        margin-top: 18px;
        color: #959ba3;
        font-size: 20px;
      }
      .list-item-price {
        margin-top: 18px;
        font-size: 34px;
        color: #6d3580;
      }
    }
  }
}
</style>
