<template>
  <div class="appSales-ranking-warper">
    <appHeader :navTitle="language.navTitle"></appHeader>
    <div class="card-tabs-body">
      <van-tabs
        v-model="tabsActive"
        class="card-tabs-boxers"
        swipe-threshold="3"
        @change="onChangeTabs"
        swipeable
        sticky
        :offset-top="'1.46667rem'"
      >
        <van-tab
          v-for="(item, index) in tabsDataList"
          :key="index"
          :title="item.name"
        >
          <div class="card-list-body">
            <div class="card-list-boxers" v-if="salesRanking.length">
              <div
                class="list-item-boxers flexbox"
                v-for="(item, index) in salesRanking"
                :key="index"
                @click="
                  $router.push({
                    path: '/appGoodsDetail',
                    query: { id: item.id },
                  })
                "
              >
                <div class="list-item-left">
                  <div class="item-img imgFlex">
                    <img v-lazy="getUrl(item.image)" />
                  </div>
                </div>
                <div class="list-item-right">
                  <div class="list-item-name text">
                    <span>{{ item.storeTitle }}</span>
                    {{ item.name }}
                  </div>
                  <div class="list-item-payment flexbox">
                    {{ item.salesTitle }}{{ language.payment }}
                  </div>
                  <div class="list-item-price">
                    {{ moneyIcon }}{{ item.unitAmount }}
                  </div>
                  <div class="list-item-options flexbox">
                    <div
                      class="options option-1 flexbox"
                      @click.stop="$router.push({ path: '/appLogin' })"
                    >
                      {{ language.optionBtn1 }}
                    </div>
                    <div class="options option-2 flexbox">
                      {{ language.optionBtn2 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <defaultPage v-else></defaultPage>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import appHeader from "../component/app-header.vue";
import appHotApi from "@/api/h5Api/appHotApi";
import imgApi from "@/utils/projectConfig.js";
export default {
  name: "appSalesRankingPage",
  data() {
    return {
      imgApi: imgApi.imgUrl,
      thumbnail: this.$thumbnail,
      moneyIcon: sessionStorage.getItem("moneyIcon"),
      language: this.$language(),
      message: this.$language("messageLanguage"),
      loading: false,
      finished: false,
      refreshing: false,

      tabsDataList: [],
      tabsActive: 0,
      salesRanking: [],
    };
  },
  mounted() {
    this.getHotDataList();
  },
  methods: {
    click1() {
      console.log(1);
    },
    onChangeTabs() {
      this.getHotDataList();
    },
    getHotDataList() {
      appHotApi.get_HotTop15().then(({ code, data }) => {
        if (code == 200) {
          if (!this.tabsDataList.length) {
            data.map((item, index) => {
              if (Object.keys(this.$route.query).length) {
                const { pageType, id } = this.$route.query;
                if (pageType == "homeHotRow" && item.category.id == id) {
                  this.tabsActive = index;
                }
              }
              item.product.map((record, index) => {
                record.salesTitle = record.sales;
                if (record.sales > 1000) {
                  record.salesTitle = "1000+";
                }
              });
              this.tabsDataList.push(item.category);
            });
          }
          data.map((item, index) => {
            if (index === this.tabsActive) {
              item.product.map((record, index) => {
                record.storeTitle = index + 1 + "";
                record.salesTitle = record.sales;
                if (Number(record.storeTitle) < 10) {
                  record.storeTitle = "0" + (index + 1);
                }
                if (record.sales > 1000) {
                  record.salesTitle = "1000+  ";
                }
                record.unitAmount = (Number(record.unitAmount) / 100).toFixed(
                  2
                );
              });
              this.salesRanking = item.product;
            }
          });
          console.log(this.salesRanking);
        }
      });
    },
    getUrl(url) {
      return this.imgApi + url;
    },
  },

  components: { appHeader },
};
</script>

<style lang="scss" scoped>
.appSales-ranking-warper {
  width: 100%;
  height: 100vh;
  font-family: "GrifaSlabRegular";
  background-color: #f2f3f4;

  ::v-deep .app-component-header-warper {
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .card-tabs-body {
    width: 100%;
    padding-top: 110px;
    background-color: #f2f3f4;
  }

  .card-tabs-boxers {
    padding-bottom: 30px;
    height: 100%;
    background-color: #f2f3f4;

    ::v-deep .van-tabs__wrap {
      background-color: #fff;

      .van-tabs__nav {
        .van-tab {
          .van-tab__text {
            font-weight: 400;
            font-size: 28px;
            color: #9ba0a8;
          }
        }

        .van-tab--active {
          .van-tab__text {
            font-weight: 600;
            color: #6d3580;
          }
        }
      }

      .van-tabs__line {
        height: 4px;
        background-color: #6d3580;
      }
    }

    ::v-deep .van-tabs__content {
      height: 100%;

      .van-tab__pane {
        height: 100%;

        .card-list-body {
          width: 100%;
          height: 100%;
        }

        .card-list-boxers {
          width: 100%;
          padding: 30px 36px;
          background-color: #f2f3f4;

          .list-item-boxers {
            background-color: #fff;
            box-shadow: 0 0 15px 0 #eff0f1;
            margin-bottom: 30px;
            padding: 30px;
            align-items: flex-start;
            border-radius: 18px;

            .list-item-left {
              width: 200px;
              border-radius: 12px;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 16px;

              .item-img {
                width: 200px;
                height: 200px;
                max-width: 100%;
                max-height: 200px;
                display: flex;
                align-items: center;
              }
            }

            .list-item-right {
              width: calc(100% - 200px);

              .list-item-name {
                color: #1d1e20;
                font-size: 28px;
              }

              .list-item-payment {
                margin-top: 12px;
                font-size: 22px;
                color: #8f959e;
              }

              .list-item-price {
                margin-top: 20px;
                font-size: 34px;
                color: #1d1e20;
                font-weight: 600;
              }

              .list-item-options {
                margin-top: 20px;
                justify-content: space-between;

                .options {
                  width: 48%;
                  height: 54px;
                  font-size: 26px;
                  border-radius: 6px;
                  justify-content: center;
                }

                .option-1 {
                  background-color: #fff;
                  border: 2px solid #6d3580;
                  color: #6d3580;
                }

                .option-2 {
                  background-color: #6d3580;
                  color: #fff;
                }
              }
            }
          }

          .list-item-boxers:nth-child(1) {
            .list-item-name {
              span {
                color: #ff2f2f;
              }
            }
          }

          .list-item-boxers:nth-child(2) {
            .list-item-name {
              span {
                color: #f4bd46;
              }
            }
          }

          .list-item-boxers:nth-child(3) {
            .list-item-name {
              span {
                color: #ff5500;
              }
            }
          }
        }
      }
    }
  }
}
</style>
