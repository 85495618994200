<template>
  <!-- 评论模块 -->
  <div class="goods-comment-card">
    <div class="comment-header flexbox" @click="
      $router.push({
        path: '/appProductReviews',
        query: { id: $route.query.id },
      })
      ">
      <span class="comment-title">{{ language.commentTitle }}</span>
      <svg-icon icon-class="app-arrow"></svg-icon>
    </div>

    <div class="comment-body">
      <template v-if="commentObjData.length">
        <div class="body-list" v-for="(item, index) in commentObjData" :key="index">
          <div class="commentBody-header flexbox">
            <div class="avatar-img imgFlex">
              <el-avatar v-if="!item.avatarIcon" class="about-sellers-logo"
                :style="`background:${extractColorByName(item.nickname)}`">
                {{ elAvatarSlice(item.nickname) }}
              </el-avatar>
              <img v-lazy="item.avatarIcon" v-else />
            </div>
            <div class="header-title flexbox">
              <div class="left">
                <p class="comment-name">{{ item.nickname }}</p>
                <!-- <p class="comment-time">{{ item.createAt }}</p> -->
              </div>
              <div class="right">
                <div class="info-nav-stareLeave flexbox">
                  <div v-for="(svgItem, svgIndex) in 5" :key="svgIndex" :class="[
                    'stare-leave',
                    item.productScore >= svgIndex + 1 && 'star-active',
                  ]">
                    <svg-icon width="20px" height="20px" icon-class="pc-gray-star"></svg-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="commentBody-info">
            <p class="text3">{{ item.comment }}</p>
          </div>
          <!-- <P class="commentBody-unique">
          {{ item.unique }}
        </P> -->
        </div>
      </template>
      <default-page v-else></default-page>
    </div>
  </div>
</template>

<script>
export default {
  name: "goodsCommentCard",
  props: {
    commentObj: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data () {
    return {
      language: this.$language(),
      commentObjData: [],
      isShow: false,
    };
  },
  mounted () {
    this.commentObjData = this.commentObj;
    this.isShow = false;
    if (this.commentObjData.length) {
      this.isShow = true;
    }
  },
  methods: {
    // 用户名称截取作为头像
    elAvatarSlice (name) {
      if (name) {
        return name.slice(0, 1);
      }
    },
    /**
     * 根据名字提取颜色
     * @param name 名字
     */
    extractColorByName (name) {
      if (name) {
        let temp = [];
        temp.push("#");
        for (let index = 0; index < name.length; index++) {
          temp.push(parseInt(name[index].charCodeAt(0), 10).toString(16));
        }
        return temp.slice(0, 5).join("").slice(0, 4);
      }
    },
  },
  watch: {
    commentObj: {
      handler (newVal, oldVal) {
        this.commentObjData = { ...newVal };
        this.isShow = false;
        if (this.commentObjData.length) {
          this.isShow = true;
        }
        this.$forceUpdate();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-comment-card {
  margin-top: 40px;
  padding: 0 36px;
  background-color: #f5f6fa;
  border-radius: 18px;
  color: #1d1e20;

  .comment-header {
    height: 110px;
    justify-content: space-between;

    span {
      font-size: 28px;
    }

    svg {
      width: 28px !important;
      height: 28px !important;
      color: #37383b;
    }
  }

  .comment-body {
    .body-list {
      margin-bottom: 15px;
      border-bottom: 1px solid #d5d5d5;
    }

    .body-list:last-child {
      border-bottom: 0;
    }

    .commentBody-header {
      .avatar-img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin-right: 12px;
        background: #fff;
      }

      .header-title {
        width: calc(100% - 56px);
        justify-content: space-between;
        align-items: center;

        .left {
          .comment-name {
            font-size: 26px;
          }

          .comment-time {
            margin-top: 6px;
            font-size: 22px;
            color: #8f8f8f;
          }
        }

        .right {
          .info-nav-stareLeave {
            align-items: center;

            svg {
              color: #dbdbdf;
            }

            .star-active {
              svg {
                color: #ffcc33;
              }
            }
          }
        }
      }
    }

    .commentBody-info {
      padding: 30px 0;

      p {
        max-height: 90px;
        max-height: 90px;
        font-size: 24px;
      }
    }

    .commentBody-unique {
      font-size: 24px;
      color: #8f959e;
      padding-bottom: 30px;
    }
  }
}
</style>
